export class QueryParamsModel {
  [key: string]: any;
  constructor(queryParams:any, substitution?:any) {
    if (queryParams) {
      Object.keys(queryParams).forEach(item => {
        if(queryParams[item] !== null && queryParams[item] !== 'null' && queryParams[item] !== undefined) this[item] = queryParams[item];
      });
    }
    if (queryParams && substitution) {
      Object.keys(substitution).forEach(item => {
        if (this[item] !== undefined) {
          this[substitution[item]] = this[item];
          delete this[item];
        }
      });
    }
  }
}
