import { Injectable } from '@angular/core';
import {ApiService} from "@app/api/services/base/api.service";
import {Observable} from "rxjs";
import {BaseResponse} from "@app/api/models/base/api.model";
import {IGuest} from "@app/api/models/components/guest/guest.model";
import {
  IGuestAddressPayload, IGuestFilePayload,
  IGuestLegalPayload,
  IGuestNaturalPayload, IGuestPasswordPayload,
  IGuestVerificationCodePayload,
  IGuestVerifyCodePayload
} from "@app/api/models/components/guest/guest-payload.model";


@Injectable()
export class GuestService {

  constructor(
      private _ApiService: ApiService,
  ) {
  }


  guest(): Observable<BaseResponse<IGuest>> {
    const path = 'Guest';
    return this._ApiService.get({path})
  }

  getVerificationCode(payload: IGuestVerificationCodePayload): Observable<BaseResponse<boolean>>{
    const path = 'guest/additional-mobile/verification-code';
    return this._ApiService.post({path, body: payload.body})
  }

  verifyCode(payload: IGuestVerifyCodePayload): Observable<BaseResponse<boolean>>{
    const path = 'guest/additional-mobile/verification-code';
    return this._ApiService.patch({path, body: payload.body})
  }

  natural(payload: IGuestNaturalPayload): Observable<BaseResponse<any>>{
    const path = 'guest/natural';
    return this._ApiService.put({path, body: payload.body})
  }

  legal(payload: IGuestLegalPayload): Observable<BaseResponse<any>>{
    const path = 'guest/legal';
    return this._ApiService.put({path, body: payload.body})
  }

  address(payload: IGuestAddressPayload): Observable<BaseResponse<any>>{
    const path = 'guest/address';
    return this._ApiService.put({path, body: payload.body})
  }

  finish(): Observable<BaseResponse<any>>{
    const path = 'guest/finish';
    return this._ApiService.patch({path, body: null})
  }

  gazette(payload: IGuestFilePayload): Observable<BaseResponse<any>>{
    const path = 'guest/gazette';
    return this._ApiService.postFile({path, body: payload.body});
  }

  nationalCard(payload: IGuestFilePayload): Observable<BaseResponse<any>>{
    const path = 'guest/national-card';
    return this._ApiService.postFile({path, body: payload.body});
  }

  nationalCardSkip(): Observable<BaseResponse<any>>{
    const path = 'guest/national-card/skip';
    return this._ApiService.patch({path, body: null})
  }

  createPassword(payload: IGuestPasswordPayload): Observable<BaseResponse<boolean>>{
    const path = 'guest/password';
    return this._ApiService.post({path, body: payload.body})
  }
}
