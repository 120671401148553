import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {PlanItemTypeEnum} from "@app/main/modules/plans/enums/plan-item-type.enum";

@Component({
  selector: 'app-plan-upgrade-modal',
  templateUrl: './plan-upgrade-modal.component.html',
  styleUrls: ['./plan-upgrade-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanUpgradeModalComponent implements OnInit {

  @Input() restrictionReason!: PlanItemTypeEnum;
  @Input() planLevel: string = "رایگان";
  @Input() expiredDate: number = 14000101;

  planVector = '../../../assets/images/icons/plans/upgrade-modal.png'
  constructor(public ngbActiveModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

}
