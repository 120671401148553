import { Injectable } from '@angular/core';
import {Observable} from "rxjs";

import {ApiService} from "@app/api/services/base/api.service";
import {BaseResponse} from "@app/api/models/base/api.model";
import {
  ISmartSendMultipleTextPreviewFirstPayload,
  ISmartSendMultipleTextPreviewSecondPayload,
  ISmartSendParametricPreviewFirstPayload,
  ISmartSendParametricPreviewSecondPayload,
  ISmartSendPayload, ISmartSendReportInfoPayload,
  ISmartSendReportPayload
} from "@app/api/models/components/smart-send/smart-send-payload.model";
import {
  ISmartSendMultipleTextPreview,
  ISmartSendReport,
  ISmartSendReportInfo, ISmartSendReportInfoItem
} from "@app/api/models/components/smart-send/smart-send.model";
import {ResponseModel} from "@shared/models/response.model";
import {PagedDataModel} from "@shared/models/paged-data.model";
import {ReportSmartSendInterface} from "@app/main/modules/report/models/report-smart-send.interface";


@Injectable()
export class SmartSendService {

  constructor(
      private _ApiService: ApiService,
  ) {
  }

  multipleTextPreviewFirst(payload: ISmartSendMultipleTextPreviewFirstPayload): Observable<BaseResponse<ISmartSendMultipleTextPreview>> {
    const path = 'smart-send/multiple-text/preview/first';
    return this._ApiService.postFile({path, body: payload.body});
  }

  multipleTextPreviewSecond(payload: ISmartSendMultipleTextPreviewSecondPayload): Observable<BaseResponse<ISmartSendMultipleTextPreview>> {
    const path = 'smart-send/multiple-text/preview/second';
    return this._ApiService.post({path, body: payload.body});
  }

  parametricPreviewFirst(payload: ISmartSendParametricPreviewFirstPayload): Observable<BaseResponse<ISmartSendMultipleTextPreview>> {
    const path = 'smart-send/parametric/preview/first';
    return this._ApiService.postFile({path, body: payload.body});
  }

  parametricPreviewSecond(payload: ISmartSendParametricPreviewSecondPayload): Observable<BaseResponse<ISmartSendMultipleTextPreview>> {
    const path = 'smart-send/parametric/preview/second';
    return this._ApiService.post({path, body: payload.body});
  }

  smartSendConfirm(payload: ISmartSendPayload): Observable<BaseResponse<boolean>>{
    const path = 'smart-send';
    return this._ApiService.patch({path, body: payload.body});
  }

  smartSendReport(payload: ISmartSendReportPayload): Observable<BaseResponse<PagedDataModel<ISmartSendReport>>>{
    const path = 'smart-send';
    return this._ApiService.get({path, queryParams: payload.params});
  }

  smartSendReportInfo(payload: ISmartSendReportInfoPayload): Observable<BaseResponse<ISmartSendReportInfo>>{
    const path = `smart-send/${payload.id}`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  smartSendReportInfoItems(payload: ISmartSendReportInfoPayload): Observable<BaseResponse<PagedDataModel<ISmartSendReportInfoItem>>>{
    const path = `smart-send/${payload.id}/detail`;
    return this._ApiService.get({path, queryParams: payload.params});
  }
}
