import {ModuleWithProviders, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from "@angular/common/http";

import {HttpClientInterceptor} from "@app/api/services/base/http-client.interceptor";
import {AuthService} from "@app/api/services/components/auth.service";
import {ApiService} from "@app/api/services/base/api.service";
import {ErrorCodeService} from "@app/api/store/error-code.service";
import {HttpErrorHandlerService} from "@app/api/services/base/http-error-handler.service";
import {PanelService} from "@app/api/services/components/panel.service";
import {GuestService} from "@app/api/services/components/guest.service";
import {AddFundService} from "@app/api/services/components/add-fund.service";
import {DashboardService} from "@app/api/services/components/dashboard.service";
import {CompleteInformationService} from "@app/api/services/components/complete-information.service";
import {ContactsService} from "@app/api/services/components/contacts.service";
import {UserEventService} from "@app/api/services/components/user-event.service";
import {PlanService} from "@app/api/services/components/plan.service";
import {UserService} from "@app/api/services/components/user.service";
import {TicketService} from "@app/api/services/components/ticket.service";
import {XmaService} from "@app/api/services/components/xma.service";
import {SimpleSendService} from "@app/api/services/components/simple-send.service";
import {UserTemplateService} from "@app/api/services/components/user-template.service";
import {SmartSendService} from "@app/api/services/components/smart-send.service";
import {CampaignSendService} from "@app/api/services/components/campaign-send.service";
import {TowerSendService} from "@app/api/services/components/tower-send.service";
import {MobileBankService} from "@app/api/services/components/mobile-bank.service";
import {UserTagService} from "@app/api/services/components/user-tag.service";
import {ReportService} from "@app/api/services/components/report.service";
import {ApiKeyService} from "@app/api/services/components/api-key.service";
import {ModulesService} from "@app/api/services/components/modules.service";
import {FastSendService} from "@app/api/services/components/fast-send.service";
import {NotificationService} from "@app/api/services/components/notification.service";
import {LineService} from "@app/api/services/components/line.service";
import {FaqService} from "@app/api/services/components/faq.service";
import {CooperationService} from "@app/api/services/components/cooperation.service";
import {PaymentService} from "@app/api/services/components/payment.service";
import {PayamakSefidService} from "@app/api/services/components/payamak-sefid.service";


@NgModule({
  providers: [
    ApiService,
    ErrorCodeService,
    HttpErrorHandlerService,
  ]
})
export class ApiModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpClientInterceptor,
          multi: true
        },
        AuthService,
        PanelService,
        GuestService,
        AddFundService,
        DashboardService,
        CompleteInformationService,
        ContactsService,
        UserEventService,
        PlanService,
        UserService,
        TicketService,
        XmaService,
        UserTemplateService,
        SimpleSendService,
        SmartSendService,
        CampaignSendService,
        TowerSendService,
        MobileBankService,
        UserTagService,
        ReportService,
        ApiKeyService,
        ModulesService,
        FastSendService,
        NotificationService,
        LineService,
        FaqService,
        CooperationService,
        PaymentService,
        PayamakSefidService,
      ]
    };
  }
}
