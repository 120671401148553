import { Injectable } from '@angular/core';
import {IMobileBankCategoryPayload} from "@app/api/models/components/mobile-bank/mobile-bank-payload.model";
import {Observable} from "rxjs";
import {BaseResponse} from "@app/api/models/base/api.model";
import {IMobileBankCategory} from "@app/api/models/components/mobile-bank/mobile-bank.model";
import {ApiService} from "@app/api/services/base/api.service";
import {
  ILinePayload,
  ILinePrebuyPayload,
  ILineServicePayload
} from "@app/api/models/components/line/line-payload.model";
import {ILine, ILineInfo, ILinePrebuy, ILineService} from "@app/api/models/components/line/line.model";

@Injectable()
export class LineService {
  constructor(
    private _ApiService: ApiService,
  ) {
  }

  line(payload: ILinePayload): Observable<BaseResponse<ILine[]>> {
    const path = `line`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  info(): Observable<BaseResponse<ILineInfo>>{
    const path = `line/info`;
    return this._ApiService.get({path});
  }

  prebuy(): Observable<BaseResponse<ILinePrebuy[]>>{
    const path = `line/prebuy`;
    return this._ApiService.get({path});
  }

  prebuyCheck(payload: ILinePrebuyPayload): Observable<BaseResponse<any>>{
    const path = `line/prebuy`;
    return this._ApiService.post({path, body: payload.body});
  }

  deletePrebuy(id: number): Observable<BaseResponse<boolean>>{
    const path = `line/prebuy/${id}`;
    return this._ApiService.delete({path});
  }

  lineService(id: number | string): Observable<BaseResponse<ILineService>>{
    const path = `line/${id}/service`;
    return this._ApiService.get({path});
  }

  createLineService(payload: ILineServicePayload): Observable<BaseResponse<any>>{
    const path = `line/${payload.id}/service`;
    return this._ApiService.postFile({path, body: payload.body});
  }

  updateLineServiceFile(payload: ILineServicePayload): Observable<BaseResponse<any>>{
    const path = `line/${payload.id}/service/files`;
    return this._ApiService.patchFile({path, body: payload.body});
  }

  lineDefault(id: number): Observable<BaseResponse<boolean>>{
    const path = `line/default/${id}`;
    return this._ApiService.patch({path, body: null});
  }
}
