import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {PrefixNumberService} from "@shared/services/prefix-number.service";


@Injectable({
  providedIn: 'root'
})
export class PrefixNumberGuard implements CanActivate {

  constructor(
    private prefixNumberService: PrefixNumberService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.prefixNumberService.storePrefixData()
  }

}
