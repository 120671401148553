import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ApiService} from "@app/api/services/base/api.service";
import {BaseResponse} from "@app/api/models/base/api.model";
import {
  IAuthAutoSignUpPayload,
  IAuthLoginPayload,
  IAuthLoginVerificationCodePayload, IAuthParamsPayload,
  IAuthPasswordRecoveryVerificationCodePayload,
  IAuthResetPasswordPayload,
  IAuthSignUpPayload,
  IAuthSignupVerificationCodePayload,
  IAuthVerificationCodePayload
} from "@app/api/models/base/auth/auth-payload.model";
import {
  IAuthAutoSignUp,
  IAuthLogin, IAuthRefCode,
  IAuthResetPassword, IAuthSetting,
  IAuthSignUp,
  IAuthVerificationCode,
  IMobileVerificationResult
} from "@app/api/models/base/auth/auth.model";


@Injectable()
export class AuthService {

  constructor(
    private _ApiService: ApiService,
  ) {
  }


  token(payload: string): Observable<BaseResponse<string>> {
    const path = "auth/token";
    return this._ApiService.put({path, body: payload})
  }

  login(payload: IAuthLoginPayload): Observable<BaseResponse<IAuthLogin>> {
    const path = `auth/login`;
    return this._ApiService.post({path, body: payload.body});
  }

  verificationCode(payload: IAuthVerificationCodePayload): Observable<BaseResponse<IAuthVerificationCode>> {
    const path = `auth/verification-code`;
    return this._ApiService.put({path, body: payload.body});
  }

  signUpVerificationCode(payload: IAuthSignupVerificationCodePayload): Observable<BaseResponse<IMobileVerificationResult>> {
    const path = `auth/sign-up/verification-code`;
    return this._ApiService.post({path, body: payload.body});
  }

  loginVerificationCode(payload: IAuthLoginVerificationCodePayload): Observable<BaseResponse<IMobileVerificationResult>> {
    const path = `auth/login/verification-code`;
    return this._ApiService.post({path, body: payload.body});
  }

  passwordRecoveryVerificationCode(payload: IAuthPasswordRecoveryVerificationCodePayload): Observable<BaseResponse<IMobileVerificationResult>> {
    const path = `auth/password-recovery/verification-code`;
    return this._ApiService.post({path, body: payload.body});
  }

  autoSignUp(payload: IAuthAutoSignUpPayload): Observable<BaseResponse<IAuthAutoSignUp>> {
    const path = `auth/auto-sign-up-code/${payload.code}`;
    return this._ApiService.get({path});
  }

  resetPassword(payload: IAuthResetPasswordPayload): Observable<BaseResponse<IAuthResetPassword>> {
    const path = `auth/password-recovery`;
    return this._ApiService.patch({path, body: payload.body});
  }

  signUp(payload: IAuthSignUpPayload): Observable<BaseResponse<IAuthSignUp>> {
    const path = `auth/sign-up`;
    return this._ApiService.post({path, body: payload.body});
  }

  uniqueUsername(payload: IAuthParamsPayload): Observable<BaseResponse<boolean>> {
    const path = `auth/username`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  existenceRefcode(payload: IAuthParamsPayload): Observable<BaseResponse<boolean>> {
    const path = `auth/existence-refCode`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  setting(): Observable<BaseResponse<IAuthSetting>>{
    const path = 'setting';
    return this._ApiService.get({path});
  }

  refCode(tempRefCode: string): Observable<BaseResponse<IAuthRefCode>>{
    const path = `auth/refCode/${tempRefCode}`;
    return this._ApiService.get({path});
  }
}
