import {EventEmitter, Injectable, Output} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";

import {ServerGuestStepEnum} from "../enums/server-guest-step.enum";
import {MciNeededGuestStepEnum} from "../enums/mci-needed-guest-step.enum";
import {GuestStepEnum} from "../enums/guest-step.enum";
import {IGuest} from "@app/api/models/components/guest/guest.model";

@Injectable({
  providedIn: 'root'
})
export class GuestStoreService {

  @Output() guestChecked: EventEmitter<boolean> = new EventEmitter<boolean>();

  private codeVerificationFailedSubject: Subject<void> = new Subject<void>();
  codeVerificationFailedObservable$ = this.codeVerificationFailedSubject.asObservable();

  private info: BehaviorSubject<IGuest | null> = new BehaviorSubject<IGuest | null>(null)
  info$: Observable<IGuest | null> = this.info.asObservable();

  private step: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  step$: Observable<number> = this.step.asObservable();

  private needSecondMobile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  needSecondMobile$: Observable<boolean> = this.needSecondMobile.asObservable();


  setInfo(info: IGuest | null) {
    this.info.next(info);
  }

  setStep(step: number) {
    this.step.next(step);
  }

  setNeedSecondMobile(item: boolean) {
    this.needSecondMobile.next(item);
  }

  translateStep(serverStep: number, needSecondMobile: boolean): number {
    if (needSecondMobile) {
      switch (serverStep) {
        case ServerGuestStepEnum.DetermineMCIMobile:
          return MciNeededGuestStepEnum.secondMobile;
        case ServerGuestStepEnum.CompletingNaturalData:
        case ServerGuestStepEnum.CompletingLegalData:
        case ServerGuestStepEnum.ImportAddressData:
          return MciNeededGuestStepEnum.personalInfo;
        case ServerGuestStepEnum.UploadNationalCard:
        case ServerGuestStepEnum.UploadGazette:
          return MciNeededGuestStepEnum.upload;
        default:
          return MciNeededGuestStepEnum.waiting;
      }
    } else {
      switch (serverStep) {
        case ServerGuestStepEnum.CompletingNaturalData:
        case ServerGuestStepEnum.CompletingLegalData:
        case ServerGuestStepEnum.ImportAddressData:
          return GuestStepEnum.personalInfo;
        case ServerGuestStepEnum.UploadNationalCard:
        case ServerGuestStepEnum.UploadGazette:
          return GuestStepEnum.upload;
        default:
          return GuestStepEnum.waiting;
      }
    }
  }

  isNeedSecondMobile(step: number, additionalMobile: number): boolean {
    if (step === 1) {
      if (additionalMobile === null) {
        return true;
      }
      throw Error('Error in step');
    } else {
      return additionalMobile !== null;
    }
  }

  updateNationalData(payload: Partial<IGuest>) {
    let info = this.info.getValue();
    if (info !== null) {
      info = {
        ...info,
        ...payload,
        step: info.isLegal ? ServerGuestStepEnum.CompletingLegalData : ServerGuestStepEnum.ImportAddressData
      }
    }
    this.setInfo(info)
  }

  updateLegalData(payload: Partial<IGuest>) {
    let info = this.info.getValue();
    if (info !== null) {
      info = {
        ...info,
        ...payload,
        step: ServerGuestStepEnum.ImportAddressData
      }
    }
    this.setInfo(info)
  }

  updateAddress(payload: Partial<IGuest>, moveForward: boolean) {
    let info = this.info.getValue();
    let needSecondMobile = this.needSecondMobile.getValue();
    if (info !== null) {
      info = {
        ...info,
        ...payload,
        step: ServerGuestStepEnum.UploadNationalCard
      }
    }
    if (moveForward){
      this.setStep(needSecondMobile ? MciNeededGuestStepEnum.upload : GuestStepEnum.upload)
    }
    this.setInfo(info)
  }


  uploadFileSuccess(payload: Partial<IGuest>) {
    let info = this.info.getValue();
    let needSecondMobile = this.needSecondMobile.getValue();
    if (info !== null) {
      info = {
        ...info,
        ...payload,
        step: ServerGuestStepEnum.Finished
      }
    }
    this.setStep(needSecondMobile ? MciNeededGuestStepEnum.waiting : GuestStepEnum.waiting)
    this.setInfo(info)
  }

  secondLineComplete(mobile: number) {
    let info = this.info.getValue();
    let needSecondMobile = this.needSecondMobile.getValue();
    if (info !== null) {
      info = {
        ...info,
        additionalMobile: mobile,
        step: ServerGuestStepEnum.CompletingNaturalData
      }
    }
    this.setStep(needSecondMobile ? MciNeededGuestStepEnum.personalInfo : GuestStepEnum.personalInfo)
    this.setInfo(info)
  }
}
