import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

import {ValidatorsService} from "@shared/services/validators.service";
import {ToastifyService} from "@shared/services/toastify.service";
import {UserService} from "@app/api/services/components/user.service";


@UntilDestroy()
@Component({
  selector: 'app-critics-suggestions',
  templateUrl: './critics-suggestions.component.html',
  styleUrls: ['./critics-suggestions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CriticsSuggestionsComponent implements OnInit {
  @Input() modalHeader!: string;
  criticsAndSuggestions: FormGroup;

  constructor(
    private _UserService: UserService,
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private toastifyService: ToastifyService,
    private validatorsService: ValidatorsService) {
    this.criticsAndSuggestions = this.formBuilder.group({
      content: new FormControl('', [this.validatorsService.required()])
    });
  }

  ngOnInit(): void {
  }

  submit() {
    const body = {
      content: this.criticsAndSuggestions.controls.content.value
    }
    this.activeModal.dismiss();
    this._UserService.feedback({
      body
    }).pipe(
      untilDestroyed(this),
    )
      .subscribe(res => {
        if (res.result) {
          this.toastifyService.success('نظر شما با موفقیت ارسال شد.');
        }
      })
  }

  cancel() {
    this.activeModal.close();
  }
}
