<div class="bootstrapper-modal">
  <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-3">
    <img alt="notify-icon" class="mb-2  tw-h-[150px]" src="assets/images/icons/modal/notify.svg">
    <h1 *ngIf="messages.length > 1">{{modalHeader}}</h1>
    <h1 *ngIf="messages.length === 1">{{messages[0].title}}</h1>
  </div>
  <div class="modal-body text-right" >
      <ul *ngIf="messages.length > 1" class="list-group mt-3">
        <li  *ngFor="let item of messages" [innerText]="item.content" class="list-group-item line-height"></li>
      </ul>
    <p *ngIf="messages.length === 1" [innerText]="messages[0].content" class="line-height"></p>
  </div>
  <div class="modal-footer text-center">
    <button (click)="onSubmit()" class="btn success-btn">
      متوجه شدم
    </button>
  </div>

</div>

