export enum FastSendTemplateTypeEnum {
  default = 1,
  custom = 0
}

export enum FastSendTemplateStatusEnum {
  Pending = 1,
  IsVerified = 2,
  UnVerified = 3,
  EnamadChecking = 4,
  XMAValidationChecking = 5
}
