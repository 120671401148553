import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ValidatorFn} from "@angular/forms";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Observable, Subject, throwError} from "rxjs";
import {debounceTime} from "rxjs/operators";

import {ValidatorsService} from "@shared/services/validators.service";
import {UtilityService} from "@shared/services/utility.service";
import {PagedDataModel} from "@shared/models/paged-data.model";
import {TextBuilderService} from "./services/text-builder.service";
import {TextBuilderTypeEnum} from "./models/text-builder-type.enum";
import {StoreService} from "@shared/services/store.service";
import {ToastifyService} from "@shared/services/toastify.service";
import {IPanelBootstrapper} from "@app/api/models/components/panel/panel.model";
import {XmaService} from "@app/api/services/components/xma.service";
import {QueryParamsModel} from "@shared/models/query-params.model";
import {UserTemplateService} from "@app/api/services/components/user-template.service";
import {IUserTemplateRow} from "@app/api/models/components/user-template/user-template-payload.model";
import {BaseResponse} from "@app/api/models/base/api.model";


@UntilDestroy()
@Component({
    selector: 'app-text-builder',
    templateUrl: './text-builder.component.html',
    styleUrls: ['./text-builder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextBuilderComponent implements OnInit, OnChanges {
    hasDoubleChar = true;
    isMaxLenValid = true;
    isMobile: boolean = false;
    showDropdown: boolean = false;
    container = 70;
    totalSize = 932;
    localSmsLen = 0;
    smsCount = 0;
    drafts!: IUserTemplateRow[];
    templates!: IUserTemplateRow[];
    remainedForbiddenWords: string[] = [];
    activeTab = 'personalize';
    form!: FormGroup;
    formURL!: FormGroup;
    errorMsg: any = null;
    @ViewChild('message') message?: ElementRef;
    @Input() title: string = "لطفا متن پیامک خود را وارد کنید."
    @Input() selectedTemplateId!: number;
    @Input() messageText!: Observable<string> | string;
    @Input() linkURL!: Observable<string>;
    @Input() forbiddenWords: string[] = [];
    @Input() textBuilderType!: TextBuilderTypeEnum;
    @Input() inputParameters!: string[];
    @Input() heightSize?: string
    @Input() optionalField?: boolean
    @Input() isURL = false;
    @Input() cancellationText!: string;
    @Input() hasTransparentBackground: boolean = true;
    @Input() validators: ValidatorFn[] = [];
    @Output() draftLoaded: EventEmitter<IUserTemplateRow> = new EventEmitter<IUserTemplateRow>();
    @Output() maxLenValidated: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() messageTextChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() messageURL: EventEmitter<string> = new EventEmitter<string>();
    searchValueSubscription: Subject<string> = new Subject<string>();
    textBuilderTypeEnum = TextBuilderTypeEnum;
    parameters!: { parameter: string, title: string; }[];
    isShortLink = true;
    bootstrap$: Observable<IPanelBootstrapper | null>;
    cancellation!: string;
    public minLength = 30;
    public errorcontent: string = 'حداقل تعداد کاراکتر مجاز برای استفاده از بهبود متن و شکلک گذاری 30کاراکتر است.'
    originalText: string = '';
    updatedText: string = '';
    isTextChanging: boolean = false;
    messages = '';
    isTextEntered: any;
    isImproved = false;
    canRedo: boolean = false;

    constructor(
        private _UserTemplateService: UserTemplateService,
        private _XmaService: XmaService,
        private utilityService: UtilityService,
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private validatorsService: ValidatorsService,
        private storeService: StoreService,
        private toast: ToastifyService,
        private textBuilderService: TextBuilderService,
    ) {

        this.bootstrap$ = this.storeService.baseData$;
        this.parameters = this.textBuilderService.parameters;
    }

    ngOnInit(): void {
        this.updateDeviceType();


        window.addEventListener('resize', () => {
            this.isMobile = window.innerWidth <= 768;
        });

        const urlPattern = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
        this.form = this.formBuilder.group({
            text: new FormControl('', [this.validatorsService.required(), ...this.validators]),
        });
        this.formURL = this.formBuilder.group({
            webURL: new FormControl('', [this.validatorsService.pattern(urlPattern)]),
        });

        this.initializeTextBuilder();
        if (this.optionalField === true) {
            this.form.get('text')?.setValidators(null)
        }
        if (this.messageText instanceof Observable) {
            this.messageText.pipe(untilDestroyed(this)).subscribe(res => {
                const persianVersion = this.textBuilderService.convertToPersian(res ? res : '');
                this.form.controls.text.patchValue(persianVersion);
            });
        } else {
            const persianVersion = this.textBuilderService.convertToPersian(this.messageText ? this.messageText : '');
            this.form.controls.text.patchValue(persianVersion);
        }
        if (this.isURL) {
            this.linkURL
                .pipe(untilDestroyed(this))
                .subscribe(res => {
                    this.formURL.controls.webURL.patchValue(res === '' ? `https://${res}` : res);
                    this.isShortLink = (res === '' || res === 'https://');
                });
        }
        this.remainedForbiddenWords = this.forbiddenWords;
        this.onMessageTextChange();
        if (this.textBuilderType === this.textBuilderTypeEnum.Template) {
            this.activeTab = 'templates';
        }

    }

    @HostListener('window:resize', [])
    onResize() {
        this.updateDeviceType();
    }

    updateDeviceType() {
        this.isMobile = window.innerWidth <= 768;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.cancellationText && changes.cancellationText.previousValue !== changes.cancellationText.currentValue) {
            this.onMessageTextChange();
        }
    }

    importURL(e: any) {
        if (e.target.value === '' || e.target.value === 'https://') {
            this.isShortLink = true;
            let expression = '';
            if (!this.isMaxLenValid) {
                return;
            }
            expression = `#link#`;
            this.form.controls.text.patchValue(this.form.controls.text.value.replaceAll(` ${expression}`, ''));
            this.messageURL.emit(e.target.value);
            this.onMessageTextChange();
        } else {
            this.isShortLink = true;
            let expression = '';
            if (!this.isMaxLenValid) {
                return;
            }
            expression = `#link#`;
            this.form.controls.text.patchValue(this.form.controls.text.value.replaceAll(` ${expression}`, ''));
            this.onMessageTextChange();
        }
    }

    generateURL() {
        this.isShortLink = false;
        this.messageURL.emit(this.formURL.get('webURL')?.value);
    }

    initializeTextBuilder() {
        switch (this.textBuilderType) {
            case TextBuilderTypeEnum.Template:
            case TextBuilderTypeEnum.TemplateAndParametric:
                this.getAllDrafts();
                this.searchValueSubscription
                    .pipe(debounceTime(1000))
                    .subscribe((res) => {
                        this.filterDesiredData(res);
                    });
                break;
            case TextBuilderTypeEnum.Textarea:
                break;
            case TextBuilderTypeEnum.Parametric:
                if (this.inputParameters.length !== 0) {
                    this.parameters = [];
                    this.inputParameters.forEach(el => {
                        this.parameters = [...this.parameters, {parameter: el, title: el}];
                    });
                } else {
                    throwError('parametric text-builder doesnt have any parameters');
                }
                break;
            case TextBuilderTypeEnum.FastSend:
                break;
            default:
                this.textBuilderType = this.textBuilderTypeEnum.Template;
                break;
        }
    }

    checkDoubleChar() {
        let data = this.form.controls.text.value;

        if (data == "" || data == null) return;
        this.hasDoubleChar = this.utilityService.containsNonLatinCodepoints(this.form.controls.text.value);
    }

    onMessageTextChange() {
        this.checkDoubleChar();
        this.totalSize = this.hasDoubleChar ? 932 : 2136;
        const repeatingContainerSize = this.hasDoubleChar ? 67 : 153;
        const firstContainerSize = this.hasDoubleChar ? 70 : 160;
        const secondContainerSize = this.hasDoubleChar ? 134 : 306;
        const thirdContainerSize = this.hasDoubleChar ? 201 : 459;
        let len;
        if (this.cancellationText) {
            this.cancellation = this.hasDoubleChar ? `لغو${this.cancellationText}` : `OFF${this.cancellationText}`;
            len = this.form.controls.text.value.length + this.cancellation?.length + 1;
        } else {
            this.cancellation = '';
            len = this.form.controls.text.value.length;
        }
        this.container = repeatingContainerSize;

        if (len === 0) {
            this.smsCount = 0;
            this.container = firstContainerSize;
            this.localSmsLen = len;
        } else if (len <= firstContainerSize) {
            this.smsCount = 1;
            this.container = firstContainerSize;
            this.localSmsLen = len;
        } else if (len > firstContainerSize && len <= secondContainerSize) {
            this.smsCount = 2;
            this.container = secondContainerSize - firstContainerSize;
            this.localSmsLen = len - firstContainerSize;
        } else if (len > secondContainerSize && len <= thirdContainerSize) {
            this.smsCount = 3;
            this.localSmsLen = len - secondContainerSize;
        } else {
            this.smsCount = 4 + Math.floor((len - thirdContainerSize - 1) / repeatingContainerSize);
            this.localSmsLen = len - (thirdContainerSize + ((this.smsCount - 4) * repeatingContainerSize));
        }
        const p = this.textBuilderService.convertToEnglish(this.form.controls.text.value);
        this.messageTextChanged.emit(p);
        this.maxLenValidating()

        const text = this.form.controls.text.value;
        this.isTextEntered = !!text;

        if (!this.originalText) {
            this.originalText = this.form.get('text')?.value || '';
        }

    }

    addSegment(parameter: { title: string, parameter: string }, element: any) {

        let expression = '';

        if (!this.isMaxLenValid) {
            return;
        }
        expression = `#${parameter.title}#`;

        let prevCharIsSpace = false;
        let nextCharIsSpace = false;
        prevCharIsSpace = this.form.controls.text.value[element.selectionStart - 1] === undefined || this.form.controls.text.value[element.selectionStart - 1] === ' ';
        nextCharIsSpace = this.form.controls.text.value[element.selectionEnd] === undefined || this.form.controls.text.value[element.selectionEnd] === ' ';
        const mainString =
            `${this.form.controls.text.value.substring(0, element.selectionStart)}${prevCharIsSpace ? '' : ' '}${expression}${nextCharIsSpace ? '' : ' '}${this.form.controls.text.value.substring(element.selectionEnd, this.form.controls.text.value.length)}`

        const selectionRange = element.selectionStart + expression.length + 1;
        this.form.controls.text.patchValue(mainString);
        element.setSelectionRange(selectionRange, selectionRange);

        this.onMessageTextChange();
        this.message?.nativeElement.focus({preventScroll: true})

    }

    maxLenValidating() {
        if (this.hasDoubleChar) {
            this.isMaxLenValid = this.form.controls.text.value.length <= 932;
        } else {
            this.isMaxLenValid = this.form.controls.text.value.length <= 2136;
        }
        this.setMaxErrorLength();
        this.maxLenValidated.emit(this.isMaxLenValid);
    }

    setMaxErrorLength() {
        if (this.isMaxLenValid) {
            this.form.controls.text.setErrors({maxLength: null});
        } else {
            this.form.controls.text.setErrors({maxLength: {valid: false}});
        }
    }

    getAllDrafts() {
        const params = new QueryParamsModel({
            pageNumber: 1, pageSize: 1000,
        })
        this._UserTemplateService.userTemplateRow({params})
            .subscribe((res: BaseResponse<PagedDataModel<IUserTemplateRow>>) => {
                if (this.selectedTemplateId) {
                    this.drafts = res.result.items.filter(i => i.id !== this.selectedTemplateId);
                    this.templates = this.drafts;
                    this.changeDetectorRef.markForCheck();
                } else {
                    this.drafts = res.result.items;
                    this.templates = res.result.items;
                    this.changeDetectorRef.markForCheck();
                }

            });
    }

    selectTemplate(draft: IUserTemplateRow) {
        this.form.controls.text.patchValue(this.textBuilderService.convertToPersian(draft.messageText));
        this.draftLoaded.emit(draft);
        this.onMessageTextChange();
        this.message?.nativeElement.focus({preventScroll: true})
    }

    switchTab(mode: string) {
        this.activeTab = mode;
    }

    onInput($event: any) {
        this.searchValueSubscription.next($event.target.value);
    }

    filterDesiredData(phrase: string) {
        this.templates = this.drafts.filter(el => (el.title.includes(phrase)) ||
            (el.title.includes(phrase.toLowerCase()) ||
                (el.title.includes(phrase.toUpperCase()))));
        this.changeDetectorRef.markForCheck();
    }

    errorStatusChanged($event: string | null) {
        if ($event !== null) {
            this.errorMsg = $event;
        } else {
            this.errorMsg = undefined;
        }
    }

    isTextareaEmpty(): boolean {

        return this.form.controls['text'].value?.length < 31;
    }

    restoreOriginalText(): void {

        if (this.isImproved) {
            this.form.get('text')?.setValue(this.originalText);
            this.isImproved = false;
            this.canRedo = true;
        }
    }

    redoText(): void {

        if (!this.isImproved) {
            this.updateFormText(this.updatedText);
            this.isImproved = true;
            this.canRedo = false;
        }
    }


    toggleDropdown(): void {

        this.showDropdown = !this.showDropdown;
    }

    handleIconClick(icon: string, isEnabled: boolean): void {
        if (!isEnabled) {
            return;
        }
        if (icon === 'icon2') {
            this.emojifyText();
        } else if (icon === 'icon3') {
            this.enhanceText();
        }

        if (this.isMobile) {
            this.toggleDropdown();
        }
    }

    enhanceText() {
        if (this.form.controls.text.value.length < this.minLength) {
            this.toast.error(this.errorcontent);
            return;
        } else {
            this.isTextChanging = true;
            this.originalText = this.form.controls.text.value;
            const body = {text: this.originalText};
            this._XmaService.textEnhance({body}).subscribe({
                next: (response: any) => {
                    this.isTextChanging = false;
                    this.isImproved = true;
                    if (response.success) {
                        this.updateFormText(response.result);
                    }
                },
                error: (err) => {
                    this.onMessageTextChange();
                    this.isTextChanging = !this.isTextChanging;
                }
            });
        }
    }

    emojifyText() {
        if (this.form.controls.text.value.length < this.minLength) {
            this.toast.error(this.errorcontent);
            return;
        } else {
            this.isTextChanging = true;
            this.originalText = this.form.controls.text.value;
            const body = {text: this.originalText};
            this._XmaService.textEmojify({body}).subscribe({
                next: (response: any) => {
                    this.isTextChanging = false;
                    this.isImproved = true;
                    if (response.success) {
                        this.updateFormText(response.result);
                    }
                },
                error: (err) => {
                    this.onMessageTextChange();
                    this.isTextChanging = !this.isTextChanging;
                }
            });
        }
    }

    updateFormText(newValue: string): void {
        this.updatedText = newValue;
        this.form.controls.text.setValue(newValue);
    }

}
