import {InjectionToken} from '@angular/core';

export const defaultErrors = {
    required: () => `پر کردن این فیلد الزامیست.`,
    minlength: ({requiredLength}: any) => `حداقل ${requiredLength} کارکتر وارد وارد شود`,
    maxlength: ({requiredLength}: any) => `حداکثر ${requiredLength} کارکتر وارد وارد شود`,
    incorrectMailFormat: () => `فرمت ایمیل اشتباه است.`,
    coefficient: ({coefficientParameter}: any) => `عدد وارد شده باید ضریب ${coefficientParameter} باشد.`,
    incorrectPasswordPattern: () => `پسورد امن نیست. پسورد باید حداقل 8 و حداکثر 32 کاراکتر باشد.`,
    beginWithZero: (prop: any) => `${prop.prop} نباید با 0 شروع شود.`,
    lengthIsDuplicate: () => `طول وارد شده وجود دارد.`,
    certainLength: ({length}: any) => `مقدار وارد شده باید ${length} کارکتر باشد`,
    passwordMatch: (err: any) => `پسورد ها برابر نیستند.`,
    inValidMax: ({max}: any) => `مقدار بیشینه برای این فیلد ${max} می‌باشد.`,
    inValidMin: ({min}: any) => `مقدار کمینه برای این فیلد ${min} می‌باشد.`,
    onlyNumbers: () => `تنها مجاز به نوشتن اعداد هستید`,
    incorrectMobile: () => `فرمت موبایل وارد شده اشتباه است`,
    ineligibleMobile: () => `توانایی احراز هویت اپراتور شما وجود ندارد`,
    pattern: ({message}: any) => message || 'فرمت مقدار وارد شده اشتباه است.',
    areaCode: () => 'کد پیش شماره الزامی است.',
    nationalId: () => 'کد ملی معتبر نیست',
    keywordValidation: () => 'پارامتر باید شامل حرف و عدد باشد. پارامتر نمی‌تواند شامل کلمات کلیدی باشد.',
    incorrectFormat: ({text}: any) => `فرمت ${text} وارد شده اشتباه است.`,
    templateParameterValidation: () => 'قالب باید دارای متغیر (انگلیسی و بدون فاصله در آن) باشد؛ مانند: #contacts#',
    digitsOnly: () => `تنها مجاز به نوشتن اعداد هستید`,
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
    providedIn: 'root',
    factory: () => defaultErrors
});
