import {map, mergeMap, switchMap, take, tap} from "rxjs/operators";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UntilDestroy} from "@ngneat/until-destroy";
import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";

import {StoreService} from "@shared/services/store.service";
import {PasswordModalComponent} from "@shared/components/password-modal/password-modal.component";
import {GuestStoreService} from "@app/main/modules/guest-info/services/guest-store.service";
import {ConfigService} from "@shared/services/config.service";
import {ModalService} from "@shared/services/modal.service";
import {HelpService} from "@app/extra-components/help/services/help.service";
import {NotificationApiService} from "@app/main/modules/notification/services/notification-api.service";
import {TokenService} from "@app/api/services/base/token.service";
import {PanelService} from "@app/api/services/components/panel.service";
import {IPanelBootstrapper} from "@app/api/models/components/panel/panel.model";
import {GuestService} from "@app/api/services/components/guest.service";
import {IGuest} from "@app/api/models/components/guest/guest.model";
import {BaseResponse} from "@app/api/models/base/api.model";
import {NotificationService} from "@app/api/services/components/notification.service";


@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class BootstrapAppService {

  constructor(
    private _NotificationService: NotificationService,
    private storeService: StoreService,
    private _PanelService: PanelService,
    private ngbModal: NgbModal,
    private _GuestService: GuestService,
    private _GuestStoreService: GuestStoreService,
    private configService: ConfigService,
    private modalService: ModalService,
    private helpService: HelpService,
    private notificationService: NotificationApiService,
    private _TokenService: TokenService,
  ) {
  }


  resetPasswordModal(): any {
    return this.ngbModal.open(PasswordModalComponent, this.configService.modalOptions('sm', 'static')).result
  }


  storeBootstrapperData(): Observable<boolean> {
    return of(!!this._TokenService.decodeToken().GuestId)
      .pipe(
        take(1),
        mergeMap((isGuest: boolean): Observable<{
          response: IGuest | IPanelBootstrapper;
          isGuest: boolean
        }> => {
          return this.handleData(isGuest)
        }),
        map(({response, isGuest}: {
          response: IGuest | IPanelBootstrapper;
          isGuest: boolean
        }): any => {

          return this.saveData({response, isGuest});

        }),
      )
  }


  handleData(isGuest: boolean): Observable<{
    response: IGuest | IPanelBootstrapper;
    isGuest: boolean
  }> {
    if (isGuest) {
      return this.fetchOrGetDataGuest(isGuest);
    } else {
      return this.fetchOrGetDataBootstrap(isGuest);
    }
  }


  saveData(data: {
    response: IGuest | IPanelBootstrapper;
    isGuest: boolean
  }) {
    if (data.response && this._TokenService.getAccessToken()) {
      if (data.isGuest) {
        this._GuestStoreService.setInfo(data.response as IGuest);
      } else {
        this.helpService.helpItems = (data.response as IPanelBootstrapper)['helpItems'];
        this.storeService.setBaseData(data.response as IPanelBootstrapper);
      }
      return true;
    } else {
      return false;
    }
  }


  bootstrapperNotify(result: IPanelBootstrapper) {
    let unreadCustomNotifications = result.notificationsData.unreadCustomNotifications
    if (unreadCustomNotifications.length > 0 && unreadCustomNotifications.find(a => a.showAtEntry)) {
      if (!this.ngbModal.hasOpenModals()) {
        this.modalService.BootstrapperNotify(unreadCustomNotifications.filter(a => a.showAtEntry)).then(
          (ids) => {
            const body = {
              ids: ids
            }
            this._NotificationService.customRead({body}).subscribe(
              () => {
                const baseData = this.storeService.getBaseData();
                if (baseData) {
                  this.storeService.setBaseData({
                    ...baseData,
                    notificationsData: {
                      ...baseData.notificationsData,
                      totalUnreadCustomNotificationsCount: baseData.notificationsData.totalUnreadCustomNotificationsCount - ids.length,
                    }
                  });
                }
              }
            )
          }
        )
      }
    }
  }


  fetchOrGetDataBootstrap(isGuest: boolean): Observable<{
    response: IPanelBootstrapper,
    isGuest: boolean
  }> {
    return this.storeService.baseData$.pipe(
      take(1),
      switchMap((localValue) => {
          if (!localValue) {
            return this._PanelService.bootstrapper()
              .pipe(
                map(resp => resp.result),
                tap((result: IPanelBootstrapper) => {
                  if (result.userData.hasPassword) {
                    this.bootstrapperNotify(result)
                  } else {
                    this.resetPasswordModal().then(
                      (isPasswordSet: boolean) => {
                        if (isPasswordSet) {
                          this.bootstrapperNotify(result)
                        }
                      }
                    );
                  }
                }),
              );
          } else {
            return of(localValue);
          }
        }
      ),
      map((data: IPanelBootstrapper | null): {
          response: IPanelBootstrapper,
          isGuest: boolean
        } => {
          return {response: data as IPanelBootstrapper, isGuest}
        }
      )
    )
  }


  fetchOrGetDataGuest(isGuest: boolean): Observable<{
    response: IGuest,
    isGuest: boolean
  }> {
    return this._GuestStoreService.info$.pipe(
      take(1),
      switchMap((localValue) => {
          if (!localValue) {
            return this._GuestService.guest()
              .pipe(
                map((response: BaseResponse<IGuest>) => {
                  if (!response.result.hasPassword) {
                    this.resetPasswordModal();
                  }
                  return response.result
                })
              );


          } else {
            return of(localValue);
          }
        }
      ),
      map((data: IGuest | null): {
          response: IGuest,
          isGuest: boolean
        } => {
          return {response: data as IGuest, isGuest}
        }
      )
    )
  }
}
