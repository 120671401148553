import {Pipe, PipeTransform} from '@angular/core';
import {PlanItemTypeEnum} from "@app/main/modules/plans/enums/plan-item-type.enum";
import {PlanItemsNameEnum} from "@app/main/modules/plans/enums/plan-items-name.enum";

@Pipe({
  name: 'plansItemName'
})
export class PlansItemNamePipe implements PipeTransform {

  transform(value: PlanItemTypeEnum): string | undefined {
    const planItemTypeEnum = PlanItemTypeEnum;
    const planItemsNameEnum = PlanItemsNameEnum
    const propertyName = planItemTypeEnum[value];
    return (planItemsNameEnum as any)[propertyName];
  }

}
