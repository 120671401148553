import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ConfigService} from "@shared/services/config.service";
import {DeleteRequest, GetRequest, PatchRequest, PostRequest, PutRequest} from "@app/api/models/base/api.model";


@UntilDestroy()
@Injectable()
export class ApiService {
    private readonly serverURL: string = this._ConfigService.baseURL;
    private httpOptions: any = {
        headers: new HttpHeaders({'Content-Type': 'application/json'}),
        observe: 'response',
    };


    constructor(private _HttpClient: HttpClient,
                private _ConfigService: ConfigService,
    ) {
    }


    cleanUpPayload(payload: any) {
        if (payload && typeof payload === 'object') {
            Object.keys(payload).forEach(el => {
                if (typeof payload[el] === 'string') {
                    payload[el] = payload[el].trim();
                }
            });
        }
    }


    get<T>(payload: GetRequest): Observable<any> {
        let options = {
            ...this.httpOptions,
        };
        if (payload.queryParams) options['params'] = payload.queryParams;

        const serverURL: string = payload.serverUrl ?? this.serverURL;

        return this._HttpClient.get(serverURL + payload.path, options)
            .pipe(
                map((res: any) => {
                    return res.body;
                })
            );
    }


    post<T>(payload: PostRequest<T>): Observable<any> {
        this.cleanUpPayload(payload.body);

        let options = {
            ...this.httpOptions,
        };

        if (payload.queryParams) options['params'] = payload.queryParams;

        const serverURL: string = payload.serverUrl ?? this.serverURL;

        return this._HttpClient.post((serverURL) + payload.path, payload.body, options)
            .pipe(
                map((res: any) => {
                    return res.body;
                })
            );
    }


    put<T>(payload: PutRequest<T>): Observable<any> {
        this.cleanUpPayload(payload.body);

        let options = {
            ...this.httpOptions,
        };

        if (payload.queryParams) options['params'] = payload.queryParams;

        const serverURL: string = payload.serverUrl ?? this.serverURL;

        return this._HttpClient.put((serverURL) + payload.path, payload.body, options)
            .pipe(
                map((res: any) => {
                    return res.body;
                })
            );
    }


    patch<T>(payload: PatchRequest<T>): Observable<any> {
        this.cleanUpPayload(payload.body);

        let options = {
            ...this.httpOptions,
        };

        if (payload.queryParams) options['params'] = payload.queryParams;

        const serverURL: string = payload.serverUrl ?? this.serverURL;

        return this._HttpClient.patch((serverURL) + payload.path, payload.body, options)
            .pipe(
                map((res: any) => {
                    return res.body;
                })
            );
    }


    delete<T>(payload: DeleteRequest<T>): Observable<any> {
        this.cleanUpPayload(payload.body);

        let options = {
            ...this.httpOptions,
        };

        if (payload.queryParams) options['params'] = payload.queryParams;

        if (payload.body) options['body'] = payload.body;

        const serverURL: string = payload.serverUrl ?? this.serverURL;
        return this._HttpClient.delete((serverURL) + payload.path, options)
            .pipe(
                map((res: any) => {
                    return res.body;
                })
            );
    }


    postFile<T>(payload: PostRequest<T>): any {

        const serverURL: string = payload.serverUrl ?? this.serverURL;

        return this._HttpClient.post(serverURL + payload.path, payload.body, {
            reportProgress: false,
            observe: 'response',
        })
            .pipe(
                map((res: any) => {
                    return res.body;
                })
            );
    }


    putFile<T>(payload: PutRequest<T>): any {

        const serverURL: string = payload.serverUrl ?? this.serverURL;

        return this._HttpClient.put(serverURL + payload.path, payload.body, {
            reportProgress: false,
            observe: 'response',
        })
            .pipe(
                map((res: any) => {
                    return res.body;
                })
            );

    }

    patchFile<T>(payload: PatchRequest<T>): any {

        const serverURL: string = payload.serverUrl ?? this.serverURL;

        return this._HttpClient.patch(serverURL + payload.path, payload.body, {
            reportProgress: false,
            observe: 'response',
        })
            .pipe(
                map((res: any) => {
                    return res.body;
                })
            );

    }
}
