import {Injectable} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Observable, of} from "rxjs";
import {catchError, map, tap} from "rxjs/operators";
import {UntilDestroy} from "@ngneat/until-destroy";

import {PlanUpgradeModalComponent} from "@shared/components/plan-upgrade-modal/plan-upgrade-modal.component";
import {ConfigService} from "@shared/services/config.service";
import {StoreService} from "@shared/services/store.service";
import {PlanItemTypeEnum} from "@app/main/modules/plans/enums/plan-item-type.enum";
import {IPanelBootstrapper} from "@app/api/models/components/panel/panel.model";
import {PlanService} from "@app/api/services/components/plan.service";


@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class PlanHandlerService {
  constructor(
    private ngbModal: NgbModal,
    private storeService: StoreService,
    private _PlanService: PlanService,
    private configService: ConfigService,
  ) {
  }

  hasPlan(): Observable<boolean> {
    return this.storeService.baseData$.pipe(
      map((bootstrap: null | IPanelBootstrapper) => {
        if (!bootstrap) return false;

        if (!bootstrap.userPlanData) return false;

        return bootstrap.userPlanData.expireAt >= Math.floor(Date.now() / 1000);
      }),
    )
  }

  checkLimitation(planItem: PlanItemTypeEnum): Observable<boolean> {
    return this._PlanService.itemLimitation(planItem).pipe(
      map(resp => resp.result),
      catchError(() => {
        return of(false)
      }),
      tap((hasAccess: boolean) => {
        if (!hasAccess) this.planInterceptorHandler(planItem);
      }),
    )
  }

  canAccess(planItem: PlanItemTypeEnum) {
    return this.storeService.baseData$.pipe(
      map((bootstrap: null | IPanelBootstrapper) => {
        if (!bootstrap) return false;

        if (!bootstrap.userPlanData) return false;

        return bootstrap.userPlanData.itemAccess.find(f => f.type == planItem)?.hasAccess || false;
      }),
      tap((hasAccess: boolean) => {
        if (!hasAccess) this.planInterceptorHandler(planItem);
      }),
    )
  }


  planInterceptorHandler(planEnum: PlanItemTypeEnum) {
    const userPlanData = this.storeService.getBaseData()?.userPlanData;
    const modalRef = this.ngbModal.open(PlanUpgradeModalComponent, this.configService.modalOptions());
    modalRef.componentInstance.planLevel = userPlanData?.name;
    modalRef.componentInstance.expiredDate = userPlanData?.expireAt;
    modalRef.componentInstance.restrictionReason = planEnum;
  }
}

