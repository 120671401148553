import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {HttpClientModule} from "@angular/common/http";
import {ToastrModule} from "ngx-toastr";
import {CookieService} from "ngx-cookie-service";
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {ServiceWorkerModule} from '@angular/service-worker';

import {WsToastrComponent} from "@shared/components/ws-toastr/ws-toastr.component";
import {C404Component} from '@app/extra-components/c404/c404.component';
import {MaintenanceComponent} from '@app/extra-components/maintenance/maintenance.component';
import {AppComponent} from '@app/app.component';
import {AppRoutingModule} from '@app/app-routing.module';
import {environment} from "@env/environment";
import {UpdateModalComponent} from '@app/extra-components/update-modal/update-modal.component';
import {
  BootstrapperNotifyModalComponent
} from '@shared/components/bootstrapper-notify-modal/bootstrapper-notify-modal.component';
import {InitialLoadingComponent} from "@app/extra-components/initial-loading/initial-loading.component";
import {
  DeviceConfirmationModalComponent
} from '@shared/components/device-confirmation-modal/device-confirmation-modal.component';
import {CheckboxModule} from "@shared/components/checkbox/checkbox.module";
import {ApiModule} from "@app/api/api.module";


@NgModule({
  declarations: [
    AppComponent,
    WsToastrComponent,
    C404Component,
    InitialLoadingComponent,
    MaintenanceComponent,
    UpdateModalComponent,
    BootstrapperNotifyModalComponent,
    DeviceConfirmationModalComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    CheckboxModule,
    ApiModule.forRoot(),
    ToastrModule.forRoot({
      toastComponent: WsToastrComponent,
      progressBar: false,
      positionClass: 'toast-top-right',
    }),
    NgxSkeletonLoaderModule.forRoot({
      animation: "pulse"
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
  ],
  providers: [
    CookieService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

