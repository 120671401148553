<div class="plan-upgrade">
  <div class="tw-flex tw-flex-col tw-items-center tw-gap-3 ">
    <img [src]="planVector" alt="delete-icon">
    <h1>ارتقا پلن</h1>
  </div>
  <div class="modal-body text-center">
    <p>کاربر گرامی، به دلیل محدودیت
      <ng-container *ngIf="restrictionReason | plansItemName">
        در
        {{restrictionReason | plansItemName}}
      </ng-container>
      نیاز به ارتقا پلن خود دارید.
    </p>

    <ul>
      <li>
        <span>پلن فعلی شما</span>
        <span>{{planLevel}}</span>
      </li>
      <li>
        <span>تا تاریخ</span>
        <span>{{expiredDate | wsDate: true}}</span>
      </li>
    </ul>
  </div>
  <div class="modal-footer text-center">
    <button class="btn success-btn"
            (click)="ngbActiveModal.dismiss()"
            [routerLink]="['/plans']">
      ارتقا پلن
    </button>
    <button (click)="ngbActiveModal.dismiss()"
            class="btn dark-btn mr-3">بازگشت
    </button>
  </div>

</div>

