import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

import {ApiService} from "@app/api/services/base/api.service";
import {BaseResponse} from "@app/api/models/base/api.model";
import {PagedDataModel} from "@shared/models/paged-data.model";
import {IFastSendInfo, IFastSendSentSms, IFastSendTemplate} from "@app/api/models/components/fast-send/fast-send.model";
import {
  IFastSendInfoPayload, IFastSendSentSmsPayload,
  IFastSendTemplateCreatePayload, IFastSendTemplateModifyPayload,
  IFastSendTemplatePayload
} from "@app/api/models/components/fast-send/fast-send-payload.model";


@Injectable()
export class FastSendService {

  constructor(
    private _ApiService: ApiService,
  ) {
  }

  test(): Observable<BaseResponse<any>> {
    const path = `fast-send/test`;
    return this._ApiService.post({path, body: null});
  }

  updateLine(id: number): Observable<BaseResponse<any>> {
    const path = `fast-send/line/${id}`;
    return this._ApiService.put({path, body: null});
  }

  templates(payload: IFastSendTemplatePayload): Observable<BaseResponse<PagedDataModel<IFastSendTemplate>>> {
    const path = `fast-send-template`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  createTemplate(payload: IFastSendTemplateCreatePayload): Observable<BaseResponse<any>>{
    const path = `fast-send-template`;
    return this._ApiService.post({path, body: payload.body});
  }

  updateTemplate(payload: IFastSendTemplateModifyPayload): Observable<BaseResponse<any>>{
    const path = `fast-send-template/${payload.id}`;
    return this._ApiService.put({path, body: payload.body});
  }

  deleteTemplate(id: number):Observable<BaseResponse<any>>{
    const path = `fast-send-template/${id}`;
    return this._ApiService.delete({path});
  }

  info(payload: IFastSendInfoPayload): Observable<BaseResponse<IFastSendInfo>> {
    const path = `fast-send/info`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  sentSms(payload: IFastSendSentSmsPayload): Observable<BaseResponse<PagedDataModel<IFastSendSentSms>>> {
    const path = `fast-send/sent-sms`;
    return this._ApiService.get({path, queryParams: payload.params});
  }
}
