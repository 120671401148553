import {Injectable} from '@angular/core';
import {ApiService} from "@app/api/services/base/api.service";
import {Observable} from "rxjs";
import {BaseResponse} from "@app/api/models/base/api.model";
import {PagedDataModel} from "@shared/models/paged-data.model";
import {IUserTemplateRow} from "@app/api/models/components/user-template/user-template-payload.model";
import {
    IUserTemplateCreatePayload, IUserTemplateModifyPayload,
    IUserTemplateRowPayload
} from "@app/api/models/components/user-template/user-template.model";


@Injectable()
export class UserTemplateService {

    constructor(
        private _ApiService: ApiService,
    ) {
    }

    userTemplateRow(payload: IUserTemplateRowPayload): Observable<BaseResponse<PagedDataModel<IUserTemplateRow>>> {
        const path = `user-template`;
        return this._ApiService.get({path, queryParams: payload.params});
    }

    userTemplate(templateId: number): Observable<BaseResponse<IUserTemplateRow>> {
        const path = `user-template/${templateId}`;
        return this._ApiService.get({path});
    }

    createUserTemplate(payload: IUserTemplateCreatePayload): Observable<BaseResponse<number>> {
        const path = `user-template`;
        return this._ApiService.post({path, body: payload.body});
    }

    updateUserTemplate(payload: IUserTemplateModifyPayload): Observable<BaseResponse<boolean>> {
        const path = `user-template/${payload.templateId}`;
        return this._ApiService.put({path, body: payload.body});
    }

    deleteUserTemplate(templateId: number): Observable<BaseResponse<boolean>> {
        const path = `user-template/${templateId}`;
        return this._ApiService.delete({path});
    }
}
