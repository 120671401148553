import { Injectable } from '@angular/core';
import {Observable} from "rxjs";

import {ApiService} from "@app/api/services/base/api.service";
import {BaseResponse} from "@app/api/models/base/api.model";
import {PagedDataModel} from "@shared/models/paged-data.model";
import {
  IUserTagCreatePayload,
  IUserTagModifyPayload, IUserTagMovePayload,
  IUserTagPayload,
} from "@app/api/models/components/user-tag/user-tag-payload.model";
import {IUserTag, IUserTagAll, IUserTagMessage,} from "@app/api/models/components/user-tag/user-tag.model";


@Injectable()
export class UserTagService {

  constructor(
    private _ApiService: ApiService,
  ) {
  }

  userTag(payload: IUserTagPayload): Observable<BaseResponse<PagedDataModel<IUserTag>>> {
    const path = `user-tag`;
    return this._ApiService.get({path, queryParams: payload.params});
  }


  userTagAll(payload: IUserTagPayload): Observable<BaseResponse<IUserTagAll>> {
    const path = `user-tag/all`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  createUserTag(payload: IUserTagCreatePayload): Observable<BaseResponse<number>> {
    const path = `user-tag`;
    return this._ApiService.post({path, body: payload.body});
  }

  updateUserTag(payload: IUserTagModifyPayload): Observable<BaseResponse<boolean>> {
    const path = `user-tag/${payload.tagId}`;
    return this._ApiService.put({path, body: payload.body});
  }

  deleteTag(tagId: number): Observable<BaseResponse<boolean>>{
    const path = `user-tag/${tagId}/remove`;
    return this._ApiService.delete({path});
  }

  moveUserTag(payload: IUserTagMovePayload): Observable<BaseResponse<boolean>>{
    const path = `user-tag/${payload.tagId}/move`;
    return this._ApiService.post({path, body: payload.body});
  }

  userTagMessages(payload: IUserTagPayload): Observable<BaseResponse<PagedDataModel<IUserTagMessage>>>{
    const path = `user-tag/messages`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  userTagMessageInfoExcel(payload: IUserTagPayload): Observable<BaseResponse<any>>{
    const path = `user-tag/message-details/excel`;
    return this._ApiService.get({path, queryParams: payload.params});
  }
}
