import { Injectable } from '@angular/core';
import {Observable} from "rxjs";

import {ApiService} from "@app/api/services/base/api.service";
import {BaseResponse} from "@app/api/models/base/api.model";
import {IPlanDowngrade, IPlanHistory, IPlanPackage, IPlanUserData} from "@app/api/models/components/plan/plan.model";
import {PlanItemTypeEnum} from "@app/main/modules/plans/enums/plan-item-type.enum";


@Injectable()
export class PlanService {
  constructor(
    private _ApiService: ApiService,
  ) {
  }

  planPackage(): Observable<BaseResponse<IPlanPackage[]>> {
    const path = `user/me/plan/package`;
    return this._ApiService.get({path});
  }

  freeAssign(): Observable<BaseResponse<IPlanUserData>>{
    const path = 'user/me/plan/package/free/assign';
    return this._ApiService.post({path, body: null});
  }


  itemLimitation(planItemEnum: PlanItemTypeEnum): Observable<BaseResponse<boolean>>{
    const path = `plan/${planItemEnum}/limitation`;
    return this._ApiService.get({path});
  }

  downgrade(planPackageId: number): Observable<BaseResponse<IPlanDowngrade>>{
    const path = `user/me/plan/package/${planPackageId}/downgrade/check`;
    return this._ApiService.get({path});
  }

  history(): Observable<BaseResponse<IPlanHistory[]>>{
    const path = 'plan/history';
    return this._ApiService.get({path});
  }
}
