export enum AppNotifications {
  CreditNotEnough = 'برای انجام عملیات، اعتبار شما کافی نمی‌باشد',
  RetryOperation = 'RetryOperation',
  DowngradePlanIsNotPermitted = "خرید این پلن برای شما امکان پذیر نیست. لطفا پلن دیگری را انتخاب کنید.",

  /*
  * separate errors
  */
  RefreshTokenNotFound = 'رفرش توکن یافت نشد',
  PasswordNotMatch = 'پسورد های وارد شده با هم یکسان نمی‌باشد.',
  VerificationCodeSent = 'کد به شماره همراه وارد شده ارسال شد.',
  VerificationCodeAlreadySent = 'از آخرین کد ارسالی استفاده کنید.',
  ContactsAdded = 'مخاطبان با موفقیت به سامانه اضافه شدند.',
  ImageSizeInvalid = 'سایز عکس بیشتر از مقدار مجاز است.',
  UploadedFileSizeError = 'سایز فایل انتخابی بیشتر از حد مجاز می‌باشد.',
  UploadedFileFormatIncorrect = 'فرمت فایل انتخابی قابل قبول نیست.',
  PasswordChangeSuccess = 'تغییر پسورد با موفقیت انجام شد.',
  EventCreated = 'رویداد با موفقیت به سامانه اضافه شد.',
  UploadedSuccessfully = 'فایل مورد نظر با موفقیت بارگزاری شد.',
  ChangesSaved = 'تغییرات با موفقیت ذخیره شد.',
  EventDeleted = 'رویداد مورد نظر حذف شد.',
  GroupNameChanged = 'نام گروه با موفقیت تغییر یافت.',
  GroupDeleted = 'گروه مورد نظر حذف شد.',
  GroupCreated = 'گروه با موفقیت ایجاد شد.',
  DeviceCreated = 'اپلیکیشن با موفقیت ایجاد شد.',
  DeviceConnected = 'اپلیکیشن با موفقیت متصل شد.',
  IpAddedSuccessfully = 'آی پی با موفقیت اضافه گردید.',
  LoginAgain = 'لطفا مجدد وارد حساب کاربری خود شوید.',
  UnknownError = 'خطایی در سامانه رخ داده است.',
  NoAccess = 'متأسفانه شما اجازه دسترسی به این بخش را ندارید.',
  /*
  * separate errors
  */
}
