import { Injectable } from '@angular/core';
import {Observable} from "rxjs";

import {ApiService} from "@app/api/services/base/api.service";
import {BaseResponse} from "@app/api/models/base/api.model";
import {PagedDataModel} from "@shared/models/paged-data.model";
import {
  INotification, INotificationInfo,
} from "@app/api/models/components/notification/notification.model";
import {
  INotificationCustomPayload, INotificationCustomReadPayload,
  INotificationSystemPayload
} from "@app/api/models/components/notification/notification-payload.model";


@Injectable()
export class NotificationService {
  constructor(
    private _ApiService: ApiService,
  ) {
  }

  system(payload: INotificationSystemPayload): Observable<BaseResponse<PagedDataModel<INotification>>> {
    const path = 'notification/system';
    return this._ApiService.get({path, queryParams: payload.params});
  }

  systemInfo(id: number): Observable<BaseResponse<INotificationInfo>> {
    const path = `notification/system/${id}`;
    return this._ApiService.get({path});
  }

  custom(payload: INotificationCustomPayload): Observable<BaseResponse<PagedDataModel<INotification>>> {
    const path = 'notification/custom';
    return this._ApiService.get({path, queryParams: payload.params});
  }

  customInfo(id: number): Observable<BaseResponse<INotificationInfo>> {
    const path = `notification/custom/${id}`;
    return this._ApiService.get({path});
  }


  customRead(payload: INotificationCustomReadPayload): Observable<BaseResponse<any>> {
    const path = `notification/custom/read`;
    return this._ApiService.patch({path, body: payload.body});
  }
}
