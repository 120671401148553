import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {Router, RouterModule} from "@angular/router";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {ToastrModule} from "ngx-toastr";
import {CookieService} from "ngx-cookie-service";
import {WsToastrComponent} from "@shared/components/ws-toastr/ws-toastr.component";
import {HttpClientInterceptor} from "@shared/services/http-client.interceptor";
import {C404Component} from './extra-components/c404/c404.component';
import {MaintenanceComponent} from './extra-components/maintenance/maintenance.component';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {StoreService} from "@shared/services/store.service";
import {environment} from "@env/environment";
import {ServiceWorkerModule} from '@angular/service-worker';
import {UpdateModalComponent} from './extra-components/update-modal/update-modal.component';
import {TokenService} from "@shared/services/token.service";
import {
  BootstrapperNotifyModalComponent
} from '@shared/components/bootstrapper-notify-modal/bootstrapper-notify-modal.component';
import {ApiService} from "@shared/services/api.service";
import {InitialLoadingComponent} from "./extra-components/initial-loading/initial-loading.component";
import {LoggingInterceptorService} from "@shared/services/logging-interceptor.service";
import * as Sentry from "@sentry/angular-ivy";
import { DeviceConfirmationModalComponent } from './shared/components/device-confirmation-modal/device-confirmation-modal.component';
import {CheckboxModule} from "@shared/components/checkbox/checkbox.module";


function initApp(http: HttpClient, router: Router,
                 storeService: StoreService,
                 tokenService: TokenService,
                 apiService: ApiService
) {
  return async (): Promise<any> => {
    const token = tokenService.getAccessToken();
    if (token) {
      storeService.retrieveDataFromCookie(token)
    } else {
      storeService.setIsGuest(false)
    }
  }
}


Sentry.init({
    dsn: "https://d304cad7bf5460efaf78eaa7e279fd7c@o4507163914207232.ingest.us.sentry.io/4507163932098560",
});

@NgModule({
  declarations: [
    AppComponent,
    WsToastrComponent,
    C404Component,
    InitialLoadingComponent,
    MaintenanceComponent,
    UpdateModalComponent,
    BootstrapperNotifyModalComponent,
    DeviceConfirmationModalComponent,
  ],
    imports: [
        BrowserModule,
        RouterModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        CheckboxModule,
        ToastrModule.forRoot({
            toastComponent: WsToastrComponent,
            progressBar: false,
            positionClass: 'toast-top-right',
        }),
        NgxSkeletonLoaderModule.forRoot({
            animation: "pulse"
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production
        }),
    ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [HttpClient, Router, StoreService, TokenService, ApiService],
      multi: true
    },
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoggingInterceptorService,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

