import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

import {ApiService} from "@app/api/services/base/api.service";
import {BaseResponse} from "@app/api/models/base/api.model";
import {
  IMobileBankCategory,
  IMobileBankPreview,
  IMobileBankReport,
  IMobileBankReportInfo, IMobileBankReportInfoItems
} from "@app/api/models/components/mobile-bank/mobile-bank.model";
import {
  IMobileBankCategoryPayload,
  IMobileBankConfirmPayload,
  IMobileBankModifyPayload,
  IMobileBankPreviewPayload, IMobileBankReportInfoItemsPayload,
  IMobileBankReportPayload
} from "@app/api/models/components/mobile-bank/mobile-bank-payload.model";
import {PagedDataModel} from "@shared/models/paged-data.model";


@Injectable()
export class MobileBankService {

  constructor(
    private _ApiService: ApiService,
  ) {
  }

  category(payload: IMobileBankCategoryPayload): Observable<BaseResponse<IMobileBankCategory[]>> {
    const path = `mobile-bank/categories`;
    return this._ApiService.get({path, queryParams: payload.params});
  }


  preview(payload: IMobileBankPreviewPayload): Observable<BaseResponse<IMobileBankPreview>> {
    const path = 'mobile-bank';
    return this._ApiService.post({path, body: payload.body})
  }

  mobileBankConfirm(payload: IMobileBankConfirmPayload): Observable<BaseResponse<boolean>> {
    const path = 'mobile-bank';
    return this._ApiService.patch({path, body: payload.body})
  }

  mobileBankUpdate(payload: IMobileBankModifyPayload): Observable<BaseResponse<boolean>> {
    const path = `mobile-bank/${payload.id}`;
    return this._ApiService.patch({path, body: payload.body})
  }

  deleteMobileBankReport(id: number): Observable<BaseResponse<boolean>> {
    const path = `mobile-bank/report/${id}`;
    return this._ApiService.delete({path})
  }

  retryMobileBankReport(id: number): Observable<BaseResponse<boolean>> {
    const path = `mobile-bank/report/${id}`;
    return this._ApiService.patch({path, body: null})
  }

  mobileBankReport(payload: IMobileBankReportPayload): Observable<BaseResponse<PagedDataModel<IMobileBankReport>>> {
    const path = 'mobile-bank/report';
    return this._ApiService.get({path, queryParams: payload.params});
  }

  mobileBankReportInfo(id: number): Observable<BaseResponse<IMobileBankReportInfo>> {
    const path = `mobile-bank/report/${id}`;
    return this._ApiService.get({path});
  }

  mobileBankReportInfoItems(payload: IMobileBankReportInfoItemsPayload): Observable<BaseResponse<PagedDataModel<IMobileBankReportInfoItems>>> {
    const path = `mobile-bank/report/${payload.id}/details`;
    return this._ApiService.get({path, queryParams: payload.params});
  }
}
