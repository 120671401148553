import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

import {ApiService} from "@app/api/services/base/api.service";
import {ResponseModel} from "@shared/models/response.model";
import {PagedDataModel} from "@shared/models/paged-data.model";
import {
  IPayamakSefidClub,
  IPayamakSefidClubConnectionCode,
  IPayamakSefidClubDetail,
  IPayamakSefidClubDevice,
  IPayamakSefidLog,
  IPayamakSefidSubscriptionMessage
} from "@app/api/models/components/payamak-sefid/payamak-sefid.model";
import {
  IPayamakSefidAddClubPayload,
  IPayamakSefidClubDevicesPayload,
  IPayamakSefidClubPasswordUpdatePayload,
  IPayamakSefidClubPayload,
  IPayamakSefidClubSubscriptionMessageUpdatePayload,
  IPayamakSefidClubUpdatePayload,
  IPayamakSefidLogsPayload
} from "@app/api/models/components/payamak-sefid/payamak-sefid-payload.model";


@Injectable()
export class PayamakSefidService {

  constructor(private _ApiService: ApiService) {
  }


  getClubs(payload: IPayamakSefidClubPayload): Observable<ResponseModel<PagedDataModel<IPayamakSefidClub>>> {
    const path = 'payamaksefid/club';
    return this._ApiService.get({path, queryParams: payload.params});
  }

  addClub(payload: IPayamakSefidAddClubPayload): Observable<ResponseModel<any>> {
    const path = 'payamaksefid/club';
    return this._ApiService.post({path, body: payload.body});
  }

  clubConnectionCode(clubId: number): Observable<ResponseModel<IPayamakSefidClubConnectionCode>> {
    const path = `payamaksefid/club/${clubId}/connection/code`;
    return this._ApiService.post({path, body: null});
  }

  getClub(clubId: number): Observable<ResponseModel<IPayamakSefidClubDetail>> {
    const path = `payamaksefid/club/${clubId}`;
    return this._ApiService.get({path});
  }

  updateClub(payload: IPayamakSefidClubUpdatePayload): Observable<ResponseModel<boolean>> {
    const path = `payamaksefid/club/${payload.clubId}`;
    return this._ApiService.put({path, body: payload.body});
  }

  removeClub(clubId: number): Observable<ResponseModel<boolean>> {
    const path = `payamaksefid/club/${clubId}`;
    return this._ApiService.delete({path});
  }

  clubSubscriptionMessage(clubId: number): Observable<ResponseModel<IPayamakSefidSubscriptionMessage>> {
    const path = `payamaksefid/club/${clubId}/subscription-message`;
    return this._ApiService.get({path});
  }

  updateClubSubscriptionMessage(payload: IPayamakSefidClubSubscriptionMessageUpdatePayload): Observable<ResponseModel<boolean>> {
    const path = `payamaksefid/club/${payload.clubId}/subscription-message`;
    return this._ApiService.put({path, body: payload.body});
  }

  getLogs(payload: IPayamakSefidLogsPayload): Observable<ResponseModel<PagedDataModel<IPayamakSefidLog>>> {
    const path = `payamaksefid/subscription-message/sent/today`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  getClubDevices(payload: IPayamakSefidClubDevicesPayload): Observable<ResponseModel<PagedDataModel<IPayamakSefidClubDevice>>> {
    const path = `payamaksefid/club/${payload.clubId}/device`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  removeDevice(deviceId: number): Observable<ResponseModel<boolean>> {
    const path = `payamaksefid/device/${deviceId}`;
    return this._ApiService.delete({path});
  }

  getClubPassword(clubId: number): Observable<ResponseModel<number>> {
    const path = `payamaksefid/club/${clubId}/password`;
    return this._ApiService.get({path});
  }

  updateClubPassword(payload: IPayamakSefidClubPasswordUpdatePayload): Observable<ResponseModel<boolean>> {
    const path = `payamaksefid/club/${payload.clubId}/password`;
    return this._ApiService.put({path, body: payload.body});
  }
}
