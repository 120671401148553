import { Injectable } from '@angular/core';
import {Observable} from "rxjs";

import {ApiService} from "@app/api/services/base/api.service";
import {BaseResponse} from "@app/api/models/base/api.model";
import {
  ISimpleSendContactsPreviewPayload, ISimpleSendFileFirstPreviewPayload, ISimpleSendFileSecondPreviewPayload,
  ISimpleSendManualFirstPreviewPayload,
  ISimpleSendManualSecondPreviewPayload, ISimpleSendPayload, ISimpleSendReportInfoPayload, ISimpleSendReportPayload,
  ISimpleSendSinglePreviewPayload
} from "@app/api/models/components/simple-send/simple-send-payload.model";
import {
  ISimpleSendReport,
  ISimpleSendReportInfo, ISimpleSendReportInfoItem,
  ISimpleSendSinglePreview
} from "@app/api/models/components/simple-send/simple-send.model";
import {PagedDataModel} from "@shared/models/paged-data.model";


@Injectable()
export class SimpleSendService {

  constructor(
      private _ApiService: ApiService,
  ) {
  }

  singlePreview(payload: ISimpleSendSinglePreviewPayload): Observable<BaseResponse<ISimpleSendSinglePreview>> {
    const path = 'simple-send/single/preview';
    return this._ApiService.post({path, body: payload.body})
  }

  contactsPreview(payload: ISimpleSendContactsPreviewPayload): Observable<BaseResponse<ISimpleSendSinglePreview>> {
    const path = 'simple-send/contacts/preview';
    return this._ApiService.post({path, body: payload.body})
  }

  manualPreviewFirst(payload: ISimpleSendManualFirstPreviewPayload): Observable<BaseResponse<ISimpleSendSinglePreview>>{
    const path = 'simple-send/manual/preview/first';
    return this._ApiService.post({path, body: payload.body})
  }

  manualPreviewSecond(payload: ISimpleSendManualSecondPreviewPayload): Observable<BaseResponse<ISimpleSendSinglePreview>>{
    const path = 'simple-send/manual/preview/second';
    return this._ApiService.post({path, body: payload.body})
  }

  filePreviewFirst(payload: ISimpleSendFileFirstPreviewPayload): Observable<BaseResponse<ISimpleSendSinglePreview>>{
    const path = 'simple-send/file/preview/first';
    return this._ApiService.postFile({path, body: payload.body})
  }


  filePreviewSecond(payload: ISimpleSendFileSecondPreviewPayload): Observable<BaseResponse<ISimpleSendSinglePreview>>{
    const path = 'simple-send/file/preview/second';
    return this._ApiService.post({path, body: payload.body})
  }


  simpleSendConfirm(payload: ISimpleSendPayload): Observable<BaseResponse<boolean>>{
    const path = 'simple-send';
    return this._ApiService.patch({path, body: payload.body})
  }

  simpleSendReport(payload: ISimpleSendReportPayload): Observable<BaseResponse<PagedDataModel<ISimpleSendReport>>>{
    const path = 'simple-send';
    return this._ApiService.get({path, queryParams: payload.params})
  }

  simpleSendReportInfo(payload: ISimpleSendReportInfoPayload): Observable<BaseResponse<ISimpleSendReportInfo>>{
    const path = `simple-send/${payload.simpleId}`;
    return this._ApiService.get({path, queryParams: payload.params})
  }

  simpleSendReportInfoItems(payload: ISimpleSendReportInfoPayload): Observable<BaseResponse<PagedDataModel<ISimpleSendReportInfoItem>>>{
    const path = `simple-send/${payload.simpleId}/detail`;
    return this._ApiService.get({path, queryParams: payload.params})
  }
}
