import {Router} from "@angular/router";
import {Injectable} from '@angular/core';
import {Observable, throwError} from "rxjs";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest} from "@angular/common/http";
import {catchError, finalize, map, switchMap} from "rxjs/operators";

import {AppNotifications} from "@shared/enums/app-notifications.enum";
import {ToastifyService} from "@shared/services/toastify.service";
import {TokenService} from "@app/api/services/base/token.service";
import {AuthService} from "@app/api/services/components/auth.service";
import {ErrorCodeService} from "@app/api/store/error-code.service";
import {HttpResponseHandlerService} from "@app/api/services/base/http-response-handler.service";
import {BaseResponse} from "@app/api/models/base/api.model";
import {LoadingService} from "@shared/services/loading.service";
import {PlanHandlerService} from "@shared/services/plan-handler.service";


@Injectable({
  providedIn: 'root'
})
export class HttpErrorHandlerService {
  constructor(
    private _ToastifyService: ToastifyService,
    private _TokenService: TokenService,
    private _Router: Router,
    private _LoadingService: LoadingService,
    private _AuthService: AuthService,
    private _ErrorCodeService: ErrorCodeService,
    private _HttpResponseHandlerService: HttpResponseHandlerService,
    private _PlanHandlerService: PlanHandlerService,
  ) {
  }


  handle(err: HttpErrorResponse | any, request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    let refreshToken = this._TokenService.getRefreshToken();

    switch (err.status) {
      case 200:
        let resp: BaseResponse<null> = err.error;
        if(resp.errorCode){
          this._ToastifyService.error(this._ErrorCodeService.translate(resp.errorCode));
          if(resp.errorCode > 57 &&  resp.errorCode < 72){
            this._PlanHandlerService.planInterceptorHandler(resp.errorCode);
          }
        }
        break;
      case 401:
        if (err.url?.slice(-5) !== 'token' && refreshToken) {
          return this.checkRefreshToken(request, next);
        }
        this._TokenService.logOut();
        this._ToastifyService.error(AppNotifications.LoginAgain);
        return throwError(() => err);
      case 404:
        this._ToastifyService.error(AppNotifications.UnknownError);
        this._Router.navigate(['/404']).then();
        break;
      case 403:
        this._ToastifyService.error(AppNotifications.NoAccess);
        break;
      default:
        this._ToastifyService.error(AppNotifications.UnknownError);
        break;

    }

    return throwError(() => err);
  }


  checkRefreshToken(req: any, next: HttpHandler) {
    const refreshToken = this._TokenService.getRefreshToken();

    return this._AuthService.token(JSON.stringify(refreshToken))
      .pipe(
        finalize(() => {
          this._LoadingService.setButtonLoading(false);
        }),
        switchMap((res) => {
          let accessToken = res.result;

          this._TokenService.setToken(accessToken, refreshToken);
          return next.handle(req.clone({headers: req.headers.set('Authorization', 'Bearer ' + accessToken)})).pipe(
            map((event: HttpEvent<any>) => this._HttpResponseHandlerService.handleHttpResponse(event, req)),
            catchError((err: HttpErrorResponse) => {
              return this.handle(err, req, next);
            }),
          )
        })
      );

  }

}
