import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {ResponseModel} from '@shared/models/response.model';
import {ApiService} from '@shared/services/api.service';
import {ConfigService} from '@shared/services/config.service';
import {ContactPreviewComponent} from '../components/contact-preview/contact-preview.component';
import {ManualAndUploadPreviewPayload} from '../models/manual-and-upload-preview-payload';
import {OperatorPreviewComponent} from '../components/operator-preview/operator-preview.component';
import {ListSimplePreviewPayload} from '../models/list-simple-preview-payload';
import {GetAbtestCampaign} from '../models/get-abtest-campaign';
import {HttpParams} from '@angular/common/http';
import {SimpleSinglePreviewInterface} from '@app/modules/send-message/models/simple-single-preview.interface';
import {SimpleSinglePreviewPayload} from '@app/modules/send-message/models/simple-single-preview-payload';
import {SmartMultiplePreviewInterface} from '@app/modules/send-message/models/smart-multiple-preview.interface';
import {
  SmartMultiplePreviewPayloadInterface
} from '@app/modules/send-message/models/smart-multiple-preview-payload.interface';
import {SmartParametricPreviewInterface} from '@app/modules/send-message/models/smart-parametric-preview.interface';
import {
  SmartParametricPreviewPayloadInterface
} from '@app/modules/send-message/models/smart-parametric-preview-payload.interface';
import {SingleEventCampaignInterface} from '@app/modules/send-message/models/single-event-campaign.interface';
import {SingleScheduledCampaignInterface} from '@app/modules/send-message/models/single-scheduled-campaign.interface';
import {SingleSimpleCampaignInterface} from '@app/modules/send-message/models/single-simple-campaign.interface';
import {
  CampaignSimplePreviewPayloadInterface
} from '@app/modules/send-message/models/campaign-simple-preview-payload.interface';
import {CampaignSimplePreviewInterface} from '@app/modules/send-message/models/campaign-simple-preview.interface';
import {
  CampaignEventPreviewPayloadInterface
} from '@app/modules/send-message/models/campaign-event-preview-payload.interface';
import {CampaignEventPreviewInterface} from '@app/modules/send-message/models/campaign-event-preview.interface';
import {
  CampaignSchedulePreviewPayloadInterface
} from '@app/modules/send-message/models/campaign-schedule-preview-payload.interface';
import {CampaignSchedulePreviewInterface} from '@app/modules/send-message/models/campaign-schedule-preview.interface';
import {CampaignAbtestPreview} from '@app/modules/send-message/models/campaign-abtest-preview';
import {CampaignAbtestPreviewPayload} from '@app/modules/send-message/models/campaign-abtest-preview-payload';
import {GetBankNumberQueryParams} from '@app/modules/send-message/models/get-bank-number-query-params';
import {GetBankNumberList} from '@app/modules/send-message/models/get-bank-number-list';
import {BankNumberPreviewPayload} from '@app/modules/send-message/models/bank-number-preview-payload';
import {BankNumberPreview} from '@app/modules/send-message/models/bank-number-preview';
import {FilterItemModel} from '@shared/components/table/models/filter-item.model';
import {SendTowerState} from '@app/modules/send-message/enums/send-tower-state';
import {PagedDataModel} from "@shared/models/paged-data.model";
import {SendTowerRequest} from "@app/modules/send-message/models/send-tower-request";
import {SendTowerConfiguration} from "@app/modules/send-message/models/send-tower-configuration";
import {SendTowerPayload} from "@app/modules/send-message/models/send-tower-payload";
import {SendTowerRequestDetails} from "@app/modules/send-message/models/send-tower-request-details";
import {SendTowerOperator} from "@app/modules/send-message/enums/send-tower-operator";


@Injectable({
  providedIn: 'root'
})
export class SendMessageService {

  private TowerStatusList: FilterItemModel[] = [
    {name: 'در حال بررسی', value: SendTowerState.Pending},
    {name: 'در انتظار پرداخت', value: SendTowerState.Accepted},
    {name: 'عدم تایید', value: SendTowerState.Rejected},
    {name: 'پرداخت شده', value: SendTowerState.Paid},
    {name: 'آماده ارسال', value: SendTowerState.Ready},
    {name: 'در حال بررسی فیش', value: SendTowerState.ManualPaymentReview},
    {name: 'ارسال شده', value: SendTowerState.Finished},
    {name: 'منقضی شده', value: SendTowerState.Expired},
  ];

  private TowerOperatorList: FilterItemModel[] = [
    {name: 'ایرانسل', value: SendTowerOperator.Irancell},
    {name: 'همراه اول', value: SendTowerOperator.Hamraheaval},
  ];

  get towerStatusList() {
    return this.TowerStatusList;
  }

  get towerOperatorList() {
    return this.TowerOperatorList;
  }

  constructor(private apiService: ApiService,
              private ngbModal: NgbModal,
              private configService: ConfigService) {
  }

  showOperatorsPreview(data: any, needConfirm?: boolean) {
    const modalRef = this.ngbModal.open(OperatorPreviewComponent, this.configService.modalOptions());
    modalRef.componentInstance.data = data;
  }

  showContactsPreview(data: SimpleSinglePreviewInterface, needConfirm?: boolean) {
    const modalRef = this.ngbModal.open(ContactPreviewComponent, this.configService.modalOptions());
    modalRef.componentInstance.data = data;
    modalRef.componentInstance.needConfirm = needConfirm;
    if (needConfirm) {
      return modalRef.result;
    } else {
      return null;
    }
  }

  previewSimpleSingle(payload: SimpleSinglePreviewPayload): Observable<ResponseModel<SimpleSinglePreviewInterface>> {
    const url = 'simple-send/single/preview';
    return this.apiService.post(url, payload);
  }


  // Backend result are the same as previous one.
  previewListSimple(payload: ListSimplePreviewPayload): Observable<ResponseModel<SimpleSinglePreviewInterface>> {
    const url = 'simple-send/contacts/preview';
    return this.apiService.post(url, payload);
  }

  previewManualContactFirst(payload: number[]): Observable<ResponseModel<SimpleSinglePreviewInterface>> {
    const url = 'simple-send/manual/preview/first';
    return this.apiService.post(url, payload);
  }


  previewManualContactSecond(payload: ManualAndUploadPreviewPayload): Observable<ResponseModel<SimpleSinglePreviewInterface>> {
    const url = 'simple-send/manual/preview/second';
    return this.apiService.post(url, payload);
  }


  previewUploadContactFirst(formData: any): Observable<ResponseModel<SimpleSinglePreviewInterface> | any> {
    const url = 'simple-send/file/preview/first';
    return this.apiService.postFile(url, formData);
  }

  previewUploadContactSecond(payload: ManualAndUploadPreviewPayload): Observable<ResponseModel<SimpleSinglePreviewInterface>> {
    const url = 'simple-send/file/preview/second';
    return this.apiService.post(url, payload);
  }

  previewUploadFileSmartMultipleFirst(formData: FormData): Observable<ResponseModel<SmartMultiplePreviewInterface> | any> {
    const url = 'smart-send/multiple-text/preview/first';
    return this.apiService.postFile(url, formData);
  }

  previewUploadFileSmartMultipleSecond(payload: SmartMultiplePreviewPayloadInterface): Observable<ResponseModel<SmartMultiplePreviewInterface>> {
    const url = 'smart-send/multiple-text/preview/second';
    return this.apiService.post(url, payload);
  }


  previewUploadFileSmartParametricFirst(formData: FormData): Observable<ResponseModel<SmartParametricPreviewInterface> | any> {
    const url = 'smart-send/parametric/preview/first';
    return this.apiService.postFile(url, formData);
  }

  previewUploadFileSmartParametricSecond(payload: SmartParametricPreviewPayloadInterface): Observable<ResponseModel<SmartParametricPreviewInterface>> {
    const url = 'smart-send/parametric/preview/second';
    return this.apiService.post(url, payload);
  }


  confirmSimpleSend(key: string): Observable<ResponseModel<boolean>> {
    const url = 'simple-send';
    return this.apiService.patch(url, key);
  }


  confirmSmartSend(key: string): Observable<ResponseModel<boolean>> {
    const url = 'smart-send';
    return this.apiService.patch(url, key);
  }


  getSingleEventCampaign(campaignId: number): Observable<ResponseModel<SingleEventCampaignInterface>> {
    const url = `event-campaign/${campaignId}`;
    return this.apiService.get(url);
  }


  getSingleScheduledCampaign(campaignId: number): Observable<ResponseModel<SingleScheduledCampaignInterface>> {
    const url = `scheduled-campaign/${campaignId}`;
    return this.apiService.get(url);
  }

  getSingleSimpleCampaign(campaignId: number): Observable<ResponseModel<SingleSimpleCampaignInterface>> {
    const url = `simple-campaign/${campaignId}`;
    return this.apiService.get(url);
  }

  previewSimpleCampaignFirst(payload: CampaignSimplePreviewPayloadInterface): Observable<ResponseModel<CampaignSimplePreviewInterface>> {
    const url = 'simple-campaign';
    return this.apiService.post(url, payload);
  }

  confirmSimpleCampaign(payload: string, campaignId?: number): Observable<ResponseModel<boolean>> {
    if (campaignId) {
      const url = `simple-campaign/${campaignId}`;
      return this.apiService.put(url, payload);
    } else {
      const url = 'simple-campaign';
      return this.apiService.patch(url, payload);
    }

  }


  previewEventCampaignFirst(payload: CampaignEventPreviewPayloadInterface): Observable<ResponseModel<CampaignEventPreviewInterface>> {
    const url = 'event-campaign';
    return this.apiService.post(url, payload);
  }

  confirmEventCampaign(payload: string | { isActive: boolean, key: string }, campaignId?: number): Observable<ResponseModel<boolean>> {
    if (campaignId) {
      const url = `event-campaign/${campaignId}`;
      return this.apiService.put(url, payload);
    } else {
      const url = 'event-campaign';
      return this.apiService.patch(url, payload);
    }
  }

  previewScheduleCampaignFirst(payload: CampaignSchedulePreviewPayloadInterface): Observable<ResponseModel<CampaignSchedulePreviewInterface>> {
    const url = 'scheduled-campaign';
    return this.apiService.post(url, payload);
  }

  confirmScheduleCampaign(payload: string | { isActive: boolean, key: string }, campaignId?: number): Observable<ResponseModel<boolean>> {
    if (campaignId) {
      const url = `scheduled-campaign/${campaignId}`;
      return this.apiService.put(url, payload);
    } else {
      const url = 'scheduled-campaign';
      return this.apiService.patch(url, payload);
    }

  }

  checkBlackListAbTestCampaign(userLineId: number, groupId: number[]): Observable<ResponseModel<number>> {
    const url = `ab-test-campaign/filter-mobiles`;
    let queryParam = new HttpParams();
    if(userLineId){
      queryParam = queryParam.append('userLineId', userLineId);
    }
    groupId.forEach(el => {
      queryParam = queryParam.append('groupId', el);
    });
    return this.apiService.getArrayQueryParam(url, {params: queryParam, observe: 'response'}, true);
  }

  previewAbTestCampaign(payload: CampaignAbtestPreviewPayload): Observable<ResponseModel<CampaignAbtestPreview>> {
    const url = 'ab-test-campaign';
    return this.apiService.post(url, payload);
  }

  confirmAbTestCampaign(payload: string, campaignId?: number): Observable<ResponseModel<boolean>> {
    if (campaignId) {
      const url = `ab-test-campaign/${campaignId}`;
      return this.apiService.put(url, payload);
    } else {
      const url = 'ab-test-campaign';
      return this.apiService.patch(url, payload);
    }
  }

  getAbtestCampaign(campaignId: number): Observable<ResponseModel<GetAbtestCampaign>> {
    const url = `ab-test-campaign/${campaignId}`;
    return this.apiService.get(url);
  }


  getBankNumber(queryParams: GetBankNumberQueryParams): Observable<ResponseModel<GetBankNumberList[]>> {
    const url = `mobile-bank/categories`;
    return this.apiService.get(url, queryParams);
  }

  previewBankNumberFirst(payload: BankNumberPreviewPayload): Observable<ResponseModel<BankNumberPreview>> {
    const url = 'mobile-bank';
    return this.apiService.post(url, payload);
  }

  confirmBankNumber(payload: string): Observable<ResponseModel<boolean>> {
    const url = 'mobile-bank';
    return this.apiService.patch(url, payload);
  }

  modifyBankNumberTextMessage(id: number, text: string): Observable<ResponseModel<boolean>> {
    const url = `mobile-bank/${id}`;
    return this.apiService.patch(url, text);
  }

  getSendTowerRequest(queryParams?: any, tableID?: number): Observable<ResponseModel<PagedDataModel<SendTowerRequest>>>{
    const url = 'send-via-tower';
    return this.apiService.get(url, queryParams, tableID);
  }

  getOneSendTowerRequest(requestId :number): Observable<ResponseModel<SendTowerRequestDetails>>{
    const url = `send-via-tower/${requestId}`;
    return this.apiService.get(url);
  }

  modifySendTowerRequest(requestId :number, payload: SendTowerPayload): Observable<ResponseModel<boolean>>{
    const url = `send-via-tower/${requestId}`;
    return this.apiService.put(url, payload);
  }

  deleteSendTowerRequest(requestId :number): Observable<ResponseModel<boolean>>{
    const url = `send-via-tower/${requestId}`;
    return this.apiService.delete(url);
  }


  sendTowerRequest(payload: FormData): Observable<any>{
    const url = 'send-via-tower';
    return this.apiService.postFile(url, payload);
  }

  editSendTowerRequest(id:number ,payload: FormData): Observable<any>{
    const url = `send-via-tower/${id}`;
    return this.apiService.putFile(url, payload);
  }

  sendTowerConfiguration(): Observable<ResponseModel<SendTowerConfiguration>> {
    const url = 'send-via-tower/configurations';
    return this.apiService.get(url);
  }

  sendTowerSingle(id: number): Observable<ResponseModel<SendTowerRequest>> {
    const url = `send-via-tower/${id}`;
    return this.apiService.get(url);
  }
}
