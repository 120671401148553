import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

import {ApiService} from "@app/api/services/base/api.service";
import {BaseResponse} from "@app/api/models/base/api.model";
import {
  IPanelBootstrapper,
  IPanelCity,
  IPanelPrefix,
  IPanelProvince
} from "@app/api/models/components/panel/panel.model";
import {IPanelCancellationKeywordPayload} from "@app/api/models/components/panel/panel-payload.model";


@Injectable()
export class PanelService {

  constructor(
    private _ApiService: ApiService,
  ) {
  }

  prefix(): Observable<BaseResponse<IPanelPrefix>> {
    const path = 'base-data/prefix';
    return this._ApiService.get({path})
  }

  bootstrapper(): Observable<BaseResponse<IPanelBootstrapper>> {
    const path = 'bootstrapper';
    return this._ApiService.get({path})
  }

  province(): Observable<BaseResponse<IPanelProvince[]>> {
    const path = 'setting/province';
    return this._ApiService.get({path});
  }

  city(provinceId: number): Observable<BaseResponse<IPanelCity[]>> {
    const path = `setting/province/${provinceId}/city`;
    return this._ApiService.get({path});
  }

  publicLineActivation(): Observable<BaseResponse<boolean>> {
    const path = `public-lines/activation`;
    return this._ApiService.get({path});
  }

  cancellationKeyword(payload: IPanelCancellationKeywordPayload): Observable<BaseResponse<string>> {
    const path = `send/cancellation-keyword`;
    return this._ApiService.get({path, queryParams: payload.params});
  }
}
