import { Injectable } from '@angular/core';
import {ApiService} from "@app/api/services/base/api.service";
import {Observable} from "rxjs";
import {BaseResponse} from "@app/api/models/base/api.model";
import {IDashboard} from "@app/api/models/components/dashboard/dashboard.model";
import {IDashboardUserConfirmationPayload} from "@app/api/models/components/dashboard/dashboard-payload.model";


@Injectable()
export class DashboardService {

  constructor(
      private _ApiService: ApiService,
  ) {
  }

  dashboard(): Observable<BaseResponse<IDashboard>> {
    const path = 'dashboard';
    return this._ApiService.get({path})
  }

  userConfirmation(payload: IDashboardUserConfirmationPayload): Observable<BaseResponse<any>> {
    const path = 'user/confirmation';
    return this._ApiService.patch({path, body: payload.body})
  }
}
