import { Injectable } from '@angular/core';
import {Observable} from "rxjs";

import {ApiService} from "@app/api/services/base/api.service";
import {BaseResponse} from "@app/api/models/base/api.model";
import {IUserEvent} from "@app/api/models/components/user-event/user-event.model";
import {
  IUserEventCreatePayload, IUserEventModifyPayload,
  IUserEventPayload
} from "@app/api/models/components/user-event/user-event-payload.model";


@Injectable()
export class UserEventService {
  constructor(
    private _ApiService: ApiService,
  ) {
  }

  userEvent(payload: IUserEventPayload): Observable<BaseResponse<IUserEvent[]>> {
    const path = 'user-event';
    return this._ApiService.get({path, queryParams: payload.params})
  }

  createUserEvent(payload: IUserEventCreatePayload): Observable<BaseResponse<any>> {
    const path = 'user-event';
    return this._ApiService.post({path, body: payload.body})
  }

  updateUserEvent(payload: IUserEventModifyPayload):Observable<BaseResponse<any>>{
    const path = `user-event/${payload.EventID}`;
    return this._ApiService.put({path, body: payload.body})
  }

  deleteUserEvent(EventID: number){
    const path = `user-event/${EventID}`;
    return this._ApiService.delete({path})
  }
}
