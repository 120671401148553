import {Injectable} from '@angular/core'
import {Observable, of} from "rxjs";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {StoreService} from "@shared/services/store.service";
import {BootstrapAppService} from "@shared/services/bootstrap-app.service";
import {TokenService} from "@app/api/services/base/token.service";
import {tap} from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class SystemGuard implements CanActivate {

  constructor(
    private _TokenService: TokenService,
    private bootstrapAppService: BootstrapAppService
  ) {
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this._TokenService.getAccessToken()) {
      return this.bootstrapAppService.storeBootstrapperData()
    } else {
        window.location.href = 'https://sms.ir/panel/';
      return of(false);
    }
  }


}
