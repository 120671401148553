import {map, switchMap, take} from "rxjs/operators";
import {UntilDestroy} from "@ngneat/until-destroy";
import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";

import {StoreService} from "@shared/services/store.service";
import {IPanelPrefix} from "@app/api/models/components/panel/panel.model";
import {PanelService} from "@app/api/services/components/panel.service";
import {BaseResponse} from "@app/api/models/base/api.model";


@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class PrefixNumberService {

    constructor(
        private storeService: StoreService,
        private _PanelService: PanelService,
    ) {
    }


    storePrefixData(): any {
        return this.fetchOrGetDataPrefix().pipe(
            map((response: IPanelPrefix): any => {
                return this.saveData(response);
            }),
        )

    }


    fetchOrGetDataPrefix(): Observable<IPanelPrefix> {
        return this.storeService.prefix$.pipe(
            take(1),
            switchMap((localValue) => {
                    if (!localValue) {
                        return this._PanelService.prefix()
                            .pipe(
                                map((response: BaseResponse<IPanelPrefix>) => {
                                    return response.result
                                })
                            );


                    } else {
                        return of(localValue);
                    }
                }
            ),
            map((data: IPanelPrefix): IPanelPrefix => {
                    return data
                }
            )
        )
    }


    saveData(data: IPanelPrefix) {
        if (data) {
            this.storeService.setPrefix(data);
            return true;
        } else {
            return false;
        }
    }
}
