import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ApiService} from "@app/api/services/base/api.service";
import {BaseResponse} from "@app/api/models/base/api.model";
import {PagedDataModel} from "@shared/models/paged-data.model";
import {IApiKey, IApiLog} from "@app/api/models/components/api-key/api-key.model";
import {
  IApiKeyCreatePayload, IApiKeyModifyPayload,
  IApiKeyValidIpCreatePayload, IApiKeyValidIpDeletePayload,
  IApiLogPayload
} from "@app/api/models/components/api-key/api-key-payload.model";


@Injectable()
export class ApiKeyService {
  constructor(
    private _ApiService: ApiService,
  ) {
  }

  apiLog(payload: IApiLogPayload): Observable<BaseResponse<PagedDataModel<IApiLog>>> {
    const path = 'api/log';
    return this._ApiService.get({path, queryParams: payload.params})
  }

  apiKeys(): Observable<BaseResponse<IApiKey[]>> {
    const path = 'apiKey';
    return this._ApiService.get({path})
  }

  createApiKey(payload: IApiKeyCreatePayload): Observable<BaseResponse<boolean>> {
    const path = 'apiKey';
    return this._ApiService.post({path, body: payload.body, queryParams: payload.params})
  }

  apiKeyInfo(apiId: number): Observable<BaseResponse<IApiKey>> {
    const path = `apiKey/${apiId}`;
    return this._ApiService.get({path})
  }

  createValidIp(payload: IApiKeyValidIpCreatePayload): Observable<BaseResponse<boolean>>{
    const path = `apiKey/${payload.apiId}/validIp`;
    return this._ApiService.post({path, body: payload.body})
  }

  deleteValidIp(payload: IApiKeyValidIpDeletePayload): Observable<BaseResponse<boolean>>{
    const path = `apiKey/${payload.apiId}/validIp/${payload.ip}`;
    return this._ApiService.delete({path})
  }

  updateApiKey(payload: IApiKeyModifyPayload): Observable<BaseResponse<any>>{
    const path = `apiKey/${payload.apiId}`;
    return this._ApiService.put({path, body: payload.body})
  }

  deleteApiKey(apiId: number): Observable<BaseResponse<boolean>>{
    const path = `apiKey/${apiId}`;
    return this._ApiService.delete({path})
  }
}
