import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

import {ApiService} from "@app/api/services/base/api.service";
import {BaseResponse} from "@app/api/models/base/api.model";
import {
  IModuleBlackListKeyword, IModuleBlackListMobile,
  IModuleCodeReader,
  IModuleCodeReaderRange, IModuleCodeReaderRangeImportRequest,
  IModuleCodeReaderRangeInfo,
  IModuleCodeReaderRangeParameter,
  IModuleCodeReaderRangeReceive,
  IModuleSecretary,
  IModuleSecretaryBehavior,
  IModuleSecretaryBehaviorInfo,
  IModuleSecretarySentMessage,
  IModuleTransmission,
  IModuleTransmissionBehavior,
  IModuleTransmissionBehaviorInfo,
  IModuleTransmissionCalledAPI,
  IModuleTransmissionSentEmail,
  IModuleTransmissionSentMessage
} from "@app/api/models/components/modules/modules.model";
import {
  IModuleBlackListCheckPayload,
  IModuleBlackListKeywordCreatePayload, IModuleBlackListKeywordDefaultPayload,
  IModuleBlackListMobileCreatePayload,
  IModuleBlackListMobilePayload,
  IModuleCodeReaderCreatePayload,
  IModuleCodeReaderModifyPayload,
  IModuleCodeReaderRangeCreatePayload,
  IModuleCodeReaderRangeFileCreatePayload, IModuleCodeReaderRangeImportRequestPayload,
  IModuleCodeReaderRangeInfoPayload,
  IModuleCodeReaderRangeModifyPayload, IModuleCodeReaderRangeParameterCreatePayload,
  IModuleCodeReaderRangeParameterDeletePayload, IModuleCodeReaderRangeParameterFilePayload,
  IModuleCodeReaderRangeParametersPayload,
  IModuleCodeReaderRangeReceivePayload,
  IModuleSecretaryBehaviorCreatePayload,
  IModuleSecretaryBehaviorInfoPayload,
  IModuleSecretaryBehaviorModifyPayload,
  IModuleSecretaryBehaviorPayload,
  IModuleSecretaryCreatePayload,
  IModuleSecretaryModifyPayload,
  IModuleSecretarySentMessagePayload,
  IModuleTransmissionBehaviorCreatePayload,
  IModuleTransmissionBehaviorInfoPayload,
  IModuleTransmissionBehaviorModifyPayload,
  IModuleTransmissionBehaviorPayload,
  IModuleTransmissionCalledAPIPayload,
  IModuleTransmissionCreatePayload,
  IModuleTransmissionModifyPayload,
  IModuleTransmissionSentEmailPayload,
  IModuleTransmissionSentMessagePayload
} from "@app/api/models/components/modules/modules-payload.model";
import {PagedDataModel} from "@shared/models/paged-data.model";


@Injectable()
export class ModulesService {
  constructor(
    private _ApiService: ApiService,
  ) {
  }

  transmission(): Observable<BaseResponse<IModuleTransmission[]>> {
    const path = `modules/transmission`;
    return this._ApiService.get({path});
  }

  transmissionSentEmails(payload: IModuleTransmissionSentEmailPayload): Observable<BaseResponse<PagedDataModel<IModuleTransmissionSentEmail>>> {
    const path = `modules/transmission/${payload.id}/sent-emails`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  transmissionSentMessages(payload: IModuleTransmissionSentMessagePayload): Observable<BaseResponse<PagedDataModel<IModuleTransmissionSentMessage>>> {
    const path = `modules/transmission/${payload.id}/sent-messages`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  transmissionCalledAPI(payload: IModuleTransmissionCalledAPIPayload): Observable<BaseResponse<PagedDataModel<IModuleTransmissionCalledAPI>>> {
    const path = `modules/transmission/${payload.id}/called-apis`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  retryTransmissionCalledAPI(id: number): Observable<BaseResponse<any>> {
    const path = `modules/transmission/${id}/called-apis/failed`;
    return this._ApiService.patch({path, body: null});
  }

  createTransmission(payload: IModuleTransmissionCreatePayload): Observable<BaseResponse<boolean>> {
    const path = `modules/transmission`;
    return this._ApiService.post({path, body: payload.body});
  }

  updateTransmission(payload: IModuleTransmissionModifyPayload): Observable<BaseResponse<boolean>> {
    const path = `modules/transmission/${payload.id}`;
    return this._ApiService.put({path, body: payload.body});
  }

  deleteTransmission(id: number): Observable<BaseResponse<boolean>> {
    const path = `modules/transmission/${id}`;
    return this._ApiService.delete({path});
  }

  transmissionBehavior(payload: IModuleTransmissionBehaviorPayload): Observable<BaseResponse<IModuleTransmissionBehavior>> {
    const path = `modules/transmission/${payload.id}/behavior/${payload.type}`;
    return this._ApiService.get({path});
  }

  transmissionBehaviorInfo(payload: IModuleTransmissionBehaviorInfoPayload): Observable<BaseResponse<IModuleTransmissionBehaviorInfo>> {
    const path = `modules/transmission/${payload.id}/behavior/${payload.type}/${payload.behaviorId}`;
    return this._ApiService.get({path});
  }

  deleteTransmissionBehavior(payload: IModuleTransmissionBehaviorInfoPayload): Observable<BaseResponse<boolean>> {
    const path = `modules/transmission/${payload.id}/behavior/${payload.type}/${payload.behaviorId}`;
    return this._ApiService.delete({path});
  }

  createTransmissionBehavior(payload: IModuleTransmissionBehaviorCreatePayload): Observable<BaseResponse<boolean>> {
    const path = `modules/transmission/${payload.id}/behavior/${payload.type}`;
    return this._ApiService.post({path, body: payload.body});
  }

  updateTransmissionBehavior(payload: IModuleTransmissionBehaviorModifyPayload): Observable<BaseResponse<boolean>> {
    const path = `modules/transmission/${payload.id}/behavior/${payload.type}/${payload.behaviorId}`;
    return this._ApiService.put({path, body: payload.body});
  }

  /*
  *
  *
  * SECRETARY SECTION START
  *
  *
  */

  secretary(): Observable<BaseResponse<IModuleSecretary[]>> {
    const path = `modules/secretary`;
    return this._ApiService.get({path});
  }

  secretarySentMessages(payload: IModuleSecretarySentMessagePayload): Observable<BaseResponse<PagedDataModel<IModuleSecretarySentMessage>>> {
    const path = `modules/secretary/${payload.id}/sent-messages`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  createSecretary(payload: IModuleSecretaryCreatePayload): Observable<BaseResponse<boolean>> {
    const path = `modules/secretary`;
    return this._ApiService.post({path, body: payload.body});
  }

  updateSecretary(payload: IModuleSecretaryModifyPayload): Observable<BaseResponse<boolean>> {
    const path = `modules/secretary/${payload.id}`;
    return this._ApiService.put({path, body: payload.body});
  }

  deleteSecretary(id: number) {
    const path = `modules/secretary/${id}`;
    return this._ApiService.delete({path});
  }

  secretaryBehavior(payload: IModuleSecretaryBehaviorPayload): Observable<BaseResponse<IModuleSecretaryBehavior>> {
    const path = `modules/secretary/${payload.id}/behavior`;
    return this._ApiService.get({path});
  }

  secretaryBehaviorInfo(payload: IModuleSecretaryBehaviorInfoPayload): Observable<BaseResponse<IModuleSecretaryBehaviorInfo>> {
    const path = `modules/secretary/${payload.id}/behavior/${payload.behaviorId}`;
    return this._ApiService.get({path});
  }

  deleteSecretaryBehavior(payload: IModuleSecretaryBehaviorInfoPayload): Observable<BaseResponse<any>> {
    const path = `modules/secretary/${payload.id}/behavior/${payload.behaviorId}`;
    return this._ApiService.delete({path});
  }

  createSecretaryBehavior(payload: IModuleSecretaryBehaviorCreatePayload): Observable<BaseResponse<any>> {
    const path = `modules/secretary/${payload.id}/behavior`;
    return this._ApiService.post({path, body: payload.body});
  }

  updateSecretaryBehavior(payload: IModuleSecretaryBehaviorModifyPayload): Observable<BaseResponse<boolean>> {
    const path = `modules/secretary/${payload.id}/behavior/${payload.behaviorId}`;
    return this._ApiService.put({path, body: payload.body});
  }

  /*
   *
   *
   * CODE READER SECTION START
   *
   *
   */

  codeReader(): Observable<BaseResponse<IModuleCodeReader[]>> {
    const path = `modules/code-reader`;
    return this._ApiService.get({path});
  }

  createCodeReader(payload: IModuleCodeReaderCreatePayload): Observable<BaseResponse<number>> {
    const path = `modules/code-reader`;
    return this._ApiService.post({path, body: payload.body});
  }

  updateCodeReader(payload: IModuleCodeReaderModifyPayload): Observable<BaseResponse<number>> {
    const path = `modules/code-reader/${payload.id}`;
    return this._ApiService.put({path, body: payload.body});
  }

  deleteCodeReader(id: number): Observable<BaseResponse<boolean>> {
    const path = `modules/code-reader/${id}`;
    return this._ApiService.delete({path});
  }

  codeReaderRange(id: number): Observable<BaseResponse<IModuleCodeReaderRange>>{
    const path = `modules/code-reader/${id}/range`;
    return this._ApiService.get({path});
  }

  createCodeReaderRange(payload: IModuleCodeReaderRangeCreatePayload): Observable<BaseResponse<boolean>>{
    const path = `modules/code-reader/${payload.id}/range`;
    return this._ApiService.post({path, body: payload.body});
  }

  createCodeReaderRangeFile(payload: IModuleCodeReaderRangeFileCreatePayload): Observable<BaseResponse<any>>{
    const path = `modules/code-reader/${payload.id}/range/file`;
    return this._ApiService.postFile({path, body: payload.body});
  }

  codeReaderRangeInfo(payload:IModuleCodeReaderRangeInfoPayload): Observable<BaseResponse<IModuleCodeReaderRangeInfo>>{
    const path = `modules/code-reader/${payload.id}/range/${payload.rangeId}`;
    return this._ApiService.get({path});
  }

  updateCodeReaderRange(payload: IModuleCodeReaderRangeModifyPayload): Observable<BaseResponse<boolean>>{
    const path = `modules/code-reader/${payload.id}/range/${payload.rangeId}`;
    return this._ApiService.put({path, body: payload.body});
  }

  deleteCodeReaderRange(payload:IModuleCodeReaderRangeInfoPayload): Observable<BaseResponse<boolean>>{
    const path = `modules/code-reader/${payload.id}/range/${payload.rangeId}`;
    return this._ApiService.delete({path});
  }

  codeReaderRangeReceives(payload: IModuleCodeReaderRangeReceivePayload): Observable<BaseResponse<IModuleCodeReaderRangeReceive>>{
    const path = `modules/code-reader/${payload.id}/range/${payload.rangeId}/receives`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  codeReaderRangeParameters(payload: IModuleCodeReaderRangeParametersPayload): Observable<BaseResponse<IModuleCodeReaderRangeParameter>>{
    const path = `modules/code-reader/${payload.id}/range/${payload.rangeId}/parameters`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  deleteCodeReaderRangeParameter(payload: IModuleCodeReaderRangeParameterDeletePayload): Observable<BaseResponse<boolean>>{
    const path = `modules/code-reader/${payload.id}/range/${payload.rangeId}/parameters/${payload.parameter}`;
    return this._ApiService.delete({path});
  }


  codeReaderRangeParameterFile(payload: IModuleCodeReaderRangeParameterFilePayload): Observable<BaseResponse<any>>{
    const path = `modules/code-reader/${payload.id}/range/${payload.rangeId}/parameters/file`;
    return this._ApiService.postFile({path, body: payload.body});
  }

  createCodeReaderRangeParameter(payload: IModuleCodeReaderRangeParameterCreatePayload): Observable<BaseResponse<boolean>>{
    const path = `modules/code-reader/${payload.id}/range/${payload.rangeId}/parameters`;
    return this._ApiService.post({path, body: payload.body});
  }

  createCodeReaderRangeImportRequests(payload: IModuleCodeReaderRangeImportRequestPayload): Observable<BaseResponse<PagedDataModel<IModuleCodeReaderRangeImportRequest>>>{
    const path = `modules/code-reader/${payload.id}/range/${payload.rangeId}/parameters`;
    return this._ApiService.get({path, queryParams: payload.params});
  }


  /*
   *
   *
   * BLACK LIST SECTION START
   *
   *
   */

  blackListMobile(payload: IModuleBlackListMobilePayload): Observable<BaseResponse<PagedDataModel<IModuleBlackListMobile>>>{
    const path = `modules/black-list/mobile`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  createBlackListMobile(payload: IModuleBlackListMobileCreatePayload): Observable<BaseResponse<boolean>>{
    const path = `modules/black-list/mobile`;
    return this._ApiService.post({path, body: payload.body});
  }

  deleteBlackListMobile(id: number): Observable<BaseResponse<boolean>>{
    const path = `modules/black-list/mobile/${id}`;
    return this._ApiService.delete({path});
  }

  blackListKeyword(): Observable<BaseResponse<IModuleBlackListKeyword[]>>{
    const path = `modules/black-list/key-word`;
    return this._ApiService.get({path});
  }

  createBlackListKeyword(payload: IModuleBlackListKeywordCreatePayload): Observable<BaseResponse<boolean>>{
    const path = `modules/black-list/key-word`;
    return this._ApiService.post({path, body: payload.body});
  }

  blackListKeywordDefault(payload: IModuleBlackListKeywordDefaultPayload): Observable<BaseResponse<boolean>>{
    const path = `modules/black-list/key-word/default`;
    return this._ApiService.put({path, body: payload.body});
  }

  deleteBlackListKeyword(id: number): Observable<BaseResponse<boolean>>{
    const path = `modules/black-list/key-word/${id}`;
    return this._ApiService.delete({path});
  }

  blackListCheck(payload: IModuleBlackListCheckPayload): Observable<BaseResponse<any>>{
    const path = `modules/check-black-list`;
    return this._ApiService.post({path, body: payload.body});
  }
}
