import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LoadingService} from "@shared/services/loading.service";
import {DemoHandler} from "@shared/services/demo-handler.service";
import {tap} from "rxjs/operators";

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent implements OnInit, OnDestroy {
  onlyDisabled = false;
  isClicked: boolean = false;
  @Input() setClass!: string
  @Input() disabled?: any;
  @Input() loadingConfig: any = {
    size: 'x-small',
    position: false,
    transparent: true,
    color: '#ffff',
    borderColor: 'transparent'
  };

  constructor(public loadingService: LoadingService, private demoHandler: DemoHandler) {
    this.loadingService.buttonLoading$.pipe(
      tap((x: boolean) => {
        if(!x){
          this.isClicked = false;
        }
      })
    ).subscribe()
  }

  onButtonClick() {
    this.isClicked = true;
  }
  ngOnInit(): void {
    if (this.demoHandler.isDemo()) {
      this.onlyDisabled = true;
    }
  }

  ngOnDestroy() {
    this.isClicked = false;
  }
}
