export enum SystemLineUsageEnum{
  None = 0,
  SystemVerification = 1,
  Notification = 2,
  Advertising = 4,
  Module = 8,
  Cooperation = 16,
  InternalOperations = 32,
  SpecialServiceLine = 64,
  PayamakSefid = 128
}
