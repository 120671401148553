import {EventEmitter, Injectable, Output} from '@angular/core';
import {RealTimeNotification} from "../models/real-time-notification";


@Injectable({
  providedIn: 'root'
})
export class NotificationApiService {

  @Output() newUserNotificationCaught: EventEmitter<RealTimeNotification> = new EventEmitter<RealTimeNotification>();

}
