import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

import {ApiService} from "@app/api/services/base/api.service";
import {BaseResponse} from "@app/api/models/base/api.model";
import {IFAQ, IFAQCategory, IFAQSimilaritySearch, IFAQSpecialSearch} from "@app/api/models/components/faq/faq.model";
import {PagedDataModel} from "@shared/models/paged-data.model";
import {
  IFAQFeedbackPayload,
  IFAQPayload,
  IFAQSimilaritySearchPayload,
  IFAQSpecialSearchPayload
} from "@app/api/models/components/faq/faq-payload.model";


@Injectable()
export class FaqService {

  constructor(
    private _ApiService: ApiService,
  ) {
  }


  getFAQ(payload: IFAQPayload): Observable<BaseResponse<PagedDataModel<IFAQ>>> {
    const path = `faq/${payload.faqCategoryId}`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  getCategory(): Observable<BaseResponse<IFAQCategory[]>> {
    const path = `faq/categories`;
    return this._ApiService.get({path});
  }

  similaritySearch(payload: IFAQSimilaritySearchPayload): Observable<BaseResponse<IFAQSimilaritySearch[]>> {
    const path = 'faq/similarity-search';
    return this._ApiService.get({path, queryParams: payload.params});
  }


  specialSearch(payload: IFAQSpecialSearchPayload): Observable<BaseResponse<IFAQSpecialSearch[]>> {
    const path = 'faq/special';
    return this._ApiService.get({path, queryParams: payload.params});
  }

  feedback(payload: IFAQFeedbackPayload): Observable<BaseResponse<boolean>> {
    const path = 'faq/feedback';
    return this._ApiService.post({path, body: payload.body});
  }
}
