import { Injectable } from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpEventType, HttpRequest, HttpResponse} from "@angular/common/http";
import {LoadingService} from "@shared/services/loading.service";



@Injectable({
  providedIn: 'root'
})
export class HttpResponseHandlerService {

    constructor(
        private _LoadingService: LoadingService,
    ) {
    }

  handleHttpResponse(event: HttpEvent<any>, req: HttpRequest<any>): HttpEvent<any> {
    if (event.type === HttpEventType.Sent && !req.url.endsWith('.svg')) {
      this._LoadingService.setButtonLoading(true);
    }

    if (event instanceof HttpResponse) {
      this._LoadingService.setButtonLoading(false);

      if (typeof event.body === 'object' && event.status === 200 && !event.body.success) {
        throw new HttpErrorResponse({
          headers: event.headers,
          url: event.url ?? undefined,
          status: event.status,
          error: event.body,
          statusText: "OK"
        });
      }
    }
    return event;
  }

}
