<ng-container *ngIf="bootstrap$ | async as bootstrap">
  <div class="text-builder-wrapper">
    <div
      [ngClass]="{'has-template': textBuilderType !== textBuilderTypeEnum.Textarea, 'box-container': hasTransparentBackground}"
      class="text-builder-textarea box-container">
      <div *ngIf="cancellation" class="alert alert-info mb-3">
        <i class="icon-circle-info"></i>
        <p>متن ({{cancellation}}) به ‌طور اتوماتیک به انتهای پیامک شما اضافه می‌گردد.</p>
      </div>
      <form [formGroup]="form" class="content-box">
        <label>{{title}}</label>
        <textarea #message
                  (errorStatusChanged)="errorStatusChanged($event)"
                  (ngModelChange)="onMessageTextChange()"
                  [autoGeneratedError]="false"
                  [maxLength]="hasDoubleChar ? 932 : 2136"
                  [ngClass]="{
                  'input-error': !!form.controls.text.hasError('maxLength'),
                  'textarea-text-fade': isTextChanging,
                  'textarea-text-show': !isTextChanging
                 }"
                  [style.height]="heightSize + 'px'"
                  [style.min-height]="heightSize + 'px'"
                  [needSpace]="true"
                  appFormError
                  dir="auto"
                  formControlName="text"
                  placeholder="متن پیام خود را اینجا بنویس..."
                  rows="15"
        >
             </textarea>

        <div *ngIf="bootstrap.settingsData.isTextEnhancementEnabled">
          <div id="diva1"
               [ngClass]="{
                         'isdesktop': !isMobile,
                         'mobile': isMobile,
                         'open': isMobile && showDropdown
                        }"
               (click)="isMobile && toggleDropdown()"
               (mouseenter)="!isMobile && (showDropdown = true)"
               (mouseleave)="!isMobile && (showDropdown = false)"
               class="xma-edit"
          >
            <svg id="icon1"
                 [class.icon-edit]="!isMobile || !showDropdown"
                 [class.icon2]="isMobile && showDropdown"
                 xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none">
              <path d="M6.43949 3.83408C6.70916 2.72197 8.29084 2.72197 8.56051 3.83408L9.30252 6.89413C9.3988 7.29118 9.70882 7.6012 10.1059 7.69748L13.1659 8.43949C14.278 8.70916 14.278 10.2908 13.1659 10.5605L10.1059 11.3025C9.70882 11.3988 9.3988 11.7088 9.30252 12.1059L8.56051 15.1659C8.29084 16.278 6.70916 16.278 6.43949 15.1659L5.69748 12.1059C5.6012 11.7088 5.29118 11.3988 4.89413 11.3025L1.83408 10.5605C0.721973 10.2908 0.721972 8.70916 1.83408 8.43949L4.89413 7.69748C5.29118 7.6012 5.6012 7.29118 5.69748 6.89413L6.43949 3.83408Z" fill="#ffffff"/>
              <path d="M15.3474 14.5133C15.5133 13.8289 16.4867 13.8289 16.6526 14.5133L17.1092 16.3964C17.1685 16.6407 17.3593 16.8315 17.6036 16.8908L19.4867 17.3474C20.1711 17.5133 20.1711 18.4867 19.4867 18.6526L17.6036 19.1092C17.3593 19.1685 17.1685 19.3593 17.1092 19.6036L16.6526 21.4867C16.4867 22.1711 15.5133 22.1711 15.3474 21.4867L14.8908 19.6036C14.8315 19.3593 14.6407 19.1685 14.3964 19.1092L12.5133 18.6526C11.8289 18.4867 11.8289 17.5133 12.5133 17.3474L14.3964 16.8908C14.6407 16.8315 14.8315 16.6407 14.8908 16.3964L15.3474 14.5133Z" fill="#ffffff"/>
              <path d="M18.5717 3.71184C18.6806 3.26272 19.3194 3.26272 19.4283 3.71184L19.7279 4.94763C19.7668 5.10798 19.892 5.23318 20.0524 5.27206L21.2882 5.57172C21.7373 5.68062 21.7373 6.31938 21.2882 6.42828L20.0524 6.72794C19.892 6.76682 19.7668 6.89202 19.7279 7.05237L19.4283 8.28816C19.3194 8.73728 18.6806 8.73728 18.5717 8.28816L18.2721 7.05237C18.2332 6.89202 18.108 6.76682 17.9476 6.72794L16.7118 6.42828C16.2627 6.31938 16.2627 5.68062 16.7118 5.57172L17.9476 5.27206C18.108 5.23318 18.2332 5.10798 18.2721 4.94763L18.5717 3.71184Z" fill="#ffffff"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M19 3.93109L18.7554 4.93972C18.6577 5.34288 18.3429 5.65766 17.9397 5.75542L16.9311 6L17.9397 6.24458C18.3429 6.34234 18.6577 6.65712 18.7554 7.06029L19 8.06891L19.2446 7.06029C19.3423 6.65712 19.6571 6.34234 20.0603 6.24458L21.0689 6L20.0603 5.75542C19.6571 5.65766 19.3423 5.34288 19.2446 4.93972L19 3.93109ZM19.4895 3.38496C19.365 2.87168 18.635 2.87168 18.5105 3.38496L18.1681 4.79729C18.1236 4.98055 17.9805 5.12363 17.7973 5.16807L16.385 5.51054C15.8717 5.635 15.8717 6.365 16.385 6.48947L17.7973 6.83193C17.9805 6.87637 18.1236 7.01945 18.1681 7.20271L18.5105 8.61504C18.635 9.12832 19.365 9.12832 19.4895 8.61504L19.8319 7.20271C19.8764 7.01945 20.0195 6.87637 20.2027 6.83193L21.615 6.48947C22.1283 6.365 22.1283 5.635 21.615 5.51054L20.2027 5.16807C20.0195 5.12363 19.8764 4.98055 19.8319 4.79729L19.4895 3.38496Z" fill="#ffffff"/>
              <path d="M7 23C6.44772 23 6 22.5523 6 22C6 21.4477 6.44772 21 7 21C7.55228 21 8 21.4477 8 22C8 22.5523 7.55228 23 7 23Z" fill="#ffffff"/>
              <path d="M2 19C1.44772 19 1 18.5523 1 18C1 17.4477 1.44772 17 2 17C2.55228 17 3 17.4477 3 18C3 18.5523 2.55228 19 2 19Z" fill="#ffffff"/>
              <path d="M13 3C12.4477 3 12 2.55228 12 2C12 1.44772 12.4477 1 13 1C13.5523 1 14 1.44772 14 2C14 2.55228 13.5523 3 13 3Z" fill="#ffffff"/>
              <path d="M22 14C21.4477 14 21 13.5523 21 13C21 12.4477 21.4477 12 22 12C22.5523 12 23 12.4477 23 13C23 13.5523 22.5523 14 22 14Z" fill="#ffffff"/>
            </svg>

            <div *ngIf="showDropdown" class="dropdown-content d-flex flex-column items-end">
              <i id="icon2"
                 (click)="emojifyText(); !isMobile && (showDropdown = false)"
                 class="icon-text">
                <svg
                  xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none">
                  <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z" fill="white"/>
                  <path d="M15.5 10.5C14.26 10.5 13.25 9.49 13.25 8.25C13.25 7.01 14.26 6 15.5 6C16.74 6 17.75 7.01 17.75 8.25C17.75 9.49 16.74 10.5 15.5 10.5ZM15.5 7.5C15.09 7.5 14.75 7.84 14.75 8.25C14.75 8.66 15.09 9 15.5 9C15.91 9 16.25 8.66 16.25 8.25C16.25 7.84 15.91 7.5 15.5 7.5Z" fill="white"/>
                  <path d="M8.5 10.5C7.26 10.5 6.25 9.49 6.25 8.25C6.25 7.01 7.26 6 8.5 6C9.74 6 10.75 7.01 10.75 8.25C10.75 9.49 9.74 10.5 8.5 10.5ZM8.5 7.5C8.09 7.5 7.75 7.84 7.75 8.25C7.75 8.66 8.09 9 8.5 9C8.91 9 9.25 8.66 9.25 8.25C9.25 7.84 8.91 7.5 8.5 7.5Z" fill="white"/>
                  <path d="M12 19.45C9.1 19.45 6.75 17.09 6.75 14.2C6.75 13.29 7.49 12.55 8.4 12.55H15.6C16.51 12.55 17.25 13.29 17.25 14.2C17.25 17.09 14.9 19.45 12 19.45ZM8.4 14.05C8.32 14.05 8.25 14.12 8.25 14.2C8.25 16.27 9.93 17.95 12 17.95C14.07 17.95 15.75 16.27 15.75 14.2C15.75 14.12 15.68 14.05 15.6 14.05H8.4Z" fill="white"/>
                </svg><span class="emojify-content font-14 mr-1">شکلک گذاری</span></i>

              <i id="icon3" class="icon-text"

                 (click)="enhanceText(); !isMobile && (showDropdown = false)">

                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0308 3.96962C18.9079 2.84673 17.093 2.84673 15.9701 3.96962L14.4938 5.44592C14.489 5.45051 14.4842 5.45518 14.4794 5.45992C14.4747 5.46466 14.47 5.46945 14.4654 5.47428L2.9701 16.9696C1.84721 18.0925 1.84721 19.9074 2.9701 21.0303C4.09299 22.1532 5.90787 22.1532 7.03076 21.0303L20.0308 8.03028C21.1537 6.90739 21.1537 5.09252 20.0308 3.96962ZM15.01 7.05109L4.03076 18.0303C3.49365 18.5674 3.49365 19.4325 4.03076 19.9696C4.56787 20.5067 5.43299 20.5067 5.9701 19.9696L16.9493 8.99043L15.01 7.05109ZM18.01 7.92977L18.9701 6.96962C19.5072 6.43252 19.5072 5.56739 18.9701 5.03028C18.433 4.49318 17.5679 4.49318 17.0308 5.03028L16.0706 5.99043L18.01 7.92977Z" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.64645 1.64646C6.77574 1.51717 6.96529 1.46876 7.14074 1.52023L8.5 1.91895L9.85926 1.52023C10.0347 1.46876 10.2243 1.51717 10.3536 1.64646C10.4828 1.77575 10.5312 1.9653 10.4798 2.14075L10.0811 3.50001L10.4798 4.85928C10.5312 5.03473 10.4828 5.22428 10.3536 5.35357C10.2243 5.48286 10.0347 5.53126 9.85926 5.4798L8.5 5.08108L7.14074 5.4798C6.96529 5.53126 6.77574 5.48286 6.64645 5.35357C6.51716 5.22428 6.46875 5.03473 6.52022 4.85928L6.91893 3.50001L6.52022 2.14075C6.46875 1.9653 6.51716 1.77575 6.64645 1.64646ZM7.73736 2.73737L7.91978 3.35928C7.94674 3.45116 7.94674 3.54886 7.91978 3.64075L7.73736 4.26265L8.35926 4.08023C8.45115 4.05327 8.54885 4.05327 8.64074 4.08023L9.26264 4.26265L9.08022 3.64075C9.05326 3.54886 9.05326 3.45116 9.08022 3.35928L9.26264 2.73737L8.64074 2.9198C8.54885 2.94675 8.45115 2.94675 8.35926 2.9198L7.73736 2.73737Z" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.64645 7.64646C2.77574 7.51717 2.96529 7.46876 3.14074 7.52023L4.5 7.91894L5.85926 7.52023C6.03471 7.46876 6.22426 7.51717 6.35355 7.64646C6.48284 7.77575 6.53125 7.9653 6.47978 8.14075L6.08107 9.50001L6.47978 10.8593C6.53125 11.0347 6.48284 11.2243 6.35355 11.3536C6.22426 11.4829 6.03471 11.5313 5.85926 11.4798L4.5 11.0811L3.14074 11.4798C2.96529 11.5313 2.77574 11.4829 2.64645 11.3536C2.51716 11.2243 2.46875 11.0347 2.52022 10.8593L2.91893 9.50001L2.52022 8.14075C2.46875 7.9653 2.51716 7.77575 2.64645 7.64646ZM3.73736 8.73737L3.91978 9.35928C3.94674 9.45116 3.94674 9.54886 3.91978 9.64075L3.73736 10.2627L4.35926 10.0802C4.45115 10.0533 4.54885 10.0533 4.64074 10.0802L5.26264 10.2627L5.08022 9.64075C5.05326 9.54886 5.05326 9.45116 5.08022 9.35928L5.26264 8.73737L4.64074 8.9198C4.54885 8.94675 4.45115 8.94675 4.35926 8.9198L3.73736 8.73737Z" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6464 12.6465C17.7757 12.5172 17.9653 12.4688 18.1407 12.5202L19.5 12.9189L20.8593 12.5202C21.0347 12.4688 21.2243 12.5172 21.3536 12.6465C21.4828 12.7757 21.5313 12.9653 21.4798 13.1407L21.0811 14.5L21.4798 15.8593C21.5313 16.0347 21.4828 16.2243 21.3536 16.3536C21.2243 16.4829 21.0347 16.5313 20.8593 16.4798L19.5 16.0811L18.1407 16.4798C17.9653 16.5313 17.7757 16.4829 17.6464 16.3536C17.5172 16.2243 17.4688 16.0347 17.5202 15.8593L17.9189 14.5L17.5202 13.1407C17.4688 12.9653 17.5172 12.7757 17.6464 12.6465ZM18.7374 13.7374L18.9198 14.3593C18.9467 14.4512 18.9467 14.5489 18.9198 14.6407L18.7374 15.2627L19.3593 15.0802C19.4512 15.0533 19.5488 15.0533 19.6407 15.0802L20.2626 15.2627L20.0802 14.6407C20.0533 14.5489 20.0533 14.4512 20.0802 14.3593L20.2626 13.7374L19.6407 13.9198C19.5488 13.9468 19.4512 13.9468 19.3593 13.9198L18.7374 13.7374Z" fill="white"/>
                </svg>
                <span class="emojify-content font-14 mr-1">بهبود متن</span></i>
            </div>
          </div>

          <div id="restoreIcon" class="xma-restore position-absolute" *ngIf="isImproved">

            <i id="icon4" (click)="restoreOriginalText()">
              <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none">
                <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM13.92 16.63H9C8.59 16.63 8.25 16.29 8.25 15.88C8.25 15.47 8.59 15.13 9 15.13H13.92C15.2 15.13 16.25 14.09 16.25 12.8C16.25 11.51 15.21 10.47 13.92 10.47H8.85L9.11 10.73C9.4 11.02 9.4 11.5 9.11 11.79C8.96 11.94 8.77 12.01 8.58 12.01C8.39 12.01 8.2 11.94 8.05 11.79L6.48 10.22C6.19 9.93 6.19 9.45 6.48 9.16L8.05 7.59C8.34 7.3 8.82 7.3 9.11 7.59C9.4 7.88 9.4 8.36 9.11 8.65L8.77 8.98H13.92C16.03 8.98 17.75 10.7 17.75 12.81C17.75 14.92 16.03 16.63 13.92 16.63Z" fill="#292D32"/>
              </svg>
            </i>
          </div>

          <div id="redoIcon" class="xma-restore position-absolute ml-2" *ngIf="canRedo">

            <i id="icon5" (click)="redoText()">
              <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none">
                <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM17.53 10.22L15.96 11.79C15.81 11.94 15.62 12.01 15.43 12.01C15.24 12.01 15.05 11.94 14.9 11.79C14.61 11.5 14.61 11.02 14.9 10.73L15.16 10.47H10.08C8.8 10.47 7.75 11.51 7.75 12.8C7.75 14.09 8.79 15.13 10.08 15.13H15C15.41 15.13 15.75 15.47 15.75 15.88C15.75 16.29 15.41 16.63 15 16.63H10.08C7.97 16.63 6.25 14.91 6.25 12.8C6.25 10.69 7.97 8.97 10.08 8.97H15.23L14.9 8.65C14.61 8.36 14.61 7.88 14.9 7.59C15.19 7.3 15.67 7.3 15.96 7.59L17.53 9.16C17.82 9.46 17.82 9.93 17.53 10.22Z" fill="#292D32"/>
              </svg>
            </i>
          </div>

        </div>

        <div class="count-sms-wrapper">
          <div class="cancellation-text">
            <span *ngIf="cancellation">{{cancellation}}</span>
          </div>
          <div class="count-sms">
            <span>{{localSmsLen}} / {{container}}</span> |
            <span>{{smsCount}} پیامک </span>
          </div>
        </div>
      </form>
      <div class="error-place">
        <div class="text-left">
          <app-error [haveSpace]="true" *ngIf="errorMsg" [text]="errorMsg"></app-error>
          <app-error [haveSpace]="true" *ngIf="form.controls.text.hasError('maxLength')"
                     [text]="'تعداد کارکتر وارد شده بیشتر از مقدار مجاز می باشد'"></app-error>
        </div>
      </div>
    </div>

    <div *ngIf="textBuilderType !== textBuilderTypeEnum.Textarea"
         [ngClass]="{'box-container': hasTransparentBackground}"
         class="text-builder-params box-container">

      <div *ngIf="textBuilderType === textBuilderTypeEnum.TemplateAndParametric ||
                textBuilderType === textBuilderTypeEnum.Template ||
                textBuilderType === textBuilderTypeEnum.Parametric"
           class="personalize-box">
        <ul class="tab-div">
          <li
            *ngIf="textBuilderType === textBuilderTypeEnum.Template || textBuilderType === textBuilderTypeEnum.TemplateAndParametric"
            class="title-tab">
            <a (click)="switchTab('templates')"
               [ngClass]="{'active' : activeTab === 'templates'}">پیش‌نویس‌ها</a>
          </li>
          <li
            *ngIf="textBuilderType === textBuilderTypeEnum.Parametric || textBuilderType === textBuilderTypeEnum.TemplateAndParametric"
            class="title-tab">
            <a (click)="switchTab('personalize')"
               [ngClass]="{'active' : activeTab === 'personalize'}">شخصی‌سازی</a>
          </li>
        </ul>

        <div *ngIf="activeTab === 'personalize'" class="personalize">
          <div *ngIf="isURL">
            <form [formGroup]="formURL" class="mt-3">
              <label>لطفا لینک خود را وارد کنید</label>
              <div class="d-flex align-items-center justify-content-between url-row mt-1">
                <div class="w-100">
                  <input (input)="importURL($event)"
                         [dir]="'ltr'"
                         [value]=""
                         formControlName="webURL"
                         placeholder="لطفا لینک خود را وارد کنید"
                         type="text"
                  />
                </div>
                <button (click)="generateURL()"
                        [disabled]="!formURL.valid"
                        class="secondary-btn btn mr-2">
                  تبدیل
                </button>
              </div>
              <div class="hint">
                <i class="icon-circle-info"></i>
                <span>به‌صورت پیش‌فرض مقدار https در آدرس شما قرار می‌گیرد.</span>
              </div>
            </form>
          </div>
          <ng-container *ngIf="isURL">
            <div *ngFor="let parameter of parameters" class="parameter-item">
              <button (click)="addSegment(parameter, message)"
                      *ngIf="parameter.parameter === 'link'"
                      [disabled]="isShortLink"
                      class="url-add no-border green-text"> {{parameter.title ? parameter.title : parameter.parameter}}
              </button>
            </div>
          </ng-container>
          <div *ngFor="let parameter of parameters" class="parameter-item">
            <button (click)="addSegment(parameter, message)"
                    *ngIf="parameter.parameter !== 'link'"
                    class="green-text no-border"> {{parameter.title ? parameter.title : parameter.parameter}} </button>
          </div>
        </div>

        <div
          *ngIf="(textBuilderType === textBuilderTypeEnum.Template || textBuilderType === textBuilderTypeEnum.TemplateAndParametric) && activeTab === 'templates'"
          class="template">
          <div class="search-wrapper">
            <i class="icon-search search-wrapper__icon"></i>
            <input (input)="onInput($event)" class="search-wrapper__input" placeholder="جستجو..."
                   type="text">
          </div>
          <div class="template-container">
            <p *ngIf="!templates || templates.length === 0">پیش‌ نویسی یافت نشد.</p>
            <div class="template-list">
              <button (click)="selectTemplate(draft)"
                      *ngFor="let draft of templates;let i = index"
                      type="button">{{i + 1}}. {{draft.title}}</button>
            </div>
          </div>
        </div>

      </div>

      <div *ngIf="textBuilderType === textBuilderTypeEnum.FastSend" class="personalize-box">
        <ul class="tab-div">
          <li class="title-tab">
            <a class="active">پارامترهای شخصی‌سازی</a>
          </li>
        </ul>
        <div class="fast-send-details">
          <p>این پارامترها توسط شما و باتوجه به نیازتان ایجاد می‌شوند.</p>
          <p>اگر مایل به استفاده از اسامی متغییر در قالب نوشتاری هستید، کلمات موردنظر خود را در داخل # قرار
            دهید.</p>
          <p>مانند: #contacts#</p>
        </div>
        <div class="alert alert-info mt-2" role="alert">
          قالب باید دارای متغیر باشد.
        </div>
        <div class="alert alert-info mt-2" role="alert">
          سایت ذکر شده در قالب باید دارای اینماد باشد
        </div>
      </div>

    </div>
  </div>
</ng-container>


