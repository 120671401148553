export enum AppNotifications {
  ContactExistsInThisGroup = 'شماره مخاطب وارد شده در این گروه وجود دارد',
  DuplicateTitleOrName = 'عنوان وارد شده تکراری است',
  FileIsInvalid = 'محتوای فایل آپلود شده قابل قبول نمی‌باشد',
  NotAllowedContactsCount = 'تعداد مخاطبان بیش از حدمجاز است.',
  CacheKeyNotFound = 'زمان تکمیل فرآیند به اتمام رسید، لطفا مجددا تلاش نمایید',
  ContactAlreadyExists = 'شماره مخاطب وارد شده در سایر گروه های شما موجود است.',
  MobileUsedByAnotherUser = 'شماره وارد شده قبلا در سامانه ثبت شده است!',
  UserNotFound = 'کاربری با این مشخصات وجود ندارد',
  UserIsSuspended = 'حساب کاربری شما مسدود است، لطفا با پشتیبانی سامانه تماس بگیرید.',
  MobileVerificationCodeIsWrong = 'کد فعالسازی نامعتبر است.',
  UserApiKeyLimitationCount = 'محدودیت ساخت کلید وب‌سرویس',
  AlreadyReturnInvoiceIssued = 'این فاکتور دارای فاکتور بازگشتی می‌باشد.',
  UserCouldNotHasOfficialInvoice = 'عدم قابلیت صدور فاکتور رسمی برای کاربر',
  PasswordIsWrong = 'رمز عبور فعلی صحیح نمی‌باشد',
  UserDeactivationAllowed24Hours = 'پس از گذشت حداکثر 24 ساعت انجام این عملیات امکان پذیر است',
  DuplicateEmailAddress = 'آدرس ایمیل تکراری است.',
  DuplicateUserName = 'نام کاربری انتخاب شده تکراری است.',
  DuplicateOfficialInvoiceNumber = 'شماره فاکتور رسمی تکراری است.',
  DiscountCodeIsWrong = 'کد تخفیف اشتباه است',
  DiscountCodeHasExpired = 'کد تخفیف منقضی شده است.',
  DiscountCodeUsageLimitationReached = 'به تعداد حداکثر دفعات مجاز، از این کد استفاده نموده‌اید',
  UserLinePrebuyExists = 'خط توسط کاربر دیگری موقتا رزرو شده است',
  DuplicateGiftLineNumber = 'خط هدیه تکراری است.',
  DuplicateUserLineNumber = 'خط کاربر تکراری است.',
  CantModifyStartedABTest = 'کمپین شروع شده را نمی‌توانید ویرایش کنید.',
  ItemCountLimitation = 'حداکثر آیتم مجاز',
  EventIsRelatedToTheCampaign = 'این رویداد در کمپین استفاده شده است. لطفا ابتدا کمپین را حذف کنید.',
  ContactGroupUsedInCampaign = 'این گروه از مخاطبان در یک کمپین مورد استفاده قرار گرفته است.',
  EmailVerificationAllowedPer2Minutes = 'تأیید ایمیل پس از گذشت دو دقیقه مجاز است',
  LineUsedOnAnotherModule = 'خط در ماژولی دیگر استفاده شده است ',
  SmsSecretaryBehaviorLimitationCount = 'محدودیت تعداد عملگر منشی پیامکی',
  DuplicateParameter = 'پارامتر تکراری می باشد',
  CreditNotEnough = 'برای انجام عملیات، اعتبار شما کافی نمی‌باشد',
  KeywordIsDuplicated = 'کیلدواژه تکراری می باشد',
  TestFastSendLimitationCount = 'محدودیت روزانه در تست ارسال سریع (۳ ارسال)',
  RetryOperation = 'RetryOperation',
  OverlapInRanges = 'تداخل در بازه‌های زمانی انتخاب شده',
  TempRefCodeIsExpired = 'لینک شما منقضی شد‌ه ‌است. لطفا برای دریافت لینک مجددا تلاش کنید.',
  ParameterUsedInBlackListKeywords = 'لطفا پارامتر یکتا انتخاب کنید. این پارامتر در لیست سیاه استفاده شده ‌است.',
  CooperationMessageParameterLimitationCount = 'محدودیت تعداد پارامتر در همکاری در فروش',
  ParameterUsedOnAnotherModule = 'این پارامتر در ماژول دیگری استفاده شده است.',
  UserHasOpenRequest = 'شما یک درخواست در حال بررسی دارید.',
  RegistrationThroughTempCodeHasAlreadyBeenDone = 'ثبت نام از طریق این لینک قبلا انجام شده است.',
  AddValidIPForTwoStepLoginLimitationCount = 'IP های وارد شده بیش از حد مجاز است.',
  AddValidMobileToSendVerificationCodeLimitationCount = 'شماره موبایل های وارد شده بیش از حد مجاز است.',
  UsersMobileCannotBeInTheWhiteList = 'شما مجاز به افزودن شماره موبایل کاربری در این لیست نمی باشید.',
  UserHaveAnyManualPaymentWaitingToConfirmAdmin = 'لطفا تا زمان تعیین وضعیت پرداخت ثبت شده خود منتظر بمانید.',
  DefaultMessageInCodeReaderIsRequired = 'پیام پیش فرض در کدخوان اجباری می باشد.',
  LegalUserMustModifyUnderSupervisionFinancialDepartment = 'کاربر موردنظر فاکتور ثبت شده دارد. لطفا برای تغییر اطلاعات حقوقی به واحد مالی ارجاع دهید.',
  DowngradePlanIsNotPermitted = "خرید این پلن برای شما امکان پذیر نیست. لطفا پلن دیگری را انتخاب کنید.",
  AlreadyHasNotExpiredPlan = "پلن شما هنوز به زمان انقضای خود نرسیده است و در حال حاضر امکان تمدید یا تنزل پلن وجود ندارد.",
  PlanItemAccessibility = "اعتبار پلن شما به پایان رسید. لطفا پلن خود را تمدید کنید.",
  PlanItemLimitationCountUserTemplate = 'PlanItemLimitationCountUserTemplate',
  PlanItemLimitationCountBlackListInquiryCount = 'PlanItemLimitationCountBlackListInquiryCount',
  PlanItemLimitationCountFastSend = 'PlanItemLimitationCountFastSend',
  PlanItemLimitationApiKey = 'PlanItemLimitationApiKey',
  PlanItemLimitationSmsTransmissionApiWithParameter = 'PlanItemLimitationSmsTransmissionApiWithParameter',
  PlanItemLimitationSmsTransmissionEmailWithParameter = 'PlanItemLimitationSmsTransmissionEmailWithParameter',
  PlanItemLimitationSmsTransmissionMobileWithParameter = 'PlanItemLimitationSmsTransmissionMobileWithParameter',
  PlanItemLimitationSmsSecretaryWithParameter = 'PlanItemLimitationSmsSecretaryWithParameter',
  PlanItemLimitationCodeReaderParameters = 'PlanItemLimitationCodeReaderParameters',
  PlanItemLimitationContacts = 'PlanItemLimitationContacts',
  PlanItemLimitationContactGroups = 'PlanItemLimitationContactGroups',
  PlanItemLimitationContactEvents = 'PlanItemLimitationContactEvents',
  PlanItemLimitationConcurrentOpenTicketsCount = 'PlanItemLimitationConcurrentOpenTicketsCount',
  PreviousDaysHasPendingInvoice = 'فاکتور شماره گذاری نشده از روز قبل دارید.',
  MobileIsBlocked = 'شماره موبایل شما بلاک شده است. لطفا با پشتیبانی تماس بگیرید.',
  MobileIsAlreadyBlocked = 'این شماره موبایل در حال حاضر بلاک می باشد.',
  PaymentLimitation = 'در حال حاضر عملیات پرداخت امکان پذیر نیست.',
  ABTestSelectedContactLessThanMin = 'تعداد مخاطبان کمتر از حد نصاب است. لطفا تعداد مخاطبان را افزایش دهید.',
  CantModifyMessageTextOfHandledScheduledCampaign = 'در کمپین زمانبندی پیشرفته، بعد از اجرای کمپین امکان ویرایش متن پیام وجود ندارد.',
  SendSmsViaTowerRequestIsExpired = 'درخواست ارسال از دکل شما منقضی شده لطفا بار دیگر درخواست ثبت کنید',
  SendSmsViaTowerRequestIsUnchangeable = 'درخواست ارسال از دکل شما قابل تغییر نمی باشد',
  PublicLineIsNotActive = 'در حال حاضر خطوط ویژه فعال نیستند.',
  UserDoesNotHaveAccessToPublicLines = 'کاربر گرامی، زمان دسترسی شما به خطوط ویژه پایان یافته است.',
  PublicLineSendLimitationCount = 'حداقل تعداد مخاطب در ارسال با خطوط ویژه رعایت نشده‌است.',
  SendSmsTypeDeactivated = 'در حال حاضر، استفاده از این قابلیت امکان‌پذیر نیست.',
  UserTagNotFound = 'پوشه مورد نظر یافت نشد.',
  UserTagsAreNotOfTheSameType = 'پوشه مبدا و مقصد از یک نوع نیستند.',
  UserTagNameDuplicated = 'نام پوشه تکراری است.',
  PaymentGatewayUnreachable = 'ارتباط با درگاه انتخاب شده در این لحظه امکان پذیر نمی‌باشد.',
  DiscountCodeCouldNotAffectTheCreditTier = 'اعمال کد تخفیف در بازه انتخابی شما، امکان‌پذیر نمی‌باشد.',
  UserHasUnfinishedRequest = 'لطفا تا اتمام درخواست افزودن قبلی منتظر بمانید.',
  PlanAccessibility = 'لطفا برای استفاده از این امکان، یکی از پلن های سامانه را انتخاب نمایید.',
  DailyCreditTransactionLimitExceeded = 'سقف مجاز تراکنش‌های اعتبار روزانه شما پر شده است',
  CustomerClubServiceConnectionFailed = 'مشکلی در ارتباط با باشگاه مشتریان وجود دارد.',
  UserHasSameCustomerClubDeviceName = 'نام دستگاه تکراری است.',
  CustomerClubDeviceIsAlreadyConnected = 'این دستگاه قبلا به باشگاه مشتریان متصل شده‌است.',
  CustomerClubContactGroupIsInUseByDevices = 'این گروه مخاطب توسط دستگاه دیگری در حال استفاده است.',
  DeviceContactGroupExists = 'این گروه مخاطب قبلاً به دستگاه اضافه شده‌است.',
  CannotRemoveDefaultContactGroup = 'امکان حذف گروه مخاطب پیش‌فرض وجود ندارد.',
  CouldNotConnectToReportService = 'اتصال به سرویس گزارش‌دهی ممکن نیست.  لطفاً بعداً دوباره امتحان کنید.',
  ReportRequestTimeout = 'زمان درخواست گزارش تمام شد. لطفاً دوباره تلاش کنید.',
  NumberOfRecordsHasExceededTheLimit = 'تعداد رکوردها بیش از حد مجاز است. درخواست را محدودتر کنید.',
  ReportServiceTerminated = 'سرویس گزارش‌دهی متوقف شد. بعداً دوباره امتحان کنید.',
  /*
  * separate errors
  */
  RefreshTokenNotFound = 'رفرش توکن یافت نشد',
  PasswordNotMatch = 'پسورد های وارد شده با هم یکسان نمی‌باشد.',
  VerificationCodeSent = 'کد به شماره همراه وارد شده ارسال شد.',
  VerificationCodeAlreadySent = 'از آخرین کد ارسالی استفاده کنید.',
  ContactsAdded = 'مخاطبان با موفقیت به سامانه اضافه شدند.',
  ImageSizeInvalid = 'سایز عکس بیشتر از مقدار مجاز است.',
  UploadedFileSizeError = 'سایز فایل انتخابی بیشتر از حد مجاز می‌باشد.',
  UploadedFileFormatIncorrect = 'فرمت فایل انتخابی قابل قبول نیست.',
  PasswordChangeSuccess = 'تغییر پسورد با موفقیت انجام شد.',
  EventCreated = 'رویداد با موفقیت به سامانه اضافه شد.',
  UploadedSuccessfully = 'فایل مورد نظر با موفقیت بارگزاری شد.',
  ChangesSaved = 'تغییرات با موفقیت ذخیره شد.',
  EventDeleted = 'رویداد مورد نظر حذف شد.',
  GroupNameChanged = 'نام گروه با موفقیت تغییر یافت.',
  GroupDeleted = 'گروه مورد نظر حذف شد.',
  GroupCreated = 'گروه با موفقیت ایجاد شد.',
  DeviceCreated = 'دستگاه با موفقیت ایجاد شد.',
  DeviceConnected = 'دستگاه با موفقیت متصل شد.',
  IpAddedSuccessfully = 'آی پی با موفقیت اضافه گردید.',
  LoginAgain = 'لطفا مجدد وارد حساب کاربری خود شوید.',
  UnknownError = 'خطایی در سامانه رخ داده است.',
  /*
  * separate errors
  */
}
