import { Injectable } from '@angular/core';
import {Observable} from "rxjs";

import {ApiService} from "@app/api/services/base/api.service";
import {BaseResponse} from "@app/api/models/base/api.model";
import {PagedDataModel} from "@shared/models/paged-data.model";
import {ITower, ITowerConfiguration, ITowerRow} from "@app/api/models/components/tower-send/tower-send.model";
import {ITowerPayload, ITowerRowPayload} from "@app/api/models/components/tower-send/tower-send-payload.model";


@Injectable()
export class TowerSendService {

  constructor(
      private _ApiService: ApiService,
  ) {
  }

  towerRows(payload: ITowerRowPayload): Observable<BaseResponse<PagedDataModel<ITowerRow>>> {
    const path = 'send-via-tower';
    return this._ApiService.get({path, queryParams: payload.params})
  }

  tower(requestId: number): Observable<BaseResponse<ITower>>{
    const path = `send-via-tower/${requestId}`;
    return this._ApiService.get({path})
  }

  configuration():Observable<BaseResponse<ITowerConfiguration>>{
    const path = 'send-via-tower/configurations';
    return this._ApiService.get({path})
  }

  deleteTower(requestId: number): Observable<BaseResponse<boolean>>{
    const path = `send-via-tower/${requestId}`;
    return this._ApiService.delete({path})
  }

  createTower(payload: ITowerPayload): Observable<BaseResponse<number>>{
    const path = 'send-via-tower';
    return this._ApiService.postFile({path, body: payload.body});
  }

  updateTower(payload: ITowerPayload): Observable<BaseResponse<boolean>>{
    const path = `send-via-tower/${payload.requestId}`;
    return this._ApiService.putFile({path, body: payload.body});
  }
}
