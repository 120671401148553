import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

import {ValidatorsService} from "@shared/services/validators.service";
import {ToastifyService} from "@shared/services/toastify.service";
import {AppNotifications} from "@shared/enums/app-notifications.enum";
import {TokenService} from "@app/api/services/base/token.service";
import {UserService} from "@app/api/services/components/user.service";
import {GuestService} from "@app/api/services/components/guest.service";


@UntilDestroy()
@Component({
  selector: 'app-password-modal',
  templateUrl: './password-modal.component.html',
  styleUrls: ['./password-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordModalComponent implements OnInit {
  changePasswordForm!: FormGroup;
  passwordShow = 'password';
  confirmPasswordShow = 'password';
  newPassword = '';
  isGuest!: boolean;

  constructor(
    private _UserService: UserService,
    private _GuestService: GuestService,
    private formBuilder: FormBuilder,
    private _TokenService: TokenService,
    private validatorsService: ValidatorsService,
    private toastifyService: ToastifyService,
    private activeModal: NgbActiveModal,
    private changeDetectorRef: ChangeDetectorRef) {

    this.createChangePasswordForm();
  }

  ngOnInit(): void {
    this.isGuest = !!this._TokenService.decodeToken().GuestId;
  }

  createChangePasswordForm() {
    this.changePasswordForm = this.formBuilder.group({
      newPassword: new FormControl('', [this.validatorsService.required(), this.validatorsService.password()]),
      confirmPassword: new FormControl('', [this.validatorsService.required()])
    }, {validator: this.validatorsService.checkMatchPassword('newPassword', 'confirmPassword')});
  }

  changePassword() {
    if (this.changePasswordForm.valid) {
      this.newPassword = this.changePasswordForm.controls.newPassword.value
      const body = JSON.stringify(this.newPassword)

      let api = this.isGuest ? this._GuestService.createPassword({body}) : this._UserService.createPassword({body});

      api.pipe(untilDestroyed(this))
        .subscribe(() => {
          this.newPassword = '';
          this.toastifyService.success(AppNotifications.PasswordChangeSuccess);
          this.createChangePasswordForm();
          this.changeDetectorRef.detectChanges();
          this.activeModal.close(true)
        });
    }
  }

  passwordToggle(event: any) {
    if (event === 'password') {
      if (this.passwordShow === 'password') {
        this.passwordShow = 'text';
      } else if (this.passwordShow === 'text') {
        this.passwordShow = 'password';
      }
    }
    if (event === 'confirm') {
      if (this.confirmPasswordShow === 'password') {
        this.confirmPasswordShow = 'text';
      } else if (this.confirmPasswordShow === 'text') {
        this.confirmPasswordShow = 'password';
      }
    }
  }
}
