import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

import {ApiService} from "@app/api/services/base/api.service";
import {BaseResponse} from "@app/api/models/base/api.model";
import {PagedDataModel} from "@shared/models/paged-data.model";
import {
  IReportCampaignInfoPayload,
  IReportCampaignPayload, IReportReceivePayload,
  IReportScheduledSendPayload, IReportSendPayload,
  IReportSendWebServicePayload
} from "@app/api/models/components/report/report-payload.model";
import {
  IReportCampaign,
  IReportCampaignInfo,
  IReportCampaignInfoItem,
  IReportFailedCampaign,
  IReportReceiveArchive,
  IReportReceiveLive,
  IReportScheduledSend,
  IReportSend,
  IReportSendWebService
} from "@app/api/models/components/report/report.model";


@Injectable()
export class ReportService {
  constructor(
    private _ApiService: ApiService,
  ) {
  }

  scheduledSendReport(payload: IReportScheduledSendPayload): Observable<BaseResponse<PagedDataModel<IReportScheduledSend>>> {
    const path = `scheduled-send`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  deleteScheduledSendReport(id: number): Observable<BaseResponse<boolean>>{
    const path = `scheduled-send/${id}`;
    return this._ApiService.delete({path});
  }

  retryScheduledSendReport(id: number): Observable<BaseResponse<boolean>>{
    const path = `scheduled-send/${id}/retry`;
    return this._ApiService.patch({path, body: null});
  }

  sendWebServiceReport(payload: IReportSendWebServicePayload): Observable<BaseResponse<PagedDataModel<IReportSendWebService>>> {
    const path = `send/web-service`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  campaignReport(payload: IReportCampaignPayload): Observable<BaseResponse<PagedDataModel<IReportCampaign>>>{
    const path = `reports-campaign`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  failedCampaignReport(payload: IReportCampaignPayload): Observable<BaseResponse<PagedDataModel<IReportFailedCampaign>>>{
    const path = `failed-campaign`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  retryFailedCampaignReport(id: number): Observable<BaseResponse<boolean>>{
    const path = `failed-campaign/${id}`;
    return this._ApiService.patch({path, body: null});
  }

  deleteFailedCampaignReport(id: number): Observable<BaseResponse<boolean>>{
    const path = `failed-campaign/${id}`;
    return this._ApiService.delete({path});
  }

  campaignReportInfo(payload: IReportCampaignInfoPayload): Observable<BaseResponse<IReportCampaignInfo>>{
    const path = `reports-campaign/${payload.type}/${payload.campaignId}`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  campaignReportInfoItem(payload: IReportCampaignInfoPayload): Observable<BaseResponse<PagedDataModel<IReportCampaignInfoItem>>>{
    const path = `reports-campaign/${payload.type}/${payload.campaignId}/sent-sms`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  sendReport(payload: IReportSendPayload): Observable<BaseResponse<PagedDataModel<IReportSend>>>{
    const path = `send`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  liveReceiveReport(payload: IReportReceivePayload): Observable<BaseResponse<PagedDataModel<IReportReceiveLive>>>{
    const path = `receive/live`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  archiveReceiveReport(payload: IReportReceivePayload): Observable<BaseResponse<PagedDataModel<IReportReceiveArchive>>>{
    const path = `receive/archive`;
    return this._ApiService.get({path, queryParams: payload.params});
  }
}
