import { Injectable } from '@angular/core';
import {ApiService} from "@app/api/services/base/api.service";
import {Observable} from "rxjs";
import {BaseResponse} from "@app/api/models/base/api.model";
import {IAddFundCreditPackage, IAddFundCreditTiers} from "@app/api/models/components/add-fund/add-fund.model";
import {IAddFundParamsPayload} from "@app/api/models/components/add-fund/add-fund-payload.model";


@Injectable()
export class AddFundService {

  constructor(
      private _ApiService: ApiService,
  ) {
  }

  creditPackage(): Observable<BaseResponse<IAddFundCreditPackage[]>> {
    const path = `credit/package`;
    return this._ApiService.get({path})
  }

  creditTiers(): Observable<BaseResponse<IAddFundCreditTiers>> {
    const path = `credit/tiers`;
    return this._ApiService.get({path})
  }

  creditCustom(payload: IAddFundParamsPayload): Observable<BaseResponse<any>>{
    const path = `credit/custom`;
    return this._ApiService.get({path, queryParams: payload.params})
  }
}
