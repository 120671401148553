export enum PlanItemsNameEnum {
  BulkSend = "ارسال انبوه",
  MobileBankSend = "ارسال به بانک شماره",
  ParametricSend = "ارسال پارامتریک",
  MultipleTextSend = "ارسال متناظر ",
  ScheduledCampaign = "کمپین زمانبندی شده",
  ABTestCampaign = "کمپین A/B",
  EventCampaign = "کمپین رویدادی",
  ReceivedMessagesReport = "گزارش دریافتی ها",
  UserTemplate = "پیش نویس",
  BlackListInquiryCount = "استعلام لیست سیاه",
  SaleCooperation = "همکاری در فروش",
  PersonalBlackListKeyword = "کلید واژه اختصاصی لیست سیاه",
  FastSend = "ارسال سریع",
  ApiKey = "تعداد کلید API",
  IPLimitationOnApiKeys = " IP قابل ثبت در کلید API",
  SmsTransmissionApiParameterless = "انتقال پیامک با URL (خودکار)",
  SmsTransmissionApiWithParameter = "تعداد انتقال پیامک با URL (پارامتریک)",
  SmsTransmissionEmailParameterless = "انتقال پیامک با ایمیل (خودکار)",
  SmsTransmissionEmailWithParameter = "تعداد انتقال پیامک با ایمیل (پارامتریک)",
  SmsTransmissionSmsParameterless = "انتقال پیامک با موبایل (خودکار)",
  SmsTransmissionSmsWithParameter = "تعداد انتقال پیامک با موبایل (پارامتریک)",
  SmsSecretaryParameterless = "منشی پیامکی (خودکار)",
  SmsSecretaryWithParameter = "تعداد منشی پیامکی (پارامتریک)",
  CodeReader = "کدخوان",
  CodeReaderParameters  = "تعداد پارامترهای کدخوان",
  Contacts = "تعداد مخاطبان",
  ContactGroups = "گروه بندی مخاطبان",
  ContactEvents = "رویداد مخاطبان",
  ConcurrentOpenTicketsCount = "تیکت های فعال",
  WebServices = "وب سرویس",
  XMATextEnhance = "بهبود متن پیامک با هوش مصنوعی",
  XMATextEmojify = "شکلک گذاری متن پیامک با هوش مصنوعی"
}
