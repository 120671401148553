import {Injectable} from '@angular/core';
import * as MJ from 'jalali-moment';

import {WeekdaysEnum} from "../../../../projects/cron-editor/src/lib/models/weekdays.enum";
import {FilterItemModel} from "@shared/components/table/models/filter-item.model";

@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    constructor() {
    }


    preventAnyCharactersExceptDigits(input: string){
      const numbers = ['۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹', '۰', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩', '٠', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
      const filteredString = input.split('').filter(char => numbers.includes(char)).join('');
      return this.convertPersianToEnglish(filteredString);
    }


    preventAlphabet(input: string): boolean {
        const numbers = ['۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹', '۰', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩', '٠', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
        return input.split('').every(char => numbers.includes(char));
    }

    convertPersianToEnglish(strNum: string): string {
        const persianNumbers = ['۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹', '۰'];
        const arabicNumbers = ['١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩', '٠'];
        const englishNumbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

        return strNum.split('').map(c => englishNumbers[persianNumbers.indexOf(c)] || englishNumbers[arabicNumbers.indexOf(c)] || c).join('');
    }

    numericalValidationMultiLang(strNum: string): string {
      const isValid = this.preventAlphabet(strNum);
      if (!isValid) return strNum.substring(0, strNum.length - 1);

      const result = this.convertPersianToEnglish(strNum);
      return strNum !== result ? result : strNum;
    }

    convertStringToDateObject(expression: any): { day: number, month: number, year: number } {
        expression = isNaN(expression) ? expression : String(expression);
        return {
            day: expression.slice(6, 8),
            month: expression.slice(4, 6),
            year: expression.slice(0, 4)
        };
    }

    convertStringToTimeObject(expression: any): { hour: number, minute: number, second: number } {
        expression = isNaN(expression) ? expression : String(expression);
        return {
            hour: expression.slice(8, 10),
            minute: expression.slice(10, 12),
            second: expression.slice(12, 14) ? expression.slice(12, 14) : '00'
        };
    }

    convertStingToDate(strNum: any) {
        if (!strNum) {
            return false
        } else {
            let minDate = strNum?.toString()
            if (minDate.length === 8) {
                return minDate.slice(0, 4) + '/' + minDate.slice(4, 6) + '/' + minDate.slice(6, 8);
            } else {
                return minDate.slice(0, 4) + '/' + minDate.slice(4, 6) + '/' + minDate.slice(6, 8) + ' ' + minDate.slice(8, 10) + ':' + minDate.slice(10, 12) + '00';
            }
        }
    }


    clone(obj: any): any {
        let copy;
        // Handle the 3 simple types, and null or undefined
        if (null == obj || 'object' != typeof obj) {
            return obj;
        }
        // Handle Date
        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }
        // Handle Array
        if (obj instanceof Array) {
            copy = [];
            for (let i = 0, len = obj.length; i < len; i++) {
                copy[i] = this.clone(obj[i]);
            }
            return copy;
        }
        // Handle Object
        if (obj instanceof Object) {
            let copy: any = {}
            for (const attr in obj) {
                if (obj.hasOwnProperty(attr)) {
                    copy[attr] = this.clone(obj[attr]);
                }
            }
            return copy;
        }
        throw new Error('Unable to copy obj! Its type isn\'t supported.');
    }

    containsNonLatinCodepoints(s: any): boolean {
        s = s.replace(/(\r\n|\n|\r)/gm, '');
        return /[^A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u0027\u0022\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\#$%&amp;()*+,\\./\-:;&lt;=&gt;?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*/.test(s);
    }


    convertJalaliToDate(jalaliDate: string): Date {
        // Parse the Jalali date from the string
        return MJ.from(jalaliDate, 'fa', 'YYYYMMDDHHmmss').locale('en').toDate()
        // const date = MJ(jalaliDate, 'YYYYMMDDHHmmss', 'en' ).toDate().getTime();
        // Convert to a timestamp
        // return date
    }


    getToday() {
        const now = new Date();
        const p = MJ(now, 'YYYY-M-D')
            .locale('fa')
            .format('YYYY/MM/DD');
        return p.substr(0, 4) + p.substr(5, 2) + p.substr(8, 2);
    }

    getMaxDate(day: any) {
        const now = new Date()
        const p = MJ(now, 'YYYY-M-D').add(day, 'day').locale('fa')
            .format('YYYY/MM/DD');
        return p.substr(0, 4) + p.substr(5, 2) + p.substr(8, 2);
    }

    getCurrentTime() {
        const now = new Date();
        const p = MJ(now, 'YYYY-M-D')
            .locale('fa')
            .format('HH:mm:ss');

        return p.substr(0, 2) + p.substr(3, 2) + p.substr(6, 2);
    }

    getPassedDefinedPeriod(oneMoreMin: boolean = false) {
        const now = new Date();
        const p = MJ(now, 'YYYY-M-D')
            .add(oneMoreMin ? 21 : 20, 'minutes')
            .locale('fa')
            .format('HH:mm:ss');
        return p.substr(0, 2) + p.substr(3, 2) + p.substr(6, 2);
    }

    private $weekday: FilterItemModel[] = [
        {name: 'شنبه', value: WeekdaysEnum.Saturday},
        {name: 'یکشنبه', value: WeekdaysEnum.Sunday},
        {name: 'دوشنبه', value: WeekdaysEnum.Monday},
        {name: 'سه شنبه', value: WeekdaysEnum.Tuesday},
        {name: 'چهارشنبه', value: WeekdaysEnum.Wednesday},
        {name: 'پنجشنبه', value: WeekdaysEnum.Thursday},
        {name: 'جمعه', value: WeekdaysEnum.Friday},
    ];

    translateCron($event: string) {
        const arr = $event.split(' ');
        if (arr[arr.length - 1] == '*' && arr[2].includes('/')) {
            // daily
            const d = arr[2].substring(arr[2].indexOf('/') + 1);
            const d2 = (Number(d) == 1) ? `هرروز ساعت ${arr[1]}:${arr[0]}` : `هر ${d} روز یکبار ساعت ${arr[1]}:${arr[0]}`;
            return d2;
        } else if (arr[arr.length - 1] == '*' && !arr[2].includes('/')) {
            return `${arr[2]} هر ماه، ساعت ${arr[1]}:${arr[0]}`
        } else {
            const days = arr[arr.length - 1].split(',');
            // weekly
            return `روز‌های ${days.map(p => this.$weekday.find(i => i.value == p)?.name).join(', ')} ساعت ${arr[1]}:${arr[0]}`;
        }
    }

    shortenText(text: string, length: number = 10): string {
        return (text?.length > 10) ? text.substr(0, length) + '...' : text;
    }

    convertBooleansToString(obj: { [key: string]: any }, reverseMode?: boolean): any {
        if (!reverseMode) {
            return Object.entries(obj).reduce((acc, [key, value]) => {
                acc[key] = typeof value === 'boolean' ? value.toString() : value;
                return acc;
            }, {} as { [key: string]: any });
        }
        return Object.entries(obj).reduce((acc, [key, value]) => {
            if (typeof value === 'string')
                switch (value.toLowerCase()) {
                    case 'true':
                        acc[key] = true;
                        break;
                    case 'false':
                        acc[key] = false;
                        break;

                    default:
                        acc[key] = value;
                        break;
                }
            else {
                acc[key] = value;
            }


            return acc;
        }, {} as { [key: string]: any });
    }

  convertStringNumbersToIntType(obj: any) {
    const result: any = {};

    // Iterate through each key-value pair in the object
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        // Check if the value is a string representation of a number
        if (!isNaN(value) && typeof value === 'string') {
          result[key] = Number(value); // Convert the string to a number and add to the result object
        } else {
          result[key] = value; // If not a string representation of a number, keep the original value
        }
      }
    }

    return result;
  }
}
