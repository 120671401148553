import {Component, Inject, OnInit} from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  ActivationStart,
  ChildActivationStart,
  NavigationEnd,
  NavigationStart,
  Router
} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {fromEvent} from "rxjs";
import {debounceTime, filter, map} from "rxjs/operators";
import {DOCUMENT} from "@angular/common";
import {UntilDestroy} from "@ngneat/until-destroy";

import {ConfigService} from "@shared/services/config.service";
import {StoreService} from "@shared/services/store.service";
import {DeviceType} from "@shared/models/device-type";
import {HelpService} from "@app/extra-components/help/services/help.service";
import {WorkerService} from "@shared/services/worker.service";


@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  prevUrl!: string;
  currentUrl!: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private storeService: StoreService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private workerService: WorkerService,
    private helpService: HelpService,
    private configService: ConfigService,
  ) {

    this.document.body.setAttribute('color-scheme', 'light')

    this.workerService.checkForUpdates();
    this.router.events
      .pipe(map(m => {
        if (m instanceof NavigationStart) {
          this.prevUrl = this.router.url.split('?')[0];
        }
        if ((m instanceof ChildActivationStart) || (m instanceof ActivationStart && m.snapshot.data.hasOwnProperty('helpItem'))) {
          this.helpService.helpId = m.snapshot.data && m.snapshot.data.helpItem ? m.snapshot.data.helpItem : 0;
          if (this.helpService.helpItems) {
            const payload = this.helpService.helpItems?.find(item => item.id == this.helpService.helpId);
            this.helpService.selectHelp(payload);
          }
        }
        return m;
      }))
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map((m) => {
        const url = (m as NavigationEnd).urlAfterRedirects;
        this.currentUrl = url.split('?')[0];
        return m;
      }))
      .pipe(
        map(() => this.activatedRoute),
        map(route => {
          const allComponents: ActivatedRouteSnapshot[] = [];
          let routeParams = {...route.snapshot.queryParams};
          const sameUrl = this.prevUrl === this.currentUrl;
          if (routeParams.hasOwnProperty('needCount') && !sameUrl) {
            delete routeParams.needCount;
            this.router.navigate([], {
              relativeTo: this.activatedRoute,
              queryParams: routeParams,
            }).then();
          }

          while (route) {
            if (!!(route.component) && !!(route.snapshot.data.title)) {
              allComponents.push(route.snapshot);
            }
            (<ActivatedRoute | null>route) = route.firstChild;
          }
          return allComponents;
        }),
        map((res) => {
          return res.map(i => i.data.title);
        })
      )
      .subscribe(res => {
        const title = res.length > 0 ? `SMS.ir | ${res[res.length - 1]}` : 'SMS.ir';
        this.titleService.setTitle(title);
      });
  }

  ngOnInit() {
    this.resize(window.innerWidth);
    fromEvent(window, 'resize')
      .pipe(debounceTime(300))
      .subscribe((res: any) => {
        this.resize(res.target['innerWidth']);
      });
  }

  resize(width: number) {
    let device: DeviceType;
    if (width <= parseInt(this.configService.deviceWidth.mobile.max, 10)) {
      device = 'mobile';
    } else if (width >= parseInt(this.configService.deviceWidth.tablet.min, 10) &&
      width <= parseInt(this.configService.deviceWidth.tablet.max, 10)) {
      device = 'tablet';
    } else {
      device = 'desktop';
    }
    this.storeService.initSidebarMode(device);
    this.storeService.setDeviceMode(device)
  }
}
