import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

import {ApiService} from "@app/api/services/base/api.service";
import {BaseResponse} from "@app/api/models/base/api.model";
import {
    ICooperationInvitesPayload, ICooperationMessageParameterCreatePayload,
    ICooperationSettlementCreatePayload,
    ICooperationSettlementPayload,
    ICooperationTransactionPayload
} from "@app/api/models/components/cooperation/cooperation-payload.model";
import {PagedDataModel} from "@shared/models/paged-data.model";
import {
    ICooperationInvites,
    ICooperationParameter,
    ICooperationSettlement,
    ICooperationTransaction
} from "@app/api/models/components/cooperation/cooperation.model";


@Injectable()
export class CooperationService {
    constructor(
        private _ApiService: ApiService,
    ) {
    }

    invitees(payload: ICooperationInvitesPayload): Observable<BaseResponse<PagedDataModel<ICooperationInvites>>> {
        const path = 'cooperation/invitees';
        return this._ApiService.get({path, queryParams: payload.params});
    }

    transactions(payload: ICooperationTransactionPayload): Observable<BaseResponse<PagedDataModel<ICooperationTransaction>>> {
        const path = 'cooperation/transactions';
        return this._ApiService.get({path, queryParams: payload.params});
    }

    settlement(payload: ICooperationSettlementPayload): Observable<BaseResponse<ICooperationSettlement>> {
        const path = 'cooperation/settlement';
        return this._ApiService.get({path, queryParams: payload.params});
    }

    createSettlement(payload: ICooperationSettlementCreatePayload): Observable<BaseResponse<boolean>> {
        const path = 'cooperation/settlement';
        return this._ApiService.post({path, body: payload.body});
    }

    parameter(): Observable<BaseResponse<ICooperationParameter>> {
        const path = 'cooperation/parameter';
        return this._ApiService.get({path});
    }

    createMessageParameter(payload: ICooperationMessageParameterCreatePayload): Observable<BaseResponse<boolean>> {
        const path = 'cooperation/message-parameter';
        return this._ApiService.post({path, body: payload.body});
    }

    deleteMessageParameter(paramId: number): Observable<BaseResponse<boolean>> {
        const path = `cooperation/message-parameter/${paramId}`;
        return this._ApiService.delete({path});
    }
}
