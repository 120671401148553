import {Component, OnInit} from '@angular/core';
import {SharedService} from "@shared/services/shared.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-device-confirmation-modal',
  templateUrl: './device-confirmation-modal.component.html',
  styleUrls: ['./device-confirmation-modal.component.scss']
})
export class DeviceConfirmationModalComponent implements OnInit {
  checkboxValue = false

  title = 'به پیامک سفید خوش آمدید!';
  description =
    `اپلیکیشن پیامک سفید برای شما این امکان را فراهم می‌کند تا به‌راحتی بتوانید پیامک‌های خود را حتی به مشتریانی ارسال کنید که دریافت پیامک‌های تبلیغاتی را روی سیم‌کارت خود مسدود کرده‌اند (لیست سیاه مخابراتی) لطفا به‌منظور پایداری و حفظ کیفیت این سرویس، قوانین و مقررات استفاده از پیامک سفید را با دقت مطالعه نمائید:

    - ارسال پیامک فقط به مشتریان‌ شما و افرادی که اجازه ارسال پیامک را از آن‌ها دریافت کرده‌ باشید امکان‌پذیر است.

    - مسئولیت ارسال پیامک از اپلیکیشن، برعهده مالک پنل کاربری می‌باشد.

    - محتوای پیامک نباید شامل تبلیغ و معرفی کالاها و خدماتی باشد که طبق قوانین کشور ممنوع اعلام شده است.
    `;

  constructor(public sharedService: SharedService,
              public ngbActiveModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.ngbActiveModal.close(true);
  }

}
