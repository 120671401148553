import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {catchError, finalize, map} from "rxjs/operators";
import {HttpErrorHandlerService} from "@app/api/services/base/http-error-handler.service";
import {HttpResponseHandlerService} from "@app/api/services/base/http-response-handler.service";
import {TokenService} from "@app/api/services/base/token.service";
import {LoadingService} from "@shared/services/loading.service";
import {DemoHandler} from "@shared/services/demo-handler.service";


@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {

  constructor(
    private _TokenService: TokenService,
    private _DemoHandler: DemoHandler,
    private _HttpResponseHandlerService: HttpResponseHandlerService,
    private _HandleErrorService: HttpErrorHandlerService,
    private _LoadingService: LoadingService,
  ) {
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any> | any> {
    if (req.method !== 'GET' && this._TokenService.getAccessToken() &&  this._DemoHandler.isDemo(false)) {
      return of();
    }

    let request = this._TokenService.getAccessToken() ? req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + this._TokenService.getAccessToken())
    }) : req;

    return next.handle(request)
      .pipe(
        finalize(() => {
          this._LoadingService.setButtonLoading(false);
        }),
        map((event: HttpEvent<any>) => this._HttpResponseHandlerService.handleHttpResponse(event, req)),
        catchError((err: HttpErrorResponse) => {
          return this._HandleErrorService.handle(err, request, next);
        }),
      );
  }

}
