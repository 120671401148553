import { Injectable } from '@angular/core';
import {ApiService} from "@shared/services/api.service";
import { Observable } from 'rxjs';
import {ToastTypeEnum} from "@shared/enums/toast-type.enum";


export interface EnhanceTextRequest {
  text: string;
}

export interface EmojifyTextRequest {
  text: string;
}
export interface EnhanceTextResponse {
  result: string;
  success: boolean;
  errorCode: string | null;
}


export interface EmojifyTextResponse {
  result: string;
  success: boolean;
  errorCode: string | null;
}

@Injectable({
  providedIn: 'root'
})

export class xmaService {



  constructor(private apiService: ApiService) {
  }


  enhanceText(payload: EnhanceTextRequest): Observable<EnhanceTextResponse> {

    const url = `xma/text-enhance/`;

    return this.apiService.post(url, payload);
  }

  emojifyText(payload: EmojifyTextRequest): Observable<EmojifyTextResponse> {

    const url = `xma/text-emojify/`;

    return this.apiService.post(url, payload);
  }


}
