import { Injectable } from '@angular/core';
import {ApiService} from "@app/api/services/base/api.service";
import {Observable} from "rxjs";

import {BaseResponse} from "@app/api/models/base/api.model";
import {
  ICompleteInformationPayload
} from "@app/api/models/components/complete-information/complete-information-payload.model";


@Injectable()
export class CompleteInformationService {

  constructor(
    private _ApiService: ApiService,
  ) {
  }

  completionRequirement(): Observable<BaseResponse<number>> {
    const path = 'user/completion-requirement';
    return this._ApiService.get({path});
  }

  updateCompletionRequirement(payload: ICompleteInformationPayload): Observable<BaseResponse<any>> {
    const path = 'user/completion-requirement';
    return this._ApiService.put({path, body: payload.body});
  }

}
