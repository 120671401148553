import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

import {BaseResponse} from "@app/api/models/base/api.model";
import {ApiService} from "@app/api/services/base/api.service";
import {
  ITicketCreatePayload,
  ITicketPayload,
  ITicketReplyPayload
} from "@app/api/models/components/ticket/ticket-payload.model";
import {PagedDataModel} from "@shared/models/paged-data.model";
import {ITicket, ITicketDetail} from "@app/api/models/components/ticket/ticket.model";


@Injectable()
export class TicketService {

  constructor(
    private _ApiService: ApiService,
  ) {
  }

  createTicket(payload: ITicketCreatePayload): Observable<BaseResponse<number>> {
    const path = `ticket`;
    return this._ApiService.postFile({path, body: payload.body});
  }

  tickets(payload: ITicketPayload): Observable<BaseResponse<PagedDataModel<ITicket>>>{
    const path = `ticket`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  ticketDetail(ticketId: number): Observable<BaseResponse<ITicketDetail<string>>>{
    const path = `ticket/${ticketId}`;
    return this._ApiService.get({path});
  }

  reply(payload: ITicketReplyPayload): Observable<BaseResponse<any>>{
    const path = `ticket/${payload.TicketId}/reply`;
    return this._ApiService.postFile({path, body: payload.body});
  }

  close(ticketId: number | string): Observable<BaseResponse<any>>{
    const path = `ticket/${ticketId}`;
    return this._ApiService.patch({path, body: null});
  }
}
