import {Injectable} from '@angular/core';
import {ConfigService} from "./config.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

import {ModifyTextMessageModalComponent} from "@app/main/modules/report/components/bank-number-report/modify-text-message-modal/modify-text-message-modal.component";
import {TermsAndConditionsComponent} from "../components/terms-and-conditions/terms-and-conditions.component";
import {CriticsSuggestionsComponent} from "../components/critics-suggestions/critics-suggestions.component";
import {ShowDataModalInterface} from "../components/show-data-modal/models/show-data-modal.interface";
import {ShowDataModalComponent} from "../components/show-data-modal/show-data-modal.component";
import {ConfirmModalComponent} from "../components/confirm-modal/confirm-modal.component";
import {OneInputModalComponent} from "../components/one-input-modal/one-input-modal.component";
import {DeleteModalComponent} from "../components/delete-modal/delete-modal.component";
import {ActiveLineComponent} from "@shared/components/active-line/active-line.component";
import {TemplateConfirmationModalComponent} from "@shared/components/template-confirmation-modal/template-confirmation-modal.component";
import {
  BootstrapperNotifyModalComponent
} from "@shared/components/bootstrapper-notify-modal/bootstrapper-notify-modal.component";
import {
  ShowDataWithConfirmModalComponent
} from "@shared/components/show-data-with-confirm-modal/show-data-with-confirm-modal.component";
import {
  DeviceConfirmationModalComponent
} from "@shared/components/device-confirmation-modal/device-confirmation-modal.component";

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private ngbModal: NgbModal,
              private configService: ConfigService) {
  }

  async oneInput(initialValue: string, text: string, header: string, modalConfirmButton?: string, modalCancelButton?: string, svg?: string,
                 inputConf?: { placeholder?: string, maxLength?: number, minLength?: null, type?: string }): Promise<string> {
    const oneInputModal = this.ngbModal.open(OneInputModalComponent, this.configService.modalOptions());
    oneInputModal.componentInstance.initialValue = initialValue;
    oneInputModal.componentInstance.modalText = text;
    oneInputModal.componentInstance.modalHeader = header;
    oneInputModal.componentInstance.modalConfirmButton = modalConfirmButton;
    oneInputModal.componentInstance.modalCancelButton = modalCancelButton;
    oneInputModal.componentInstance.inputConf = inputConf;
    oneInputModal.componentInstance.svg = svg;
    return oneInputModal.result;
  }

  async delete(text: string, header: string, modalConfirmButton?: string, modalCancelButton?: string, svg?: string): Promise<any> {
    const deleteModal = this.ngbModal.open(DeleteModalComponent, this.configService.modalOptions());
    deleteModal.componentInstance.modalText = text;
    deleteModal.componentInstance.modalHeader = header;
    deleteModal.componentInstance.modalConfirmButton = modalConfirmButton;
    deleteModal.componentInstance.modalCancelButton = modalCancelButton;
    deleteModal.componentInstance.svg = svg;
    return deleteModal.result;
  }

  async confirm(text: string, header: string, modalConfirmButton?: string, modalCancelButton?: string, svg?: string): Promise<any> {
    const confirmModal = this.ngbModal.open(ConfirmModalComponent, this.configService.modalOptions());
    confirmModal.componentInstance.modalText = text;
    confirmModal.componentInstance.modalHeader = header;
    confirmModal.componentInstance.modalConfirmButton = modalConfirmButton;
    confirmModal.componentInstance.modalCancelButton = modalCancelButton;
    confirmModal.componentInstance.svg = svg;
    return confirmModal.result;
  }

  async showData(text: string, header: string, data: ShowDataModalInterface, svg?: string) {
    const showDataModal = this.ngbModal.open(ShowDataModalComponent, this.configService.modalOptions());
    showDataModal.componentInstance.data = data;
    showDataModal.componentInstance.modalText = text;
    showDataModal.componentInstance.modalHeader = header;
    showDataModal.componentInstance.svg = svg;
  }


  async showDataWithConfirm(text: string, header: string, data: ShowDataModalInterface, svg?: string, hasSecondButton?: boolean, textSecondButton?:string, classNameSecondButton?:string ) {
    const showDataModal = this.ngbModal.open(ShowDataWithConfirmModalComponent, this.configService.modalOptions());
    showDataModal.componentInstance.data = data;
    showDataModal.componentInstance.modalText = text;
    showDataModal.componentInstance.modalHeader = header;
    showDataModal.componentInstance.svg = svg;
    showDataModal.componentInstance.hasSecondButton = hasSecondButton;
    showDataModal.componentInstance.textSecondButton = textSecondButton;
    showDataModal.componentInstance.classNameSecondButton = classNameSecondButton;
    return showDataModal.result
  }

   async activeLinesModal(lineNumber?: number): Promise<any>  {
    const showDataModal = this.ngbModal.open(ActiveLineComponent, this.configService.modalOptions());
    showDataModal.componentInstance.modalHeader = 'فعال سازی خط';
     showDataModal.componentInstance.lineNumber = lineNumber;
  }

  async TermCondition() {
    const showDataModal = this.ngbModal.open(TermsAndConditionsComponent, this.configService.modalOptions('lg', true,true));
    showDataModal.componentInstance.modalHeader = 'شرایط و قوانین';
  }

  async BootstrapperNotify(messages: { title: string, id: number , showAtEntry: boolean}[]) {
    const bootstrapperNotifyModal = this.ngbModal.open(BootstrapperNotifyModalComponent, this.configService.modalOptions('lg', false,true));
    bootstrapperNotifyModal.componentInstance.modalHeader = 'اعلانات مهم';
    bootstrapperNotifyModal.componentInstance.messages = messages;
    return bootstrapperNotifyModal.result;
  }

  async CriticsSuggestions() {
    const showDataModal = this.ngbModal.open(CriticsSuggestionsComponent, this.configService.modalOptions('lg', true,true));
    showDataModal.componentInstance.modalHeader = 'پیشنهادات و انتقادات';
  }

  async modifyBankNumberTextMessage(text: string): Promise<string> {
    const modifyModal = this.ngbModal.open(ModifyTextMessageModalComponent, this.configService.modalOptions());
    modifyModal.componentInstance.defaultText = text;
    return modifyModal.result;
  }

  async templateConfirmation(modalConfirmButton?: string, modalCancelButton?: string): Promise<any> {
    const confirmModal = this.ngbModal.open(TemplateConfirmationModalComponent, this.configService.modalOptions());
    confirmModal.componentInstance.modalConfirmButton = modalConfirmButton;
    confirmModal.componentInstance.modalCancelButton = modalCancelButton;
    return confirmModal.result;
  }

  deviceConfirmation(): Promise<any> {
    const confirmModal = this.ngbModal.open(DeviceConfirmationModalComponent, this.configService.modalOptions('lg', 'static'));
    return confirmModal.result;
  }

}
