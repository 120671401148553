import {Injectable} from '@angular/core';
import {ApiService} from "@app/api/services/base/api.service";
import {Observable} from "rxjs";
import {BaseResponse} from "@app/api/models/base/api.model";

import {IXmaTextPayload} from "@app/api/models/components/xma/xma-payload.model";

@Injectable()
export class XmaService {

    constructor(
        private _ApiService: ApiService,
    ) {
    }

    textEnhance(payload: IXmaTextPayload): Observable<BaseResponse<string>> {
        const path = 'xma/text-enhance';
        return this._ApiService.post({path, body: payload.body})
    }

    textEmojify(payload: IXmaTextPayload): Observable<BaseResponse<string>> {
        const path = 'xma/text-emojify';
        return this._ApiService.post({path, body: payload.body})
    }
}
