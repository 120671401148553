import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from "rxjs";
import {map} from "rxjs/operators";

import {DeviceType} from "../models/device-type";
import {SidebarModeType} from "../models/sidebar-mode";
import {HelpModeType} from "@app/extra-components/help/models/help-mode.type";
import {IPanelPrefix} from "../models/prefix.model";
import {IPanelBootstrapper} from "@app/api/models/components/panel/panel.model";
import {IPlanUserData} from "@app/api/models/components/plan/plan.model";
import {IUser} from "@app/api/models/components/user/user.model";


@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor() {
  }

  private prefix: BehaviorSubject<IPanelPrefix | null> = new BehaviorSubject<IPanelPrefix | null>(null);
  prefix$: Observable<IPanelPrefix | null> = this.prefix.asObservable();

  private token: ReplaySubject<string> = new ReplaySubject<string>(1)
  token$: Observable<string> = this.token.asObservable();

  private userInfo: BehaviorSubject<IUser | null> = new BehaviorSubject<IUser | null>(null)
  userInfo$: Observable<IUser | null> = this.userInfo.asObservable();

  private deviceMode: ReplaySubject<DeviceType> = new ReplaySubject<DeviceType>(1)
  deviceMode$: Observable<DeviceType> = this.deviceMode.asObservable();

  private sidebarMode: ReplaySubject<SidebarModeType> = new ReplaySubject<SidebarModeType>(1)
  sidebarMode$: Observable<SidebarModeType> = this.sidebarMode.asObservable();

  private helpMode: BehaviorSubject<HelpModeType> = new BehaviorSubject<HelpModeType>('closed')
  helpMode$: Observable<HelpModeType> = this.helpMode.asObservable();

  private baseData: BehaviorSubject<IPanelBootstrapper | null> = new BehaviorSubject<IPanelBootstrapper | null>(null)
  baseData$: Observable<IPanelBootstrapper | null> = this.baseData.asObservable();



  setBaseData(payload: IPanelBootstrapper) {
    this.baseData.next(payload)
  }

  getBaseData(): IPanelBootstrapper | null {
    return this.baseData.getValue();
  }

  setHelpMode(payload: HelpModeType) {
    this.helpMode.next(payload)
  }

  setDeviceMode(payload: DeviceType) {
    this.deviceMode.next(payload)
  }

  setPrefix(payload: IPanelPrefix) {
    this.prefix.next(payload)
  }


  initSidebarMode(deviceMode: DeviceType) {
    switch (deviceMode) {
      case 'mobile':
        this.sidebarMode.next('hidden');
        break;
      case 'tablet':
        this.sidebarMode.next('hidden');
        break;
      case 'desktop':
        this.sidebarMode.next('default');
        break;
      default:
        this.sidebarMode.next('default');
    }
  }

  modifySidebar(mode: SidebarModeType) {
    this.sidebarMode.next(mode);
  }

  getPrefixes(operators?: string[]): Observable<string[]> {
    return this.prefix$.pipe(
      map(res => {
        if(!res) return [];
        if (operators) {
          const prefixesDic: { [key: string]: any } = res;
          return [].concat(...operators.map(key => prefixesDic[key]));
        } else {
          return [].concat(...Object.values(res));
        }
      })
    )
  }

  confirmAlert(alertId: number) {
    let data = this.baseData.getValue();
    if (data) {
      data = {
        ...data,
        userData: {
          ...data.userData,
          confirmation: (data.userData.confirmation - Number(alertId))
        }
      }
    }
    this.baseData.next(data)
  }

  getUserInfoSuccess(payload: IUser) {
    let userInfo = this.userInfo.getValue();
    userInfo = {
      ...userInfo,
      ...payload
    }
    this.userInfo.next(userInfo);
  }

  avatarUpdatedSuccess(newUrl: string | null) {
    let baseData = this.baseData.getValue();
    let userData = this.userInfo.getValue();
    if (baseData) {
      baseData = {
        ...baseData,
        userData: {
          ...baseData.userData,
          profileImageURL: newUrl
        }
      }
    }
    if (userData) {
      userData = {
        ...userData,
        profileImageURL: newUrl
      }
    }
    this.baseData.next(baseData);
    this.userInfo.next(userData);
  }

  emailUpdatedSuccess(payload: any) {
    let userData = this.userInfo.getValue();
    if (userData) {
      userData = {
        ...userData,
        email: payload.email
      }
    }
    this.userInfo.next(userData);
  }

  setPlanData(plan: IPlanUserData) {
    let data = this.baseData.getValue();
    if (data) {
      data = {
        ...data,
        userPlanData: {...plan},
      }
    }
    this.baseData.next(data)
  }
  getPlanExpireAt() {
    let data = this.baseData.getValue();
    return data?.userPlanData?.expireAt;
  }

  getPlanCreatedAt() {
    let data = this.baseData.getValue();
    return data?.userPlanData?.createdAt;
  }
}
