import { Injectable } from '@angular/core';
import {ApiService} from "@app/api/services/base/api.service";
import {Observable} from "rxjs";
import {BaseResponse} from "@app/api/models/base/api.model";
import {IPlanPackage} from "@app/api/models/components/plan/plan.model";
import {
  ICreditTransactionPayload,
  IPaymentCreditManualPayload,
  IPaymentCreditOnlinePayload, IPaymentInvoiceInfoPayload, IPaymentInvoicePayload,
  IPaymentLineManualPayload,
  IPaymentLineOnlinePayload,
  IPaymentOfficialManualPayload,
  IPaymentOfficialOnlinePayload,
  IPaymentPackageManualPayload,
  IPaymentPackageOnlinePayload, IPaymentPayload,
  IPaymentPlanPackageManualPayload,
  IPaymentPlanPackageOnlinePayload, IPaymentPreviewPayload,
  IPaymentProformaInvoiceManualPayload,
  IPaymentProformaInvoiceOnlinePayload, IPaymentProformaPreviewPayload,
  IPaymentTowerManualPayload,
  IPaymentTowerOnlinePayload
} from "@app/api/models/components/payment/payment-payload.model";
import {GatewayTransporter} from "@app/main/modules/payment-preview/models/gateway-transporter";
import {
  ICreditTransaction,
  IPayment,
  IPaymentInfo, IPaymentInvoice, IPaymentInvoiceInfo,
  IPaymentPlanPreview,
  IPaymentPreview
} from "@app/api/models/components/payment/payment.model";
import moment from "jalali-moment";
import Base = moment.unitOfTime.Base;
import {PagedDataModel} from "@shared/models/paged-data.model";

@Injectable()
export class PaymentService {

  constructor(
    private _ApiService: ApiService,
  ) {
  }

  packageOnline(payload: IPaymentPackageOnlinePayload): Observable<BaseResponse<GatewayTransporter>> {
    const path = `payment/package/${payload.packageId}/online`;
    return this._ApiService.post({path, body: payload.body, queryParams: payload.params});
  }

  packageManual(payload: IPaymentPackageManualPayload): Observable<BaseResponse<any>> {
    const path = `payment/package/${payload.packageId}/manual`;
    return this._ApiService.postFile({path, body: payload.body});
  }

  creditOnline(payload: IPaymentCreditOnlinePayload): Observable<BaseResponse<GatewayTransporter>> {
    const path = `payment/credit/online`;
    return this._ApiService.post({path, body: payload.body, queryParams: payload.params});
  }

  creditManual(payload: IPaymentCreditManualPayload): Observable<BaseResponse<any>> {
    const path = `payment/credit/manual`;
    return this._ApiService.postFile({path, body: payload.body});
  }

  planPackageOnline(payload: IPaymentPlanPackageOnlinePayload): Observable<BaseResponse<GatewayTransporter>>{
    const path = `payment/plan/package/${payload.planPackageId}/online`;
    return this._ApiService.post({path, body: payload.body});
  }

  planPackageManual(payload: IPaymentPlanPackageManualPayload): Observable<BaseResponse<any>> {
    const path = `payment/plan/package/${payload.planPackageId}/manual`;
    return this._ApiService.postFile({path, body: payload.body});
  }

  proformaInvoiceOnline(payload: IPaymentProformaInvoiceOnlinePayload): Observable<BaseResponse<GatewayTransporter>>{
    const path = `payment/proformaInvoice/${payload.proformaId}/online`;
    return this._ApiService.post({path, body: payload.body});
  }

  proformaInvoiceManual(payload: IPaymentProformaInvoiceManualPayload): Observable<BaseResponse<any>>{
    const path = `payment/proformaInvoice/${payload.proformaId}/manual`;
    return this._ApiService.postFile({path, body: payload.body});
  }

  lineOnline(payload: IPaymentLineOnlinePayload): Observable<BaseResponse<GatewayTransporter>>{
    const path = `payment/line/${payload.lineId}/online`;
    return this._ApiService.post({path, body: payload.body, queryParams: payload.params});
  }

  lineManual(payload: IPaymentLineManualPayload): Observable<BaseResponse<any>>{
    const path = `payment/line/${payload.lineId}/manual`;
    return this._ApiService.postFile({path, body: payload.body});
  }

  towerOnline(payload: IPaymentTowerOnlinePayload): Observable<BaseResponse<GatewayTransporter>>{
    const path = `payment/send-via-tower/${payload.towerId}/online`;
    return this._ApiService.post({path, body: payload.body, queryParams: payload.params});
  }

  towerManual(payload: IPaymentTowerManualPayload): Observable<BaseResponse<boolean>>{
    const path = `payment/send-via-tower/${payload.towerId}/manual`;
    return this._ApiService.postFile({path, body: payload.body});
  }

  officialOnline(payload: IPaymentOfficialOnlinePayload): Observable<BaseResponse<GatewayTransporter>>{
    const path = `payment/${payload.requestId}/official/online`;
    return this._ApiService.post({path, body: payload.body});
  }

  officialManual(payload: IPaymentOfficialManualPayload): Observable<BaseResponse<any>>{
    const path = `payment/${payload.requestId}/official/manual`;
    return this._ApiService.postFile({path, body: payload.body});
  }

  packagePreview(payload: IPaymentPreviewPayload): Observable<BaseResponse<IPaymentPreview[]>>{
    const path = `payment/package/${payload.ID}/preview`;
    return this._ApiService.get({path});
  }

  creditPreview(payload: IPaymentPreviewPayload): Observable<BaseResponse<IPaymentPreview[]>>{
    const path = `payment/credit/preview`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  linePreview(payload: IPaymentPreviewPayload): Observable<BaseResponse<IPaymentPreview[]>>{
    const path = `payment/line/${payload.ID}/preview`;
    return this._ApiService.get({path});
  }

  towerPreview(payload: IPaymentPreviewPayload): Observable<BaseResponse<IPaymentPreview[]>>{
    const path = `payment/send-via-tower/${payload.ID}/preview`;
    return this._ApiService.get({path});
  }

  officialPreview(payload: IPaymentPreviewPayload): Observable<BaseResponse<IPaymentPreview[]>>{
    const path = `payment/${payload.ID}/official/preview`;
    return this._ApiService.get({path});
  }

  proformaPreview(payload: IPaymentProformaPreviewPayload): Observable<BaseResponse<IPaymentPreview[]>>{
    const path = `payment/proforma/preview`;
    return this._ApiService.post({path, body: payload.body});
  }

  planPackagePreview(payload: IPaymentPreviewPayload): Observable<BaseResponse<IPaymentPlanPreview>>{
    const path = `payment/plan/package/${payload.ID}/preview`;
    return this._ApiService.get({path});
  }

  payments(payload: IPaymentPayload): Observable<BaseResponse<PagedDataModel<IPayment>>>{
    const path = `payment`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  paymentInfo(paymentId: number): Observable<BaseResponse<IPaymentInfo>>{
    const path = `payment/${paymentId}`;
    return this._ApiService.get({path});
  }

  invoices(payload: IPaymentInvoicePayload): Observable<BaseResponse<PagedDataModel<IPaymentInvoice>>>{
    const path = `invoice`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  invoiceInfo(payload: IPaymentInvoiceInfoPayload): Observable<BaseResponse<IPaymentInvoiceInfo>>{
    const path = `invoice/${payload.invoiceId}`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  creditTransaction(payload: ICreditTransactionPayload): Observable<BaseResponse<PagedDataModel<ICreditTransaction>>>{
    const path = `credit/transaction`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  invoiceInfoPdf(invoiceId: number): Observable<BaseResponse<any>>{
    const path = `invoice/${invoiceId}/pdf`;
    return this._ApiService.get({path});
  }
}
