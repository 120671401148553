import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfigService} from '@shared/services/config.service';
import {ContactPreviewComponent} from '../components/contact-preview/contact-preview.component';
import {OperatorPreviewComponent} from '../components/operator-preview/operator-preview.component';
import {FilterItemModel} from '@shared/components/table/models/filter-item.model';
import {SendTowerState} from '@app/main/modules/send-message/enums/send-tower-state';
import {SendTowerOperator} from "@app/main/modules/send-message/enums/send-tower-operator";
import {ISimpleSendSinglePreview} from "@app/api/models/components/simple-send/simple-send.model";


@Injectable({
    providedIn: 'root'
})
export class SendMessageService {

    private TowerStatusList: FilterItemModel[] = [
        {name: 'در حال بررسی', value: SendTowerState.Pending},
        {name: 'در انتظار پرداخت', value: SendTowerState.Accepted},
        {name: 'عدم تایید', value: SendTowerState.Rejected},
        {name: 'پرداخت شده', value: SendTowerState.Paid},
        {name: 'آماده ارسال', value: SendTowerState.Ready},
        {name: 'در حال بررسی فیش', value: SendTowerState.ManualPaymentReview},
        {name: 'ارسال شده', value: SendTowerState.Finished},
        {name: 'منقضی شده', value: SendTowerState.Expired},
    ];

    private TowerOperatorList: FilterItemModel[] = [
        {name: 'ایرانسل', value: SendTowerOperator.Irancell},
        {name: 'همراه اول', value: SendTowerOperator.Hamraheaval},
    ];

    get towerStatusList() {
        return this.TowerStatusList;
    }

    get towerOperatorList() {
        return this.TowerOperatorList;
    }

    constructor(
        private ngbModal: NgbModal,
        private configService: ConfigService) {
    }

    showOperatorsPreview(data: any, needConfirm?: boolean) {
        const modalRef = this.ngbModal.open(OperatorPreviewComponent, this.configService.modalOptions());
        modalRef.componentInstance.data = data;
    }

    showContactsPreview(data: ISimpleSendSinglePreview, needConfirm?: boolean) {
        const modalRef = this.ngbModal.open(ContactPreviewComponent, this.configService.modalOptions());
        modalRef.componentInstance.data = data;
        modalRef.componentInstance.needConfirm = needConfirm;
        if (needConfirm) {
            return modalRef.result;
        } else {
            return null;
        }
    }
}
