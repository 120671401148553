import {Injectable} from '@angular/core';
import jwtDecode from "jwt-decode";

import {CookieService} from "ngx-cookie-service";
import {JwtModel} from "@app/api/models/base/jwt.model";


@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private _ACCESS_TOKEN = 'access_token';
  private _REFRESH_TOKEN = 'refresh_token';


  constructor(
    private _CookieService: CookieService,
  ) {
  }


  getAccessToken() {
    return this._CookieService.get(this._ACCESS_TOKEN);
  };


  getRefreshToken() {
    return this._CookieService.get(this._REFRESH_TOKEN);
  };


  setToken(accessToken: string, refreshToken: string) {
    this.setAccessToken(accessToken);
    this.setRefreshToken(refreshToken);
  }


  setAccessToken(accessToken: string) {
    this._CookieService.set(this._ACCESS_TOKEN, accessToken, {path: '/'});
  };


  setRefreshToken(refreshToken: string) {
    this._CookieService.set(this._REFRESH_TOKEN, refreshToken, {path: '/'});
  };


  removeAll() {
    this._CookieService.deleteAll('/');
    this._CookieService.deleteAll('/', '.sms.ir');
  }


  logOut() {
    this.removeAll()
    window.location.href = 'https://sms.ir/';
  }


  decodeToken(): JwtModel{
    const token = this.getAccessToken();
    return jwtDecode(token);
  }
}
