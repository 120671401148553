export enum ProvidersEnum {
    Magfa = 1,
    Rahyab = 2,
    Asanak = 3,
    Armaqan = 4,
    AsiaTech = 5,
    Noavaran = 6,
    Jiring = 7,
    Shatel = 8,
    Ariantel = 9,
}
