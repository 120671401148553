export enum ErrorCode {
  ContactExistsInThisGroup = 1,
  DuplicateTitleOrName = 2,
  FileIsInvalid = 3,
  NotAllowedContactsCount = 4,
  CacheKeyNotFound = 5,
  ContactAlreadyExists = 6,
  MobileUsedByAnotherUser = 7,
  UserNotFound = 8,
  UserIsSuspended = 9,
  MobileVerificationCodeIsWrong = 10,
  UserApiKeyLimitationCount = 11,
  AlreadyReturnInvoiceIssued = 12,
  UserCouldNotHasOfficialInvoice = 13,
  PasswordIsWrong = 14,
  UserDeactivationAllowed24Hours = 15,
  DuplicateEmailAddress = 16,
  DuplicateUserName = 17,
  DuplicateInvoiceNumber = 18,
  DiscountCodeIsWrong = 19,
  DiscountCodeHasExpired = 20,
  DiscountCodeUsageLimitationReached = 21,
  UserLinePrebuyExists = 22,
  DuplicatePlanLineNumber = 23,
  DuplicateUserLineNumber = 24,
  CantModifyStartedABTest = 25,
  ItemCountLimitation = 26,
  EventIsRelatedToTheCampaign = 27,
  ContactGroupUsedInCampaign = 28,
  EmailVerificationAllowedPer2Minutes = 29,
  LineUsedOnAnotherModule = 30,
  SmsSecretaryBehaviorLimitationCount = 31,
  DuplicateParameter = 32,
  CreditNotEnough = 33,
  KeyWordIsDuplicated = 34,
  TestFastSendLimitationCount = 35,
  RetryOperation = 36,
  OverlapInRanges = 37,
  UsableSystemLine = 38,
  SystemLineExist = 39,
  DuplicateBlockedIP = 40,
  TempRefCodeIsExpired = 41,
  ParameterUsedInBlackListKeywords = 42,
  CooperationMessageParameterLimitationCount = 43,
  ParameterUsedOnAnotherModule = 44,
  UserHasOpenRequest = 45,
  RegistrationThroughTempCodeHasAlreadyBeenDone = 46,
  SepidarRegisterCustomerFailed = 47,
  SepidarRegisterInvoiceFailed = 48,
  SepidarRegisterReceiptFailed = 49,
  AddValidIPForTwoStepLoginLimitationCount = 50,
  AddValidMobileToSendVerificationCodeLimitationCount = 51,
  UsersMobileCannotBeInTheWhiteList = 52,
  UserHaveAnyManualPaymentWaitingToConfirmAdmin = 53,
  DefaultMessageInCodeReaderIsRequired = 54,
  UserMustModifyUnderSupervisionFinancialDepartment = 55,
  DowngradePlanIsNotPermitted = 56,
  AlreadyHasNotExpiredPlan = 57,
  PlanItemAccessibility = 58,
  PlanItemLimitationCountUserTemplate = 59,
  PlanItemLimitationCountBlackListInquiryCount = 60,
  PlanItemLimitationCountFastSend = 61,
  PlanItemLimitationApiKey = 62,
  PlanItemLimitationSmsTransmissionApiWithParameter = 63,
  PlanItemLimitationSmsTransmissionEmailWithParameter = 64,
  PlanItemLimitationSmsTransmissionMobileWithParameter = 65,
  PlanItemLimitationSmsSecretaryWithParameter = 66,
  PlanItemLimitationCodeReaderParameters = 67,
  PlanItemLimitationContacts = 68,
  PlanItemLimitationContactGroups = 69,
  PlanItemLimitationContactEvents = 70,
  PreviousDaysHasPendingInvoice = 72,
  MobileIsAlreadyBlocked = 74,
  PaymentLimitations = 75,
  ABTestSelectedContactLessThanMin = 76,
  CantModifyMessageTextOfHandledScheduledCampaign = 77,
  SendSmsViaTowerRequestIsExpired = 78,
  SendSmsViaTowerRequestIsUnchangeable = 79,
  UserInvoiceIsAlreadyCancelled = 80,
  NotSupportedSepidarLocation = 81,
  NotSupportedSepidarBankType = 82,
  PublicLineIsNotActive = 83,
  UserDoesNotHaveAccessToSpecialLines = 84,
  PublicLineSendLimitationCount = 85,
  DuplicateAdvertisingCampaignName = 86,
  DuplicateAdvertisingCampaignCode = 87,
  SendSmsTypeDeactivated = 88,
  InternalOperationsLineMustBeUnique = 89,
  SystemLineTypesMustBeTaken = 90,
  SystemLineHasTypeMustBeActive = 91,
  UserTagNotFound = 92,
  UserTagsAreNotOfTheSameType = 93,
  UserTagNameDuplicated = 94,
  LegalDataIncompleteForRegisterInSepidar = 95,
  CustomerNationalIdForRegisterInSepidarEmpty = 96,
  HasConflictCustomerNameInSepidar = 97,
  CustomerHasSameNameExistInSepidar = 98,
  PaymentGatewayUnreachable = 99,
  DiscountCodeCouldNotAffectTheCreditTier = 100,
  UserHasUnfinishedRequest = 101,
  FAQServiceUnreachable = 102,
  FAQQuestionDuplicated = 103,
  FAQCategoryTitleDuplicated = 104,
  CouldNotConnectToSepidar = 107,
  PlanIsNotAvailable = 108,
  PlanAccessibility = 109,
  DailyCreditTransactionLimitExceeded = 110,
  ManagerPendingSendMessageCodeIsNotValid = 111,
  ManagerPendingSendMessageCodeIsAlreadySent = 112,
  CouldNotConnectToReportService = 113,
  ReportRequestTimeout = 114,
  NumberOfRecordsHasExceededTheLimit = 115,
  ReportServiceTerminated = 116,
  PayamakSefidServiceConnectionFailed = 117,
  UserHasSamePayamakSefidDeviceName = 118,
  PayamakSefidDeviceIsAlreadyConnected = 119,
  PayamakSefidContactGroupIsInUseByDevices = 120,
  DeviceContactGroupExists = 121,
  CannotRemoveDefaultContactGroup = 122,
  XMAServiceConnectionFailed = 123,
  XMAPlanItemLimitation = 124,
  PayamakSefidContactGroupHasContacts = 125,
  PayamakSefidPlanContactsCountLimitation = 126,
  MessageCountExceeded = 127,
}
