import {Injectable} from '@angular/core';
import {ApiService} from "@app/api/services/base/api.service";
import {Observable} from "rxjs";
import {BaseResponse} from "@app/api/models/base/api.model";
import {
  IABTestCampaignAnalysisPayload,
  IABTestCampaignConfirmPayload,
  IABTestCampaignFilterMobilePayload, IABTestCampaignItemModifyPayload,
  IABTestCampaignModifyPayload,
  IABTestCampaignPreviewPayload,
  IEventCampaignConfirmPayload,
  IEventCampaignModifyPayload,
  IEventCampaignPreviewPayload,
  IScheduledCampaignConfirmPayload,
  IScheduledCampaignModifyPayload,
  IScheduledCampaignPreviewPayload,
} from "@app/api/models/components/campaign-send/campaign-send-payload.model";
import {
  IABTestCampaign, IABTestCampaignAnalysis, IABTestCampaignMobileStatistic,
  IABTestCampaignPreview,
  IEventCampaign,
  IEventCampaignPreview,
  IScheduledCampaign,
  IScheduledCampaignPreview,
} from "@app/api/models/components/campaign-send/campaign-send.model";
import {PagedDataModel} from "@shared/models/paged-data.model";


@Injectable()
export class CampaignSendService {

  constructor(
    private _ApiService: ApiService,
  ) {
  }


  eventCampaign(campaignId: number): Observable<BaseResponse<IEventCampaign>> {
    const path = `event-campaign/${campaignId}`;
    return this._ApiService.get({path});
  }

  eventCampaignPreview(payload: IEventCampaignPreviewPayload): Observable<BaseResponse<IEventCampaignPreview>> {
    const path = 'event-campaign';
    return this._ApiService.post({path, body: payload.body});
  }

  eventCampaignConfirm(payload: IEventCampaignConfirmPayload): Observable<BaseResponse<boolean>> {
    const path = 'event-campaign';
    return this._ApiService.patch({path, body: payload.body});
  }


  eventCampaignModify(payload: IEventCampaignModifyPayload): Observable<BaseResponse<boolean>> {
    const path = `event-campaign/${payload.campaignId}`;
    return this._ApiService.put({path, body: payload.body});
  }

  scheduledCampaign(campaignId: number): Observable<BaseResponse<IScheduledCampaign>> {
    const path = `scheduled-campaign/${campaignId}`;
    return this._ApiService.get({path});
  }

  scheduledCampaignPreview(payload: IScheduledCampaignPreviewPayload): Observable<BaseResponse<IScheduledCampaignPreview>> {
    const path = `scheduled-campaign`;
    return this._ApiService.post({path, body: payload.body});
  }

  scheduledCampaignConfirm(payload: IScheduledCampaignConfirmPayload): Observable<BaseResponse<boolean>> {
    const path = `scheduled-campaign`;
    return this._ApiService.patch({path, body: payload.body});
  }

  scheduledCampaignModify(payload: IScheduledCampaignModifyPayload): Observable<BaseResponse<boolean>> {
    const path = `scheduled-campaign/${payload.campaignId}`;
    return this._ApiService.put({path, body: payload.body});
  }

  abTestCampaignFilterMobiles(payload: IABTestCampaignFilterMobilePayload): Observable<BaseResponse<number>> {
    const path = `ab-test-campaign/filter-mobiles`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  abTestCampaignPreview(payload: IABTestCampaignPreviewPayload): Observable<BaseResponse<IABTestCampaignPreview>> {
    const path = `ab-test-campaign`;
    return this._ApiService.post({path, body: payload.body});
  }


  abTestCampaignConfirm(payload: IABTestCampaignConfirmPayload): Observable<BaseResponse<boolean>> {
    const path = 'ab-test-campaign';
    return this._ApiService.patch({path, body: payload.body});
  }

  abTestCampaignModify(payload: IABTestCampaignModifyPayload): Observable<BaseResponse<boolean>> {
    const path = `ab-test-campaign/${payload.campaignId}`;
    return this._ApiService.put({path, body: payload.body});
  }

  abTestCampaign(campaignId: number): Observable<BaseResponse<IABTestCampaign>> {
    const path = `ab-test-campaign/${campaignId}`;
    return this._ApiService.get({path});
  }

  deleteABTestCampaign(campaignId: number): Observable<BaseResponse<boolean>> {
    const path = `ab-test-campaign/${campaignId}`;
    return this._ApiService.delete({path});
  }

  deleteEventCampaign(campaignId: number): Observable<BaseResponse<boolean>> {
    const path = `event-campaign/${campaignId}`;
    return this._ApiService.delete({path});
  }

  deleteScheduledCampaign(campaignId: number): Observable<BaseResponse<boolean>> {
    const path = `scheduled-campaign/${campaignId}`;
    return this._ApiService.delete({path});
  }

  abTestCampaignAnalysis(payload: IABTestCampaignAnalysisPayload): Observable<BaseResponse<IABTestCampaignAnalysis>>{
    const path = `ab-test-campaign/${payload.campaignId}/analysis`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  abTestCampaignMobileStatistic(payload: IABTestCampaignAnalysisPayload): Observable<BaseResponse<PagedDataModel<IABTestCampaignMobileStatistic>>>{
    const path = `ab-test-campaign/${payload.campaignId}/statistics/mobiles`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  updateABTestCampaignItem(payload: IABTestCampaignItemModifyPayload): Observable<BaseResponse<boolean>>{
    const path = `ab-test-campaign/${payload.campaignId}/items`;
    return this._ApiService.put({path, body: payload.body});
  }
}
