import {Injectable} from "@angular/core";
import jwtDecode from "jwt-decode";
import {TokenService} from "@app/api/services/base/token.service";
import {ToastifyService} from "@shared/services/toastify.service";


@Injectable({
  providedIn: 'root'
})

export class DemoHandler {
  constructor(
    private _TokenService: TokenService,
    private _ToastifyService: ToastifyService,
  ) {
  }

  isDemo(showMessage: boolean = false): boolean {
    const jwt = this._TokenService.decodeToken();
    if (jwt.IsDemo && showMessage) {
      this._ToastifyService.error('کاربر گرامی، با عرض پوزش، استفاده از این قابلیت در حالت دمو امکان پذیر نمی‌باشد.');
    }
    return jwt.IsDemo;
  }
}
