import { Injectable } from '@angular/core';
import {Observable} from "rxjs";

import {ApiService} from "@app/api/services/base/api.service";
import {BaseResponse} from "@app/api/models/base/api.model";
import {
  IBulkCreateContactsInGroup,
  IContact,
  IContactGroup,
  IContactGroupDetail,
  IContactInGroup, IExcelFirstStepContactsInGroup, ITextCreateContactsInGroup
} from "@app/api/models/components/contacts/contacts.model";
import {PagedDataModel} from "@shared/models/paged-data.model";
import {
  IContactPayload,
  IContactGroupCreatePayload,
  IContactGroupDetailPayload,
  IContactGroupModifyPayload,
  IContactGroupPayload,
  IContactInGroupPayload,
  IContactsInGroupPayload,
  IBulkCreateContactsInGroupPayload,
  IBulkUpdateContactsInGroupPayload,
  ITextCreateContactsInGroupPayload,
  IExcelFirstStepContactsInGroupPayload,
  IExcelSecondStepContactsInGroupPayload, IFileContactsInGroupPayload, ICopyContactsPayload, IMoveContactsPayload
} from "@app/api/models/components/contacts/contacts-payload.model";


@Injectable()
export class ContactsService {

  constructor(
    private _ApiService: ApiService,
  ) {
  }

  contactGroup(payload: IContactGroupPayload): Observable<BaseResponse<PagedDataModel<IContactGroup>>> {
    const path = 'contact-group';
    return this._ApiService.get({path, queryParams: payload.params});
  }

  updateContactGroup(payload: IContactGroupModifyPayload): Observable<BaseResponse<boolean>> {
    const path = `contact-group/${payload.groupID}`;
    return this._ApiService.put({path, body: payload.body});
  }

  deleteContactGroup(groupID: number): Observable<BaseResponse<boolean>>{
    const path = `contact-group/${groupID}`;
    return this._ApiService.delete({path});
  }

  createContactGroup(payload: IContactGroupCreatePayload): Observable<BaseResponse<number>> {
    const path = `contact-group`;
    return this._ApiService.post({path, body: payload.body});
  }

  contactGroupDetail(payload: IContactGroupDetailPayload): Observable<BaseResponse<IContactGroupDetail>> {
    const path = `contact-group/${payload.groupID}`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  contactInGroup(payload: IContactInGroupPayload): Observable<BaseResponse<IContactInGroup>>{
    const path = `contact-group/${payload.groupID}/contact/${payload.contactID}`;
    return this._ApiService.get({path});
  }

  contactsInGroup(payload: IContactsInGroupPayload): Observable<BaseResponse<PagedDataModel<IContact>>>{
    const path = `contact-group/${payload.groupID}/contact`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  createContact(payload: IContactPayload): Observable<BaseResponse<boolean>>{
    const path = `contact-group/${payload.groupID}/contact`;
    return this._ApiService.post({path, body: payload.body});
  }

  updateContact(payload: IContactPayload): Observable<BaseResponse<boolean>>{
    const path = `contact-group/${payload.groupID}/contact/${payload.contactID}`;
    return this._ApiService.put({path, body: payload.body});
  }

  deleteContact(payload: IContactPayload): Observable<BaseResponse<boolean>>{
    const path = `contact-group/${payload.groupID}/contact/${payload.contactID}`;
    return this._ApiService.delete({path});
  }

  deleteContactFromSystem(contactID: number): Observable<BaseResponse<boolean>>{
    const path = `contact/${contactID}`;
    return this._ApiService.delete({path});
  }

  bulkCreateContactsInGroup(payload: IBulkCreateContactsInGroupPayload): Observable<BaseResponse<IBulkCreateContactsInGroup>>{
    const path = `contact-group/${payload.groupID}/contact/bulk`;
    return this._ApiService.post({path, body: payload.body});
  }

  bulkUpdateContactsInGroup(payload: IBulkUpdateContactsInGroupPayload): Observable<BaseResponse<IBulkCreateContactsInGroup>>{
    const path = `contact-group/${payload.groupID}/contact/bulk`;
    return this._ApiService.patch({path, body: payload.body});
  }

  textCreateContactsInGroup(payload: ITextCreateContactsInGroupPayload): Observable<BaseResponse<ITextCreateContactsInGroup>>{
    const path = `contact-group/${payload.groupID}/contact/text`;
    return this._ApiService.postFile({path, body: payload.body});
  }

  excelFirstStepContactsInGroup(payload: IExcelFirstStepContactsInGroupPayload): Observable<BaseResponse<IExcelFirstStepContactsInGroup>>{
    const path = `contact-group/${payload.groupID}/contact/excel/first-step`;
    return this._ApiService.postFile({path, body: payload.body});
  }

  excelSecondStepContactsInGroup(payload: IExcelSecondStepContactsInGroupPayload): Observable<BaseResponse<any>>{
    const path = `contact-group/${payload.groupID}/contact/excel/second-step`;
    return this._ApiService.post({path, body: payload.body});
  }

  fileContactsInGroup(payload: IFileContactsInGroupPayload): Observable<BaseResponse<boolean>>{
    const path = `contact-group/${payload.groupID}/contact/file`;
    return this._ApiService.patch({path, body: payload.body});
  }

  copyContact(payload: ICopyContactsPayload): Observable<BaseResponse<boolean>>{
    const path = `contact-group/${payload.groupID}/copy`;
    return this._ApiService.post({path, body: payload.body});
  }

  moveContact(payload: IMoveContactsPayload): Observable<BaseResponse<boolean>>{
    const path = `contact-group/${payload.groupID}/move`;
    return this._ApiService.post({path, body: payload.body});
  }
}
