import {Injectable} from '@angular/core';
import {ErrorCode} from "@app/api/enums/error-code.enum";
import {FilterItemModel} from "@shared/components/table/models/filter-item.model";


@Injectable()
export class ErrorCodeService {
  translate(code: number) {
    return this.errorCodeTranslate.find(i => i.value === code)?.name ?? code;
  }

  private errorCodeTranslate: FilterItemModel[] = [
    { value: ErrorCode.ContactExistsInThisGroup, name: "شماره مخاطب وارد شده در این گروه وجود دارد." },
    { value: ErrorCode.DuplicateTitleOrName, name: "عنوان وارد شده تکراری است." },
    { value: ErrorCode.FileIsInvalid, name: "محتوای فایل آپلود شده قابل قبول نمی‌باشد." },
    { value: ErrorCode.NotAllowedContactsCount, name: "تعداد مخاطبان بیش از حدمجاز است." },
    { value: ErrorCode.CacheKeyNotFound, name: "زمان تکمیل فرآیند به اتمام رسید، لطفا مجددا تلاش نمایید." },
    { value: ErrorCode.ContactAlreadyExists, name: "شماره مخاطب وارد شده در سایر گروه های شما موجود است." },
    { value: ErrorCode.MobileUsedByAnotherUser, name: "شماره وارد شده قبلا در سامانه ثبت شده است." },
    { value: ErrorCode.UserNotFound, name: "کاربری با این مشخصات وجود ندارد." },
    { value: ErrorCode.UserIsSuspended, name: "حساب کاربری شما مسدود است، لطفا با پشتیبانی سامانه تماس بگیرید." },
    { value: ErrorCode.MobileVerificationCodeIsWrong, name: "کد فعالسازی نامعتبر است." },
    { value: ErrorCode.UserApiKeyLimitationCount, name: "محدودیت ساخت کلید وب‌سرویس" },
    { value: ErrorCode.AlreadyReturnInvoiceIssued, name: "این فاکتور دارای فاکتور بازگشتی می‌باشد." },
    { value: ErrorCode.UserCouldNotHasOfficialInvoice, name: "عدم قابلیت صدور فاکتور رسمی برای کاربر" },
    { value: ErrorCode.PasswordIsWrong, name: "رمز عبور فعلی صحیح نمی‌باشد." },
    { value: ErrorCode.UserDeactivationAllowed24Hours, name: "پس از گذشت حداکثر 24 ساعت انجام این عملیات امکان پذیر است." },
    { value: ErrorCode.DuplicateEmailAddress, name: "آدرس ایمیل تکراری است." },
    { value: ErrorCode.DuplicateUserName, name: "نام کاربری انتخاب شده تکراری است." },
    { value: ErrorCode.DuplicateInvoiceNumber, name: "شماره فاکتور رسمی تکراری است." },
    { value: ErrorCode.DiscountCodeIsWrong, name: "کد تخفیف اشتباه است." },
    { value: ErrorCode.DiscountCodeHasExpired, name: "کد تخفیف منقضی شده است." },
    { value: ErrorCode.DiscountCodeUsageLimitationReached, name: "به تعداد حداکثر دفعات مجاز، از این کد استفاده نموده‌اید." },
    { value: ErrorCode.UserLinePrebuyExists, name: "خط توسط کاربر دیگری موقتا رزرو شده است." },
    { value: ErrorCode.DuplicateUserLineNumber, name: "خط کاربر تکراری است." },
    { value: ErrorCode.CantModifyStartedABTest, name: "کمپین شروع شده را نمی‌توانید ویرایش کنید." },
    { value: ErrorCode.ItemCountLimitation, name: "حداکثر آیتم مجاز" },
    { value: ErrorCode.EventIsRelatedToTheCampaign, name: "این رویداد در کمپین استفاده شده است. لطفا ابتدا کمپین را حذف کنید." },
    { value: ErrorCode.ContactGroupUsedInCampaign, name: "این گروه از مخاطبین در یک کمپین مورد استفاده قرار گرفته است." },
    { value: ErrorCode.EmailVerificationAllowedPer2Minutes, name: "تأیید ایمیل پس از گذشت دو دقیقه مجاز است" },
    { value: ErrorCode.LineUsedOnAnotherModule, name: "خط در ماژول دیگری استفاده شده است." },
    { value: ErrorCode.SmsSecretaryBehaviorLimitationCount, name: "محدودیت تعداد عملگر منشی پیامکی" },
    { value: ErrorCode.DuplicateParameter, name: "پارامتر تکراری می باشد." },
    { value: ErrorCode.CreditNotEnough, name: "برای انجام عملیات، اعتبار شما کافی نمی‌باشد." },
    { value: ErrorCode.KeyWordIsDuplicated, name: "کیلدواژه تکراری می باشد." },
    { value: ErrorCode.TestFastSendLimitationCount, name: "محدودیت روزانه در تست ارسال سریع (۳ ارسال)" },
    { value: ErrorCode.RetryOperation, name: "RetryOperation" },
    { value: ErrorCode.OverlapInRanges, name: "تداخل در بازه‌های زمانی انتخاب شده" },
    { value: ErrorCode.TempRefCodeIsExpired, name: "لینک شما منقضی شد‌ه ‌است. لطفا برای دریافت لینک مجددا تلاش کنید." },
    { value: ErrorCode.ParameterUsedInBlackListKeywords, name: "لطفا پارامتر یکتا انتخاب کنید. این پارامتر در لیست سیاه استفاده شده ‌است." },
    { value: ErrorCode.CooperationMessageParameterLimitationCount, name: "محدودیت تعداد پارامتر در همکاری در فروش" },
    { value: ErrorCode.ParameterUsedOnAnotherModule, name: "این پارامتر در ماژول دیگری استفاده شده است." },
    { value: ErrorCode.UserHasOpenRequest, name: "شما یک درخواست در حال بررسی دارید." },
    { value: ErrorCode.RegistrationThroughTempCodeHasAlreadyBeenDone, name: "ثبت نام از طریق این لینک قبلا انجام شده است." },
    { value: ErrorCode.AddValidIPForTwoStepLoginLimitationCount, name: "IP های وارد شده بیش از حد مجاز است." },
    { value: ErrorCode.AddValidMobileToSendVerificationCodeLimitationCount, name: "شماره موبایل های وارد شده بیش از حد مجاز است." },
    { value: ErrorCode.UsersMobileCannotBeInTheWhiteList, name: "شما مجاز به افزودن شماره موبایل کاربری در این لیست نمی باشید." },
    { value: ErrorCode.UserHaveAnyManualPaymentWaitingToConfirmAdmin, name: "لطفا تا زمان تعیین وضعیت پرداخت ثبت شده خود منتظر بمانید." },
    { value: ErrorCode.DefaultMessageInCodeReaderIsRequired, name: "پیام پیش فرض در کدخوان اجباری می باشد." },
    { value: ErrorCode.UserMustModifyUnderSupervisionFinancialDepartment, name: "کاربر موردنظر فاکتور ثبت شده دارد. لطفا برای تغییر اطلاعات حقوقی به واحد مالی ارجاع دهید." },
    { value: ErrorCode.DowngradePlanIsNotPermitted, name: "خرید این پلن برای شما امکان پذیر نیست. لطفا پلن دیگری را انتخاب کنید." },
    { value: ErrorCode.AlreadyHasNotExpiredPlan, name: "پلن شما هنوز به زمان انقضای خود نرسیده است و در حال حاضر امکان تمدید یا تنزل پلن وجود ندارد." },
    { value: ErrorCode.PlanItemAccessibility, name: "اعتبار پلن شما به پایان رسید. لطفا پلن خود را تمدید کنید." },
    { value: ErrorCode.PreviousDaysHasPendingInvoice, name: "فاکتور شماره گذاری نشده از روز قبل دارید." },
    { value: ErrorCode.MobileIsAlreadyBlocked, name: "این شماره موبایل در حال حاضر بلاک می باشد." },
    { value: ErrorCode.PaymentLimitations, name: "در حال حاضر عملیات پرداخت امکان پذیر نیست." },
    { value: ErrorCode.ABTestSelectedContactLessThanMin, name: " تعداد مخاطبان کمتر از حد نصاب است. لطفا تعداد مخاطبان را افزایش دهید." },
    { value: ErrorCode.CantModifyMessageTextOfHandledScheduledCampaign, name: "در کمپین زمانبندی پیشرفته، بعد از اجرای کمپین امکان ویرایش متن پیام وجود ندارد." },
    { value: ErrorCode.SendSmsViaTowerRequestIsExpired, name: "درخواست ارسال از دکل شما منقضی شده لطفا بار دیگر درخواست ثبت کنید" },
    { value: ErrorCode.SendSmsViaTowerRequestIsUnchangeable, name: "درخواست ارسال از دکل شما قابل تغییر نمی باشد" },
    { value: ErrorCode.PublicLineIsNotActive, name: "در حال حاضر خطوط ویژه فعال نیستند." },
    { value: ErrorCode.UserDoesNotHaveAccessToSpecialLines, name: "کاربر گرامی، زمان دسترسی شما به خطوط ویژه پایان یافته است." },
    { value: ErrorCode.PublicLineSendLimitationCount, name: "حداقل تعداد مخاطب در ارسال با خطوط ویژه رعایت نشده‌است." },
    { value: ErrorCode.SendSmsTypeDeactivated, name: "در حال حاضر، استفاده از این قابلیت امکان‌پذیر نیست." },
    { value: ErrorCode.UserTagNotFound, name: "پوشه مورد نظر یافت نشد." },
    { value: ErrorCode.UserTagsAreNotOfTheSameType, name: "پوشه مبدا و مقصد از یک نوع نیستند." },
    { value: ErrorCode.UserTagNameDuplicated, name: "نام پوشه تکراری است." },
    { value: ErrorCode.PaymentGatewayUnreachable, name: "ارتباط با درگاه انتخاب شده در این لحظه امکان پذیر نمی‌باشد." },
    { value: ErrorCode.DiscountCodeCouldNotAffectTheCreditTier, name: "اعمال کد تخفیف در بازه انتخابی شما، امکان‌پذیر نمی‌باشد." },
    { value: ErrorCode.UserHasUnfinishedRequest, name: "لطفا تا اتمام درخواست افزودن قبلی منتظر بمانید." },
    { value: ErrorCode.PlanAccessibility, name: "لطفا برای استفاده از این امکان، یکی از پلن های سامانه را انتخاب نمایید." },
    { value: ErrorCode.DailyCreditTransactionLimitExceeded, name: "سقف مجاز تراکنش‌های اعتبار روزانه شما پر شده است" },
    { value: ErrorCode.CouldNotConnectToReportService, name: "اتصال به سرویس گزارش‌دهی ممکن نیست.  لطفاً بعداً دوباره امتحان کنید." },
    { value: ErrorCode.ReportRequestTimeout, name: "زمان درخواست گزارش تمام شد. لطفاً دوباره تلاش کنید." },
    { value: ErrorCode.NumberOfRecordsHasExceededTheLimit, name: "تعداد رکوردها بیش از حد مجاز است. درخواست را محدودتر کنید." },
    { value: ErrorCode.ReportServiceTerminated, name: "سرویس گزارش‌دهی متوقف شد. بعداً دوباره امتحان کنید." },
    { value: ErrorCode.PayamakSefidServiceConnectionFailed, name: "مشکلی در ارتباط با پیامک سفید وجود دارد." },
    { value: ErrorCode.UserHasSamePayamakSefidDeviceName, name: "نام نمایشی اپلیکیشن تکراری است." },
    { value: ErrorCode.PayamakSefidDeviceIsAlreadyConnected, name: "این اپلیکیشن قبلا به سامانه متصل شده‌است." },
    { value: ErrorCode.PayamakSefidContactGroupIsInUseByDevices, name: "این گروه مخاطب در حال استفاده در یک اپلیکیشن است." },
    { value: ErrorCode.DeviceContactGroupExists, name: "این گروه مخاطب قبلاً اضافه شده‌است." },
    { value: ErrorCode.CannotRemoveDefaultContactGroup, name: "امکان حذف گروه مخاطب پیش‌فرض وجود ندارد." },
    { value: ErrorCode.XMAServiceConnectionFailed, name: "در حال حاضر ارتباط برقرار نمیباشد." },
    { value: ErrorCode.XMAPlanItemLimitation, name: "به محدودیت تعداد دفعات استفاده روزانه رسیده اید. برای استفاده بیشتر پلن خود را ارتقا دهید." },
    { value: ErrorCode.PayamakSefidContactGroupHasContacts, name: "برای حذف این گروه، باید ابتدا تمامی مخاطبان آن را حذف کنید." },
  ];
}
